import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classes from "./BestAuthor.module.css";
import { fetchAllAuthors } from "../../../Store/actions/BestSellingAuthorAction";

const getNumberOfVisibleItems = () => {
  if (window.innerWidth >= 1200) return 5;
  if (window.innerWidth >= 768) return 3;
  return 1;
};

const BestAuthor = ({ query, setQuery, onSearch }) => {
  const dispatch = useDispatch();
  const bestAuthor = useSelector((state) => state.bestSellingAuthors.authors);
  const [current, setCurrent] = useState(0);
  const [visibleItems, setVisibleItems] = useState(getNumberOfVisibleItems());
  const [isLocalQueryUpdate, setIsLocalQueryUpdate] = useState(false);

  useEffect(() => {
    fetchAllAuthors(dispatch);

    const handleResize = () => {
      setVisibleItems(getNumberOfVisibleItems());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const nextSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent + 1) % bestAuthor.length);
  };

  const prevSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent - 1 + bestAuthor.length) % bestAuthor.length);
  };

  useEffect(() => {
    if (query !== "" && isLocalQueryUpdate) {
      onSearch();
      setIsLocalQueryUpdate(false); // Reset the flag after triggering search
    }
  }, [query, onSearch, isLocalQueryUpdate]);

  const handleQueryUpdate = (authorName) => {
    setIsLocalQueryUpdate(true); // Mark as local update
    setQuery(authorName);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>Best Selling Authors</h2>
        <div className={classes.carouselBtn}>
          <button className={`${classes.carouselControl} ${classes.prev}`} onClick={prevSlide}>
            &lt;
          </button>
          <button className={`${classes.carouselControl} ${classes.next}`} onClick={nextSlide}>
            &gt;
          </button>
        </div>
      </div>

      <div className={classes.carousel}>
        <div className={classes.carouselInner} style={{ transform: `translateX(-${current * (100 / visibleItems)}%)` }}>
          {bestAuthor?.map((author, index) => (
            <div key={index} className={classes.carouselItem} onClick={() => handleQueryUpdate(author?.authorName)}>
              <div className={classes.image}>
                <img src={author?.url} alt={author?.altText} />
              </div>
              <p title={author?.authorName}>{author?.authorName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestAuthor;
