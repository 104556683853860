import classes from "./EditText.module.css";

import { useState, useEffect, useContext } from "react";
import { CustomizationContext } from "../../../Customization";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
const dropDownIcon = "/Assets/Icons/caret-down-solid.svg";

const EditText = () => {
  const { fontData, spineFontColor, setSpineFontColor, setSpineFontFamily, spineFontStyle, setSpineFontStyle, allTextColors } = useContext(CustomizationContext);
  const [selectedFont, setSelectedFont] = useState(fontData[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Set spineFontColor to the first allowed color on first render
  useEffect(() => {
    if (allTextColors?.length > 0 && !spineFontColor) {
      setSpineFontColor(allTextColors[0]); // Ensure to set the colorValue
    }
  }, [allTextColors]);

  const handleColorChange = (color) => {
    setSpineFontColor(color); // Directly update spineFontColor
  };

  const handleFontChange = (font) => {
    setSelectedFont(font);
    setSpineFontFamily(font);
    setIsDropdownOpen(false);
  };

  const handleTextStyleChange = (style) => {
    setSpineFontStyle((prevStyle) => {
      const newStyle = { ...prevStyle };

      switch (style) {
        case 'italic':
          newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
          break;
        case 'underline':
          newStyle.underline = !newStyle.underline;
          break;
        default:
          break;
      }

      return newStyle;
    });
  };


  // useEffect(() => {
  //   const changeCoverColor = (colorCode) => {
  //     const obj = activeObject;
  //     const selectedTextStyle = obj?._activeObject;
  //     if (selectedTextStyle) {
  //       selectedTextStyle.set("fontFamily", selectedFont.fontFamily);
  //       selectedTextStyle.set("fill", colorCode);
  //       obj.dirty = true;
  //       canvas.renderAll();
  //     }
  //   };

  //   changeCoverColor(spineFontColor); // Use spineFontColor
  // }, [spineFontColor, selectedFont, activeObject, canvas]);

  return (
    <div
      className={classes.colorPickerDropdown}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.selectColor}>
        <h2>Font family: {selectedFont?.fontFamily}</h2>
        <div className={classes.customDropdown}>
          <div
            className={classes.selectedOption}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={classes.dropdownBar}>
              <div className={classes.dropdownText}>
                <span>{selectedFont?.fontFamily}</span>
                <span>{selectedFont?.fontPrice > 0 && `+$${selectedFont?.fontPrice}`}</span>
              </div>
              <div className={classes.dropdownIcon}>
                <img src={dropDownIcon} alt="" />
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div className={classes.dropdownOptions}>
              {fontData.map((font) => (
                <div
                  key={font.fontFamily}
                  className={classes.option}
                  onClick={() => handleFontChange(font)}
                >
                  <span>{font?.fontFamily}</span>
                  <span>{font?.fontPrice > 0 && `+$${font?.fontPrice}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={classes.textStylingOptions}>
        <h3>Select Text Styling</h3>
        <div className={classes.stylingButtons}>
          <button
            className={`${classes.stylingButton}  ${
                  spineFontStyle?.fontStyle === "italic"
                    ? classes.activeStylingButton
                    : ""}`}
            onClick={() => handleTextStyleChange("italic")}
          >
            <i>I</i>
          </button>
          <button
            className={`${classes.stylingButton}  ${
                  spineFontStyle?.underline === true
                    ? classes.activeStylingButton
                    : ""}`}
            onClick={() => handleTextStyleChange("underline")}
          >
            <u>U</u>
          </button>
        </div>
      </div>

      <div className={classes.selectColor}>
        <h2>Color: {spineFontColor?.colorName}</h2>{" "}
        {/* Use spineFontColor here */}
        {/* <div className={classes.colors}>
          {allTextColors.map((color, index) =>
            spineFontColor?.colorValue === color.colorValue ? (
              <div key={index} className={classes.selected}>
                <div
                  className={classes.active}
                  style={{ background: color.colorValue }}
                />
              </div>
            ) : (
              <div
                key={index}
                className={classes.colorPicker}
                style={{ background: color.colorValue }}
                onClick={() => handleColorChange(color)}
              />
            )
          )}
        </div> */}
        <ColorSelector
          availableColors={allTextColors}
          selectedColor={spineFontColor}
          handleColorChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default EditText;
