import React from "react";
import classes from "./PrimaryNav.module.css";
import useScreenSize from "../../../Hooks/UseScreenSize";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import { useSelector } from "react-redux";
const cartIcon = "/Assets/Icons/shopping_basket.png";
const heartIcon = "/Assets/Icons/icon-heart.svg";

function CollapsedItems() {
    const {isSmallScreen} = useScreenSize();
    const history = useHistory()
    const cartItems = useSelector((state) => state.cart.cartItems);
    const user = useSelector((state) => state.user.currentUser)
  return (
    <>
      <li key={"whishlist"}>
        <div className={classes.navItem} onClick={() => history.push(`${urls.USERPROFILE}/wishlist`)}>
          <img src={heartIcon} alt="" />
        </div>
        { isSmallScreen && <label className={classes.label} onClick={() => history.push(`${urls.USERPROFILE}/wishlist`)}>Wishlist</label>}
      </li>
      <li key={"cart"} className={classes.cartBtn}>
        <div className={classes.navItem} onClick={() => history.push(urls.CART)}>
          <img src={cartIcon} alt=""/>
          {user?.id && cartItems?.length > 0 ? <div className={classes.cartCount}>{cartItems?.length}</div> : ""}
        </div>
        { isSmallScreen && <label className={classes.label}>Cart</label>}
      </li>
      {/* <li key={"person"}>
        <div className={classes.navItem}>
          <img src={personIcon} />
        </div>
        { isSmallScreen && <label>My Account</label>}
      </li> */}
    </>
  );
}

export default CollapsedItems;
