import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../Store/actions/UsersActions";
import { copyTextToClipboard } from "../../../Util";
import classes from "./MyProfile.module.css";
import ProfileForm from "../ProfileForm/ProfileForm";
import ButtonLink from "../../../Components/Buttons/ButtonLink/ButtonLink";

const referralBackgroundImage = "/Assets/Images/referral-background.svg";
const referralFrontImage = "/Assets/Images/referral-front.svg";

export function MyProfile() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [showPopup, setShowPopup] = useState(false);

  const url = window.location.href;
  const domainName = url.substring(0, url.lastIndexOf("/my-account"));
  const code = currentUser.userCode;
  const referralLink = `${domainName}?referral_code=${code}`

  const handleFormSubmission = (e) => {
    
    e.preventDefault();

    const photo = e.target.files[0];

    const data = {};

    updateUser(data, photo, dispatch).then((response) => {
      if (response && response.data && response.data.success) {
      }
    });
  };

  const handlePhotoUpload = (e) => {
    handleFormSubmission(e);
  };

  const copyHandler = (copyText) => {
    copyTextToClipboard(copyText).then(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 1000);
    })
  };

  return (
    <div className={classes.profileContainer}>
      <div className={classes.title}>My Profile</div>
      <div className={classes.formSection}>
        <div className={classes.userDetails}>
          <div
            className={currentUser.photoURL ? classes.showImage : classes.image}
          >
            <img src={currentUser.photoURL} alt="" />
          </div>
          <div className={classes.description}>
            <div>
              <h3>{currentUser.fullName}</h3>
              <p>{currentUser.userId}</p>
            </div>
            <input
              type="file"
              id="photoUpload"
              accept="image/*"
              onChange={(e) => handlePhotoUpload(e)}
              title="Select Photos"
              className={classes.photoUpload}
            />
            <label htmlFor="photoUpload" className={classes.photoUploadButton}>
              Upload Photo
            </label>
          </div>
        </div>

        <div className={classes.signupForm}>
          <ProfileForm />
        </div>
      </div>

      <div className={classes.referrals}>
        <div className={classes.referralTitle}>
          Share the referral link with your friends
        </div>
        <div>
          You can share your referral link with your friends and get money
          credited to your wallet.{" "}
        </div>
        <div className={classes.referralContainer}>
          <img src={referralBackgroundImage} alt="" />
          <div className={classes.innerContainer}>
            <div>
              <div className={classes.innerTitle}>
                Share the Love of Books and Get Rewarded!
              </div>
              <div className={classes.linkContainer}>
                <div className={classes.link}>{referralLink}</div>
                <ButtonLink title="Copy" onClick={() => copyHandler(referralLink)}/>
                {showPopup && <div className={classes.copied}>Copied to clipboard</div>}
              </div>
            </div>
            <div className={classes.frontImage}>
              <img src={referralFrontImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
