import classes from "./ToolTip.module.css"

import { useState } from "react";

const ToolTip = ({tooltipText}) => {
    const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return(
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

          <span className={classes.infoIcon}>i</span>
          {showTooltip && <div className={classes.tooltip}>{tooltipText}</div>}
      </div>
  )
}

export default ToolTip