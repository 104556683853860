import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { createOrder } from "../../Store/actions/OrdersActions";
import { loaderActions } from "../../Store/Slices/LoaderSlice";
import classes from "./Checkout.module.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";

export const CheckoutForm = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const url = window.location.href;
  const domainName = url.substring(0, url.lastIndexOf(`/`));

  const [errorMessage, setErrorMessage] = useState({
    show: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage({ show: true, message: submitError?.message });
      setTimeout(() => {
        setErrorMessage({ show: false, message: "" });
      }, 4000);
      return;
    }

    const response = await createOrder();
    const clientSecret = response.data.clientSecret;

    dispatch(loaderActions.fetching());

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element.
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${domainName}/payment.html`,
      },
    });

    dispatch(loaderActions.fetched());

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // window.location.href = `${domainName}${urls.CHECKOUT_THANK_YOU}`
      setErrorMessage({ show: true, message: error.message });
      setTimeout(() => {
        setErrorMessage({ show: false, message: "" });
      }, 4000);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <PrimaryButton
        type="submit"
        className={classes.payBtn}
        disabled={!stripe || !elements}
      >
        Pay
      </PrimaryButton>
      {/* Show error message to your customers */}
      {errorMessage.show && (
        <div className={classes.errorMessage}>{errorMessage.message}</div>
      )}
    </form>
  );
};

const stripePromise = loadStripe(
  "pk_test_51O5qVjJnTZyiVnbAhr06m3zPtI396DbiljNHno2ZS1XBaRPSNxcJd9nriEohorz4SAx4ohinYM5JN3S4w23naT4M00FCLzezDE"
);

export const CheckoutFormComponent = ({ onClose }) => {
  const { cart } = useSelector((state) => state.cart);

  const options = {
    mode: "payment",
    amount: Number(cart.payableAmount.toFixed(0)),
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm onClose={onClose} />
    </Elements>
  );
};
