import { useFabricCanvas } from "react-fabric-page";
import Accordion from "../../Components/Accordion/Accordion";
import classes from "./Gilding.module.css";

import { useState, useEffect, useContext } from "react";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import { getGildingColor } from "../../../../Store/actions/ColorsAction";
import { CustomizationContext } from "../../Customization";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { useSelector } from "react-redux";

const gildingTabs = [
  {
    name: "Yes",
    value: "yes",
  },
  {
    name: "No",
    value: "no",
  },
];

const Gilding = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const { selectedGildingColor, setSelectedGildingColor,selectedDesigns } =
    useContext(CustomizationContext);
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [wantGilding, setWantGilding] = useState(gildingTabs[1].value);
  const { isMediumScreen } = useScreenSize();
  const [allGildingColor, setAllGildingColor] = useState([]);
  const [allowedColors, setAllowedColors] = useState([]);

  const canvas = useFabricCanvas();

  const handleColorChange = (color) => {
    setSelectedGildingColor(color);
  };

  const getColor = async () => {
    let filter = {
      // offset: (currPageNo - 1) * pageSize,
      // limit: pageSize,
    };

    try {
      const res = await getGildingColor(filter);
      if (res.status === 200) {
        setAllGildingColor(res.data?.data);
      }
    } catch (err) {
      
    }
  };

  useEffect(() => {
    getColor();
  }, []);

  useEffect(() => {
    const nonDisabledColors = allGildingColor?.filter(
      (color) => color.disabled === false
    );
    setAllowedColors(nonDisabledColors);
  }, [allGildingColor]);

  useEffect(() => {
    if (wantGilding === "yes") {
      if (allowedColors.length > 0) {
        if(selectedDesigns?.customization?.gilding?.hasGilding) {
          const selectedGildingColor = allowedColors?.find(color => color?.colorName === selectedDesigns?.customization?.gilding?.gildingColor);
          if(selectedGildingColor) {
            setSelectedGildingColor(selectedGildingColor);
          } else {
            setSelectedGildingColor(allowedColors[0]);
          }
        } else {
          setSelectedGildingColor(allowedColors[0]);
        }
      }
    }
  }, [allowedColors, wantGilding, setSelectedGildingColor]);

  useEffect(() => {
    if (canvas && wantGilding === "yes") {
      const layer = canvas.getObjects().find((o) => o.id === "pages");
      if (layer) {
        layer.set({ fill: selectedGildingColor?.colorValue });
        layer.dirty = true;
        canvas.renderAll();
      }
    } else if (wantGilding === "no") {
      setSelectedGildingColor(null);
      if (canvas) {
        const layer = canvas.getObjects().find((o) => o.id === "pages");
        if (layer) {
          layer.set({ fill: "#fff" });
          layer.dirty = true;
          canvas.renderAll();
        }
      }
    }
  }, [selectedGildingColor, wantGilding, canvas]);

  const handleWantGilding = (e, option) => {
    e.stopPropagation();
    setWantGilding(option);
  };


  useEffect(() => {
    if(selectedDesigns?.customization) {
      if(selectedDesigns?.customization?.gilding?.hasGilding) {
        setWantGilding(gildingTabs?.[0]?.value)
        const selectedGildingColor = allowedColors?.find(color => color?.colorName === selectedDesigns?.customization?.gilding?.gildingColor)
        setSelectedGildingColor(selectedGildingColor);
      }
    }
  },[selectedDesigns])

  return (
    <div>
      {isMediumScreen ? (
        <div className={classes.selectColorOptions}>
            <h2>Want Gilding (edge papers) ?</h2>
             <div className={classes.gildingOptions}>
            {gildingTabs.map((option) => (
              <OptionCapsules
                key={option.value}
                option={option.name}
                onClick={(e) => handleWantGilding(e, option.value)}
                active={wantGilding === option.value}
              />
            ))}
          </div>

          {dropdownOpen && wantGilding === "yes" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Select Gilding Color</h2>

              <div className={classes.message}>
                <h2>Please note that gilding customization comes with an additional cost</h2>
                <p>+${customizationPricing?.gildingPrice}</p>
              </div>

              <div className={classes.selectColor}>
                <h2>Color : {selectedGildingColor?.colorName}</h2>
                <div className={classes.colors}>
                  {allowedColors.map((color, index) =>
                    selectedGildingColor?.colorValue === color?.colorValue ? (
                      <div key={index} className={classes.selected}>
                        <div
                          className={classes.active}
                          style={{ background: color?.colorValue }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={classes.colorPicker}
                        style={{ background: color?.colorValue }}
                        onClick={(e) => handleColorChange(color, e)}
                      >
                        {" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <Accordion
            onClick={onClick}
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            currentDropdown={currentDropdown}
            dropdownName={"Gilding"}
            toolText={"Add Gilding to paper"}
            title={"Gilding (Edge Pages)"}
          />

          <div className={classes.gildingOptions}>
            {gildingTabs.map((option) => (
              <OptionCapsules
                key={option.value}
                option={option.name}
                onClick={(e) => handleWantGilding(e, option.value)}
                active={wantGilding === option.value}
              />
            ))}
          </div>

          {dropdownOpen && wantGilding === "yes" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Select Gilding Color</h2>

              <div className={classes.message}>
                <h2>Please note that gilding customization comes with an additional cost</h2>
                <p>+${customizationPricing?.gildingPrice}</p>
              </div>

              <div className={classes.selectColor}>
                <h2>Color : {selectedGildingColor?.colorName}</h2>
                <div className={classes.colors}>
                  {allowedColors.map((color, index) =>
                    selectedGildingColor?.colorValue === color?.colorValue ? (
                      <div key={index} className={classes.selected}>
                        <div
                          className={classes.active}
                          style={{ background: color?.colorValue }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={classes.colorPicker}
                        style={{ background: color?.colorValue }}
                        onClick={(e) => handleColorChange(color, e)}
                      >
                        {" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Gilding;
