import { useDispatch, useSelector } from "react-redux"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import Gallery from "../../Body/Gallery/Gallery"
import Footer from "../../Footer/Footer"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import FAQ from "../FAQ/FAQ"
import AboutForPublishers from "./AboutForPublishers/AboutForPublishers"
import classes from "./ForPublishers.module.css"
import { useEffect } from "react"
import { getAllFaq } from "../../../Store/actions/FaqAction"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";

const ForPublishers = () => {
    const dispatch = useDispatch()
    const crumbs = [
        { title: "Home", path: ("/") },
        { title: "For Publishers", path: urls.FORPUBLISHERS },
    ]
    const video = useSelector((state) => state.serviceVideo?.videos?.forPublishersVideo)
    const allFaq = useSelector(state => state.Faq.questions);

    useEffect(() => {
        getAllFaq("category","For Publishers","",dispatch)
    },[])
    const scrollRef = useScrollToTop();

    return (
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader crumbs={crumbs} title={"For Publishers"} desc={"Unite with us in a shared commitment to excellence, where every book is a testament to the craftsmanship and the enduring power of stories."} />

            <ServiceVideo
                title={"Become Our Partner"}
                videoSrc={video}
                desc={"Rare Biblio is an expert in creating leather-bound books that can be personalized and customized are entirely hand-crafted and are distinguished by their high quality, exquisite design, and classic appeal. We are thrilled to invite booksellers like you to become a part of this special alliance. Working with us will enable you to provide your clients with a unique selection of beautifully constructed, leather-bound books that will win them over and add value to their libraries."}
            />

            <AboutForPublishers />
            <div className={classes.join}>
                <h2>
                    Join Hands With Us
                </h2>
                <p>
                    Find out how Rare Biblo's superior bookbinding services might improve your publishing offers. Come celebrate the craft of bookmaking with us as we provide your writers and authors with premium quality leather-bound handcrafted customized books that are exceptional in terms of elegance and quality.
                    Would you like a partnership with us? To get in touch with us and find out more about how we may collaborate, click the link below.
                </p>
            </div>

            <Gallery />

            <CustomerReview />

            <FAQ data={allFaq} />
            <div className={classes.contactUs}>
                <ContactUs />   
            </div>

            <Footer />
        </div>
    )
}

export default ForPublishers