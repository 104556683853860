import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Slider from "react-slick";
import { urls } from "../../../Routes/Urls";
import { getRandomImageForBestSellerBook } from "../../../Util";
import Card from "../../BestSeller/Card/Card2";
import classes from "./Recommendations.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.leftArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={classes.RightArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

const Recommendations = () => {
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
    ],
  };
  const featuredCollection = useSelector(
    (state) => state.featured.featuredCollection
  );

  const books =
    featuredCollection?.find((data) => data.category === "bestSelling")
      ?.books || [];
  const history = useHistory();

  const handleOnClick = (url) => {
    history.push(`${url}`);
  };
  const allColorsData = useSelector((state) => state.bookColor.colors);

  return (
    <div className={classes.container}>
      <h1>You may also like these books</h1>
      <div className={classes.carouselContainer}>
        <Slider className={classes.slider} {...settings}>
          {books.map((book, index) => (
            <Card
              key={index}
              bookData={book}
              onClick={() => handleOnClick(`${urls.BESTSELLERPDP}/${book.id}`)}
              image={getRandomImageForBestSellerBook(book,allColorsData)?.image}
              price={book?.priceGenuine}
            />
          ))}
        </Slider>

        {/* <BookCarousel /> */}
      </div>
    </div>
  );
};

export default Recommendations;
