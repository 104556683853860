import classes from "./RibDesign.module.css";
import { useState, useEffect, useContext } from "react";
import { fabric } from "react-fabric-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import useScreenSize from "../../../../Hooks/UseScreenSize";
import { useFabricCanvas } from "react-fabric-page";

import { addRibDesignToAll } from "../../../../spineUtils";

import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { getRibDesign } from "../../../../Store/actions/ColorsAction";
import Slider from "react-slick/lib/slider";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { CustomizationContext } from "../../Customization";

const RibDesign = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
  activeSectionNumber,
}) => {
    const {allFoilingColors,spineRibDesignColor,setSpineRibDesignColor,spineRibDesign,setSpineRibDesign,isSpineLoaded,bookLeather,bookVariant,selectedDesigns} = useContext(CustomizationContext);
  const { isMediumScreen } = useScreenSize();
  const [allowedFoilingColors, setAllowedFoilingColor] = useState([]);
  const [allRibDesigns, setAllRibDesigns] = useState([]);


  const canvas = useFabricCanvas();

  const settings = {
    slidesToShow: allRibDesigns?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    centerPadding: "10px",
  };

  useEffect(() => {
    setSpineRibDesign(allRibDesigns?.[0])
  },[allRibDesigns])

  const getRibDesigns = async () => {
    let filter = {};

    try {
      const res = await getRibDesign(filter);
      if (res.status === 200) {
        setAllRibDesigns(res.data?.data);
      }
    } catch (err) {
      
    }
  };

  useEffect(() => {
    const notDisabledFoilingColors = allFoilingColors?.filter(
      (color) => color.disabled === false
    );
    setAllowedFoilingColor(notDisabledFoilingColors);
  }, [allFoilingColors]);

  useEffect(() => {
    getRibDesigns();
  }, []);

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false);
    } else {
      toggleDropdown(true);
    }
  };

  const changeFoilingColor = () => {
    if (canvas) {
        // Select all images in the canvas that are part of the spine sections
        const sectionImages = canvas.getObjects().filter(obj => obj.id && obj.id.startsWith("rib-design-image-"));
            sectionImages.forEach(image => {
                if (spineRibDesignColor) {
                    image.filters[0] = new fabric.Image.filters.PatternColor({
                        color: spineRibDesignColor?.colorValue,
                        convert: true,
                    });
                    image.applyFilters();
                }
            });
        // Re-render the canvas to reflect changes
        canvas.renderAll();
    }
};

useEffect(() => {
  if(spineRibDesignColor){
    changeFoilingColor()
  }
},[spineRibDesignColor])


  // useEffect(() => {
  //   if (wantRibDesign === "No") {
  //     const layer = canvas?.getObjects()?.find((o) => o.id === "decoration");
  //     if (layer) {
  //       layer._objects = [];
  //       layer.dirty = true;
  //       canvas.renderAll();
  //     }
  //   }
  // }, [wantRibDesign]);

  useEffect(() => {
    const setDesignAndFoiling = () => {
      if (canvas && spineRibDesign && isSpineLoaded) {
         addRibDesignToAll(canvas, spineRibDesign?.designImage, 10).then(() => changeFoilingColor());
      }
    };
  
    setDesignAndFoiling(); // Call the function that sets both design and foiling color
  }, [spineRibDesign, spineRibDesignColor,isSpineLoaded,bookLeather,bookVariant]);
  

  const handleChangeBorder = (border) => {
    if(spineRibDesign.designName !== border.designName){
      setSpineRibDesign(border);
      addRibDesignToAll(canvas,border?.designImage,8);
    }
  };

  // useEffect(() => {
  //   if(spineRibDesign?.designImage && canvas)
  //   addRibDesignToAll(canvas,spineRibDesign?.designImage,8);
  // },[spineRibDesign,allRibDesigns,canvas])

  useEffect(() => {
    if (allowedFoilingColors?.length > 0) {
      if(selectedDesigns?.customization?.spine?.ribDesignColor) {
        const selectedDesignColor = allowedFoilingColors?.find((color) => color?.colorName === selectedDesigns?.customization?.spine?.ribDesignColor);
        if(selectedDesignColor) {
          setSpineRibDesignColor(selectedDesignColor)
        } else {
          setSpineRibDesignColor(allowedFoilingColors?.[0]);
        }
      } else {
        setSpineRibDesignColor(allowedFoilingColors?.[0]);
      }
    }
  }, [allowedFoilingColors,bookLeather,bookVariant,selectedDesigns]);
  
  useEffect(() => {
    if (allRibDesigns?.length > 0) {
      if(selectedDesigns?.customization?.spine?.ribDesign) {
        const selectedRibDesign = allRibDesigns?.find((design) => design?.designName === selectedDesigns?.customization?.spine?.ribDesign);
        if(selectedRibDesign) {
          setSpineRibDesign(selectedRibDesign)
        } else {
          setSpineRibDesign(allRibDesigns?.[0]);
        }
      } else {
        setSpineRibDesign(allRibDesigns?.[0]);
      }
    }
  }, [allRibDesigns,bookLeather,bookVariant,selectedDesigns]);
  

  const handleColorChange = (color,e) => {
    e.stopPropagation();
    setSpineRibDesignColor(color)
  }

  return (
    <div>
      {isMediumScreen ? (
            <div onClick={(e) => e.stopPropagation()}>
            <div className={classes.outerBorderTypes}>
              <div className={classes.previewContainer}>
                <Slider {...settings}>
                  {allRibDesigns?.map((border) => (
                    <div
                      key={border?.designName}
                      className={`${classes.previewCard} ${
                        spineRibDesign?.designName ===
                        border?.designName
                          ? classes.activePreviewCard
                          : ""
                      }`}
                      onClick={() => handleChangeBorder(border)}
                    >
                      <img src={border?.designImage} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className={classes.foilingColorSection}>
                  <h2>Pick your preferred foil color! </h2>
                  <ColorSelector
                    availableColors={allowedFoilingColors}
                    selectedColor={spineRibDesignColor}
                    handleColorChange={handleColorChange}
                  />
                </div>
              {/* <p className={classes.disclaimer}>
                Disclaimer : uploaded design won’t be available for live
                preview
              </p> */}
            </div>
          <div className={classes.message}>
            <FontAwesomeIcon icon={faCircleInfo} />
            <p>Raised bands will feature a uniform design along the spine.</p>
          </div>
        </div>
      ) : (
        <div
          className={classes.outerBorder}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
              <h2>Choose Rib design</h2>
              <ToolTip tooltipText={"Select Rib Design"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "RibDesign" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "RibDesign" ? (
            <div onClick={(e) => e.stopPropagation()}>
                <div className={classes.outerBorderTypes}>
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                      {allRibDesigns?.map((border) => (
                        <div
                          key={border?.designName}
                          className={`${classes.previewCard} ${
                            spineRibDesign?.designName ===
                            border?.designName
                              ? classes.activePreviewCard
                              : ""
                          }`}
                          onClick={() => handleChangeBorder(border)}
                        >
                          <img src={border?.designImage} alt="" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className={classes.foilingColorSection}>
                      <h2>Pick your preferred foil color! </h2>
                      <ColorSelector
                        availableColors={allowedFoilingColors}
                        selectedColor={spineRibDesignColor}
                        handleColorChange={handleColorChange}
                      />
                    </div>
                  {/* <p className={classes.disclaimer}>
                    Disclaimer : uploaded design won’t be available for live
                    preview
                  </p> */}
                </div>
              <div className={classes.message}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <p>Raised bands will feature a uniform design along the spine.</p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default RibDesign;
