import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import classes from "./InfoNav.module.css";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";

function InfoNav() {
  const history = useHistory();
  return (
    <nav className={classes.main}>
      <div className={classes.container}>
        <p className={classes.description}>
          Explore world of exclusive customization unique design and super
          premium craftsmanship!
        </p>
        <div>
          <SecondaryButton className={classes.exploreButton} onClick={() => history.push(urls.RB_EXCLUSIVE)}>
            Explore RB Exclusive
          </SecondaryButton>
        </div>
      </div>
    </nav>
  );
}

export default InfoNav;
