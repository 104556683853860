import { useEffect, useState } from "react";
import CouponCard from "./CouponCard/CouponCard";
import classes from "./Coupons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCoupons, fetchUserCoupons } from "../../../Store/actions/CouponAction";

const tabs = [
    {
        name: "All",
        value: "all"
    },
    {
        name: "Exclusively for you",
        value: "exclusively"
    }
]



const Coupons = () => {
  const [activeTab,setActiveTab] = useState(tabs[0])

  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.user.currentUser)
  const allCoupons = useSelector((state) => state.coupons.allCoupons)
  const userCoupons = useSelector((state) => state.coupons.userCoupons)

  useEffect(() => {
    let filter = {
      where: {
        expires: { $gte: new Date().getTime() }
      }
    }
    if(currentUser?.id){
      if(activeTab.value === "all"){
        fetchAllCoupons(dispatch, filter)
      } else {
          fetchUserCoupons(currentUser.id,dispatch)
      }
    }
  },[activeTab,currentUser])

  const couponData = activeTab.value === "all" ? allCoupons : userCoupons;

  return (
    <div className={classes.main}>
      <div className={classes.container}>
       <div className={classes.tabs}>
           {
            tabs.map((data) => (
                <div className={`${classes.tab} ${activeTab.value === data.value ? classes.activeTab : ""}`} onClick={() => setActiveTab(data)}>{data.name}</div>
            ))
           }
       </div>

       <div className={classes.couponSection}>
          {couponData?.map((coupon,index) => (
            <CouponCard 
            key={index} 
            discountAmount={coupon.value} 
            minItemQuantity={coupon.minItemQuantity} 
            minOrderValue={coupon.minOrderValue}
            authorName={coupon.authorName}
            couponCode={coupon.code}
            endDate={coupon.expires}
            discountType={coupon.discountType}
            couponType={coupon.couponType}
            />
          ))}
       </div>
      </div>
    </div>
  );
};

export default Coupons;