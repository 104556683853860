import { useContext } from "react";
import Modal, { ModalBody } from "../../../../Components/Modal/Modal";
import classes from "./CustomizationDetails.module.css";
import { CustomizationContext } from "../../Customization";
import { getBookPrice, getRandomImageForNormalBook } from "../../../../Util";
import { useSelector } from "react-redux";

const CustomizationDetails = ({ onClose }) => {
  const allColorsData = useSelector((state) => state?.bookColor?.colors);
  const { bookQty, setBookQty } = useContext(CustomizationContext);
  const { bookLeather, bookVariant, bookData, frontCoverColor,customizationCharges } =
    useContext(CustomizationContext);
  const bookImage = getRandomImageForNormalBook(
    bookData,
    allColorsData,
    frontCoverColor?.colorName,
    bookLeather,
    bookVariant
  )?.image;
  const imageAlt = getRandomImageForNormalBook(
    bookData,
    allColorsData,
    frontCoverColor?.colorName,
    bookLeather,
    bookVariant
  )?.altText;

  const incrementCount = () => {
    setBookQty(bookQty + 1);
  };

  const decrementCount = () => {
    if (bookQty === 1) {
      return;
    }
    setBookQty(bookQty - 1);
  };

  
  return (
    <Modal onClose={onClose} title={"Review and Confirm"}>
      <ModalBody>
        <div className={classes.container}>
          <div className={classes.viewDetails}>
            <div className={classes.bookDetails}>
              <div className={classes.bookImage}>
                <img src={bookImage} alt={imageAlt} />
              </div>
              <div className={classes.bookInfo}>
                <h2 className={classes.bookTitle}>{bookData?.title}</h2>
                <p className={classes.authorName}>{bookData?.author}</p>
                <p className={classes.bookPrice}>${getBookPrice(bookData, bookLeather, bookVariant)}</p>
                <div className={classes.quantity}>
                  <button className={classes.quantityButton} onClick={decrementCount}>-</button>
                  <span className={classes.quantityValue}>{bookQty}</span>
                  <button className={classes.quantityButton} onClick={incrementCount}>+</button>
                </div>
              </div>
            </div>

            <div className={classes.customizationCharges}>
              <h3>Add-on Customizations</h3>
              <div className={classes.customizationList}>
                {customizationCharges?.frontCoverCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Front Cover</h4>
                    {customizationCharges.frontCover.outerBorderCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Outer Border</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.outerBorderCharges}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.innerBorderCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Inner Border</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.innerBorderCharges}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.marblePaperCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Marble Paper</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.marblePaperCharges}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.fontCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Custom Font</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.fontCharges}</p>
                      </div>
                    )}
                        {customizationCharges.frontCover.textColorCharges > 0 && (
                          <div className={classes.customizationItemDetail}>
                            <p>Text Color Charges</p>
                            <p className={classes.optionPrice}>${customizationCharges.frontCover.textColorCharges}</p>
                          </div>
                        )}
                    {customizationCharges.frontCover.logoCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Front Cover Logo</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.logoCharges}</p>
                      </div>
                    )}
                   
                  </div>
                )}

                {customizationCharges?.spineCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Spine</h4>
                    {customizationCharges?.spine?.sectionColorCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Spine Section Color</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.sectionColorCharges}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.logoCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Custom Logo Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.logoCharges}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.fontCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Font Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.fontCharges}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.textCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>TextBox Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.textCharges}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.textColorCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Text Color Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.textColorCharges}</p>
                      </div>
                    )}
                  </div>
                )}

                {customizationCharges?.gildingCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Gilding</h4>
                    {customizationCharges.gildingCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Gilding Color</p>
                        <p className={classes.optionPrice}>${customizationCharges?.gildingCharges}</p>
                      </div>
                    )}
                  </div>
                )}
                {customizationCharges?.endpaperCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Endpaper</h4>
                    {customizationCharges.endpaperCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Endpaper Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.endpaperCharges}</p>
                      </div>
                    )}
                  </div>
                )}

                {customizationCharges?.dedicationPageCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Dedication Page</h4>
                    {customizationCharges.dedicationPageCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Dedication Page Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.dedicationPageCharges}</p>
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className={classes.orderSummary}>
            <h3 className={classes.orderSummaryTitle}>Order Summary</h3>
            <div className={classes.summaryItem}>
              <span>Book Price:</span>
              <span>${getBookPrice(bookData, bookLeather, bookVariant)}</span>
            </div>
            <div className={classes.summaryItem}>
              <span>Customization Price:</span>
              <span>${customizationCharges.totalCharges?.toFixed(2)}</span>
            </div>
            <div className={classes.summaryItem}>
              <span>Total Quantity:</span>
              <span>{bookQty}</span>
            </div>
            <hr className={classes.divider} />
            <div className={classes.summaryTotal}>
              <span>Total:</span>
              <span>${(getBookPrice(bookData, bookLeather, bookVariant) * bookQty)?.toFixed(2) + (customizationCharges.totalCharges?.toFixed(2) * bookQty)?.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomizationDetails;