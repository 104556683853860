import { useDispatch, useSelector } from "react-redux"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import Gallery from "../../Body/Gallery/Gallery"
import Footer from "../../Footer/Footer"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import FAQ from "../FAQ/FAQ"
import classes from "./CorporateGifts.module.css"
import { useEffect } from "react"
import { getAllFaq } from "../../../Store/actions/FaqAction"

import AboutCorporateGifts from "./AboutCorporateGifts/AboutCorporateGifts"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";

const CorporateGifts = () => {
  const dispatch = useDispatch()
  const crumbs = [
    { title: "Home", path: ("/") },
    { title: "Corporate Gifts", path: urls.CORPORATEGIFTS },
  ]

  const video = useSelector((state) => state.serviceVideo?.videos?.forCorporateGiftsVideo)
  const allFaq = useSelector(state => state.Faq.questions);

  useEffect(() => {
    getAllFaq("category", "Corporate Gifting", "", dispatch)
  }, [])
  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader crumbs={crumbs} title={"Elevate Your Brand with Custom Corporate Gifts"} desc={"Unique, High-Quality Gifts that Leave a Lasting Impression"} />

      <ServiceVideo
        videoSrc={video}
        title={`“Our finely leather-bound books combine sophistication and intellectual stimulation, making them the ideal business gifts”`}
        desc={"We understand that your goal as a business owner is to increase your staff's knowledge, morale, and happiness. Our fine leather-bound books combine sophistication and intellectual stimulation and make the ideal business gifts.Whether for the holidays, business achievements, or special recognitions, picking the appropriate present may improve relationships and express your gratitude. At Rare Biblio, we're experts in producing high-end, leather-bound books that are ideal for corporate gifts because they combine style, substance, and personalization in a way that will appeal to your receivers."}
      />

      <AboutCorporateGifts />
      <div className={classes.join}>
        <h2>
          For Your Corporate Gifting Requirements, Collaborate with Rare Biblio
        </h2>
        <p>
          Make a statement this season by giving a present that combines luxury, customization, and tradition.
          Leather-bound books from Rare Biblio are more than just presents; they're a tribute to your company's values and a token of gratitude to all who have helped it succeed.

          Get in touch with us right now to learn more about our selection of leather-bound books and how we can assist you in crafting the ideal business present.

          Together, we can make your gift-giving experience remarkable and remarkable.

        </p>
      </div>
      <Gallery />

      <CustomerReview />

      <FAQ data={allFaq} />
      <div className={classes.contactUs}>
        <ContactUs title={"Let’s Get Started"}
          desc={"With Rare Biblio, turn your text into a gorgeously printed book. Start your publishing adventure now by looking through our print-on-demand alternatives! Our committed team of experts is available to assist you if you need it or if you have any queries.Are you prepared to release your book? To begin creating, fill out the form below, or get in touch with us for further details."}
        />
      </div>

      <Footer />
    </div>
  )
}

export default CorporateGifts