import axios from "axios";
import { SERVICE_URL } from "../ServiceUrls";
import { BookActions } from "../Slices/BookSlice";
import { KeywordsAction } from "../Slices/KeywordsSlice";
import {getRandomImageForNormalBook } from "../../Util";
import { store } from "../Store";
import { SearchRecommendationAction } from "../Slices/SearchRecommendtionSlice";

export const fetchBook = (
  field,
  query,
  dispatch,
  limit = 16,
  page = 1,
  sortParameter = null,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre
) => {
  let filter = {
    where: {
      $and: [],
    },
  };
  
  if(query){
    query = `'${query}'`
  // Add field and query conditions
  if (field !== "all") {
    filter.where.$and.push({ [field]: query });
  } else {
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });
  }
 }
  // Add publishingYear range condition in the format "MIN TO MAX"
  if (minPublishingYear || maxPublishingYear) {
    const publishingYearCondition = {
      publishingYear: `[${minPublishingYear || "*"} TO ${
        maxPublishingYear || "*"
      }]`,
    };
    filter.where.$and.push(publishingYearCondition);
  }

  if (selectedGenre && selectedGenre?.length > 0) {
    filter.where.$and.push({ genres: selectedGenre.join(",") });
  }

  // Add sorting if provided
  if (sortParameter) {
    filter.sortBy = sortParameter;
  }

  filter.limit = limit;
  filter.offset = (page - 1) * limit;

  return axios({
    url: `${SERVICE_URL.BOOK}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        const booksData = response.data.data;
        const allColorsData = store.getState().bookColor.colors;
        const addRandomImagesToBookData = booksData.map((data) =>  ({...data, imageUrl: getRandomImageForNormalBook(data, allColorsData )?.image }));
        dispatch(BookActions.updateBooks(addRandomImagesToBookData));
        return { data: addRandomImagesToBookData, total: response.data.total };
      }
      return { data: [], total: 0 };
    })
    .catch((error) => {
      console.error(error);
      return { data: [], total: 0 };
    });
};

export const fetchBookById = (bookId, dispatch) => {
  const filter = {
    where: {
      id: bookId,
    },
  };

  return axios({
    url: `${SERVICE_URL.BOOK}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(BookActions.updateBookById(response.data.data));
        return response.data.data;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const searchWithInSearch = (
  query,
  additionalQuery,
  dispatch,
  limit = 16,
  page = 1,
  sortParameter = null,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre
) => {
  let filter = {
    where: {
      $and: [],
    },
  };

  // Add main query conditions
  query = `'${query}'`
  filter.where.$and.push({
    $or: [{ author: query }, { title: query }, { language: query }],
  });

  // Add additional query conditions
  if (additionalQuery) {
    additionalQuery = `'${additionalQuery}'`
    filter.where.$and.push({
      $or: [
        { author: additionalQuery },
        { title: additionalQuery },
        { language: additionalQuery },
      ],
    });
  }

  // Add publishingYear range condition in the format "MIN TO MAX"
  if (minPublishingYear || maxPublishingYear) {
    const publishingYearCondition = {
      publishingYear: `[${minPublishingYear || "*"} TO ${
        maxPublishingYear || "*"
      }]`,
    };
    filter.where.$and.push(publishingYearCondition);
  }

  // Add genre condition if selectedGenre is provided
  if (selectedGenre && selectedGenre?.length > 0) {
    filter.where.$and.push({ genres: selectedGenre.join(",") });
  }

  // Add sorting if provided
  if (sortParameter) {
    filter.sortBy = sortParameter;
  }

  filter.limit = limit;
  filter.offset = (page - 1) * limit;

  return axios({
    url: `${SERVICE_URL.BOOK}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        const booksData = response.data.data;
        const allColorsData = store.getState().bookColor.colors;
        const addRandomImagesToBookData = booksData.map((data) =>  ({...data, imageUrl: getRandomImageForNormalBook(data, allColorsData )?.image }));
        dispatch(BookActions.updateBooks(addRandomImagesToBookData));
        return { data: addRandomImagesToBookData, total: response.data.total };
      }
      return { data: [], total: 0 };
    })
    .catch((error) => {
      console.error(error);
      return { data: [], total: 0 };
    });
};

export const fetchBookCount = (
  field,
  query,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre,
  additionalQuery
) => {
  let filter = {
    where: {
      $and: [],
    },
  };

  if (!additionalQuery) {
    if (field !== "all" && query) {
      query = `'${query}'`
      filter.where.$and.push({ [field]: query });
    } else if (query) {
      query = `'${query}'`
      filter.where.$and.push({
        $or: [{ author: query }, { title: query }, { language: query }],
      });
    }

    // Add publishingYear range condition in the format "MIN TO MAX"
    if (minPublishingYear || maxPublishingYear) {
      const publishingYearCondition = {
        publishingYear: `[${minPublishingYear || "*"} TO ${
          maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    // Add genre condition if selectedGenre is provided
    if (selectedGenre && selectedGenre?.length > 0) {
      filter.where.$and.push({ genres: selectedGenre.join(",") });
    }
  } else {
    query = `'${query}'`
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });

    // Add additional query conditions
    if (additionalQuery) {
      additionalQuery = `'${additionalQuery}'`
      filter.where.$and.push({
        $or: [
          { author: additionalQuery },
          { title: additionalQuery },
          { language: additionalQuery },
        ],
      });
    }

    // Add publishingYear range condition in the format "MIN TO MAX"
    if (minPublishingYear || maxPublishingYear) {
      const publishingYearCondition = {
        publishingYear: `[${minPublishingYear || "*"} TO ${
          maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    // Add genre condition if selectedGenre is provided
    if (selectedGenre && selectedGenre?.length>0) {
      filter.where.$and.push({ genres: selectedGenre.join(",") });
    }
  }

  return axios({
    url: `${SERVICE_URL.BOOK}/count?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getKeywords = (dispatch) => {
  return axios({
    url: `${SERVICE_URL.BOOK}/keywords`,
    method: "get",
    responseType: "json",
  }).then((response) => {
    if (response.data.success) {
      dispatch(KeywordsAction.updateKeywords(response.data.data));
    }
  });
};

export const getFacets = () => {
  return axios({
    url: `${SERVICE_URL.BOOK}/facets`,
    method: "get",
    responseType: "json",
  }).then((response) => {
    if (response.data.success) {
      return response.data.data;
    }
  });
};

export const getSuggestionsForNormalBooks = (dispatch,field,
  query,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre, isFilterPresent,facet="title,author") => {
  let filter = {
    where: {
      $and: [],
    },
  };
  
  if(query){
    query = `'${query}'`
  // Add field and query conditions
  if (field !== "all") {
    filter.where.$and.push({ [field]: query });
  } else {
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });
  }
 }
  // Add publishingYear range condition in the format "MIN TO MAX"
  if (minPublishingYear || maxPublishingYear) {
    const publishingYearCondition = {
      publishingYear: `[${minPublishingYear || "*"} TO ${
        maxPublishingYear || "*"
      }]`,
    };
    filter.where.$and.push(publishingYearCondition);
  }

  if (selectedGenre && selectedGenre?.length > 0) {
    filter.where.$and.push({ genres: selectedGenre.join(",") });
  }

  return axios({
    url: `${SERVICE_URL.BOOK}/facetsAll?facets=${facet}` + (isFilterPresent ? `&filter=${encodeURIComponent(JSON.stringify(filter))}` : ""),
    method: "get",
    responseType: "json",
  }).then((res) => {
    if(res.data.success) {
      dispatch(SearchRecommendationAction.setRecommendations(res.data.data))
    }
  })
}

export const getSuggestionsForNormalBooksWithinSearch = (dispatch,
  query,
  additionalQuery,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre, isFilterPresent) => {
    let filter = {
      where: {
        $and: [],
      },
    };
  
    // Add main query conditions
    query = `'${query}'`
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });
  
    // Add additional query conditions
    if (additionalQuery) {
      additionalQuery = `'${additionalQuery}'`
      filter.where.$and.push({
        $or: [
          { author: additionalQuery },
          { title: additionalQuery },
          { language: additionalQuery },
        ],
      });
    }
  
    // Add publishingYear range condition in the format "MIN TO MAX"
    if (minPublishingYear || maxPublishingYear) {
      const publishingYearCondition = {
        publishingYear: `[${minPublishingYear || "*"} TO ${
          maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }
  
    // Add genre condition if selectedGenre is provided
    if (selectedGenre && selectedGenre?.length > 0) {
      filter.where.$and.push({ genres: selectedGenre.join(",") });
    }
  

  return axios({
    url: `${SERVICE_URL.BOOK}/facetsAll?facets=title,author` + (isFilterPresent ? `&filter=${encodeURIComponent(JSON.stringify(filter))}` : ""),
    method: "get",
    responseType: "json",
  }).then((res) => {
    if(res.data.success) {
      dispatch(SearchRecommendationAction.setRecommendations(res.data.data))
    }
  })
}

export const getSuggestionsForBestSellerBooks = (field,
  query,
  minPublishingYear,
  maxPublishingYear,
  selectedGenre, isFilterPresent) => {
  let filter = {
    where: {
      $and: [],
    },
  };
  
  if(query){
    query = `'${query}'`
  // Add field and query conditions
  if (field !== "all") {
    filter.where.$and.push({ [field]: query });
  } else {
    filter.where.$and.push({
      $or: [{ author: query }, { title: query }, { language: query }],
    });
  }
 }
  // Add publishingYear range condition in the format "MIN TO MAX"
  if (minPublishingYear || maxPublishingYear) {
    const publishingYearCondition = {
      publishingYear: `[${minPublishingYear || "*"} TO ${
        maxPublishingYear || "*"
      }]`,
    };
    filter.where.$and.push(publishingYearCondition);
  }

  if (selectedGenre && selectedGenre?.length > 0) {
    filter.where.$and.push({ genres: selectedGenre.join(",") });
  }

  return axios({
    url: `${SERVICE_URL.BESTSELLERBOOK}/facetsAll?facets=title,author` + (isFilterPresent ? `&filter=${encodeURIComponent(JSON.stringify(filter))}` : ""),
    method: "get",
    responseType: "json",
  }).then((res) => {
    if(res.data.success) {
      return res.data.data;
    }
  })
}