import axios from 'axios';

const CUSTOMIZATION_URL = "/api/customization-designs"
const CUSTOMIZATION_PRICING = "/api/customization-pricing"

export function getAllUserDesigns(userid){
    const filter = {
        where : {
            userId : userid
        }
    }
    return axios({
        url:`${CUSTOMIZATION_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method:"get"
    })
}

export function saveNewDesign(JSONLinks,bookCustomization,variant,leather){
    const formData = new FormData();

    JSONLinks.forEach((blob, index) => {
        formData.append(`files`, blob, `file${index}.json`);
    });
    formData.append('bookCustomization', JSON.stringify(bookCustomization));
    formData.append('variant', variant);
    formData.append('leatherType', leather);

    return axios({
        url:`${CUSTOMIZATION_URL}/create`,
        data:formData,
        method:"post"
    })
}

export function updateCustomizationDesign(designId,JSONLinks,bookCustomization){
    const formData = new FormData();

    JSONLinks.forEach((blob, index) => {
        formData.append(`files`, blob, `file${index}.json`);
    });

    formData.append("bookCustomization",JSON.stringify(bookCustomization));
   
    return axios({
        url:`${CUSTOMIZATION_URL}/update/${designId}`,
        data:formData,
        method:"put"
    })
}

export function deleteCustomizationDesign(designId){
    return axios({
        url:`${CUSTOMIZATION_URL}/delete/${designId}`,
        method:"delete"
    })
}

export function calculateCustomizationCharges(bookCustomization) {
    return axios({
        url:`${CUSTOMIZATION_PRICING}/calculate`,
        method:"post",
        data:bookCustomization
    })
}


