import { useContext, useEffect, useState } from "react";
import classes from "./Sidebar.module.css";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import OptionCard from "../OptionCard/OptionCard";
import { CustomizationContext } from "../../Customization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const sidebarSteps = [
  {
    option: "Front Cover",
    number: "01",
    step: 1,
  },
  {
    option: "Spine",
    number: "02",
    step: 2,
  },
  {
    option: "Paper",
    number: "03",
    step: 3,
  },
  {
    option: "Endpaper",
    number: "04",
    step: 4,
  },
  {
    option: "Additional Request",
    number: "05",
    step: 5,
  },
];


const Sidebar = ({ onClick, currentStep, setDrawerOpen,smallScreenSteps }) => {
    const [active, setActive] = useState("Front Cover");
    const { isMediumScreen } = useScreenSize();
    const { steps, setSteps, selectedOption,setSelectedOption } = useContext(CustomizationContext);
    const [isComponentOpen, setIsComponentOpen] = useState(false);

  useEffect(() => {
    if (currentStep > 1) {
      setActive(sidebarSteps[currentStep - 1].option);
    }
  }, [currentStep]);

  const optionClickHandler = (page) => {
    setActive(page.option);
    setDrawerOpen(true);
    onClick(page.step);
  };

  const handleClick = (step) => {
    const selectedStep = smallScreenSteps.find((stepOption) => stepOption.step === step);
    
    if (selectedStep) {
      if (!selectedStep.options || selectedStep.options.length === 0) {
        // If no options are available, set the selected option and do not open the second menu
        setSelectedOption(selectedStep.option);
        setIsComponentOpen(false); // Ensure the second menu does not open
      } else {
        // If options are available, open the second menu
        setIsComponentOpen(true);
      }
      setSteps(step);
    }
  };
  

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName); // Set the clicked option as selected
  };

  const selectedStepOption = smallScreenSteps?.find((stepOption) => stepOption.step === steps);

  return (
    <>
      {!isMediumScreen ? (
        <div className={classes.mainContainer}>
          {sidebarSteps.map((steps, index) => (
            <div
              key={index}
              className={
                active === steps.option ? classes.active : classes.steps
              }
              onClick={() => optionClickHandler(steps)}
            >
              <h2>{steps.number}</h2>
              <p>{steps.option}</p>
            </div>
          ))}
        </div>
      ) : (
        <div>
        {!isComponentOpen ? (
          <div className={classes.options}>
            <h2>Book Customization Components</h2>
            <div className={classes.customizationOptions}>
              {smallScreenSteps.map((stepOption) => (
                <OptionCard
                  className={classes.optionCard}
                  image={stepOption.icon}
                  desc={stepOption.option}
                  active={steps === stepOption.step}
                  key={stepOption.step}
                  onClick={() => handleClick(stepOption.step)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.openedOption}>
            <div className={classes.openedOptionTitle} onClick={() => setIsComponentOpen(false)}>
              <FontAwesomeIcon icon={faAngleLeft} />
              <h2>{selectedStepOption?.option}</h2>
            </div>
            <div className={classes.customizationOptions}>
              {selectedStepOption?.options.map((option) => (
                <OptionCard
                  className={`${classes.optionCard} ${selectedOption === option.name ? classes.activeOption : ''}`} // Add active styling for selected option
                  image={option.icon}
                  active={selectedOption === option.name}
                  desc={option.name}
                  key={option.name}
                  onClick={() => handleOptionClick(option.name)} // Set selected option on click
                />
              ))}
            </div>
          </div>
        )}
      </div>
      )}
    </>
  );
};

export default Sidebar;
