import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import SearchComponent from "./SearchComponent/SearchComponent";
import Sidebar from "./Sidebar/Sidebar";
import Carousel from "./Corousel/Corousel";
import Card from "./Card/Card2";
import Community from "./Community/Community";
import BestSeller from "./BestSeller/BestSeller";
import BestAuthor from "./BestAuthor/BestAuthor";
import Footer from "../Footer/Footer";
import classes from "./Listing.module.css";
import {
  fetchBook,
  fetchBookCount,
  getFacets,
  getSuggestionsForNormalBooks,
  getSuggestionsForNormalBooksWithinSearch,
  searchWithInSearch,
} from "../../Store/actions/BookAction";
import { useDispatch, useSelector } from "react-redux";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../../Components/Inputs/Input/Input";
import { getRandomImageForNormalBook } from "../../Util";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { urls } from "../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TrendingBooks from "./TrendingBooks/TrendingBooks";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";

const Listing = () => {
  const allColorsData = useSelector((state) => state.bookColor.colors);

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchResults, setSearchResults] = useState(
    location.state?.searchResults?.data || null
  );

  
  const [totalResults, setTotalResults] = useState(
    location.state?.searchCount || 0
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const searchCriteria = location.state?.searchCriteria || "all";
  const [query,setQuery] = useState(location.state?.query || "");
  const [searchedQuery,setSearchedQuery] = useState(location.state?.query || "");
  
  const [additionalQuery, setAdditionalQuery] = useState("");
  
  const [sortBy, setSortBy] = useState(null);
  const [minPublishingYear, setMinPublishingYear] = useState(null);
  const [maxPublishingYear, setMaxPublishingYear] = useState(null);
  const [allowedMinPublishingYear,setAllowedMinPublishingYear] = useState(0);
  const [allowedMaxPublishingYear,setAllowedMaxPublishingYear] = useState(0);
  const [allGenres,setAllGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [isPageChange, setIsPageChange] = useState(false);

  const totalPages = Math.ceil(totalResults / itemsPerPage);

  const [sidebarTopPosition, setSidebarTopPosition] = useState(0);
  const searchHeadingRef = useRef(null);

  useEffect(() => {
    if (searchHeadingRef.current) {
      // Get the height of the heading and apply margin/padding to sidebar
      const headingHeight = searchHeadingRef.current.getBoundingClientRect().top;
      setSidebarTopPosition(headingHeight);
    }
  }, [searchResults]);

  // Fetch facet data (like publishing year)
  useEffect(() => {
    getFacets().then((data) => {
      setAllGenres(data?.genres);
      setAllowedMinPublishingYear(data?.publishingYear?.min)
      setAllowedMaxPublishingYear(data?.publishingYear?.max)
    });
  }, []);

  useEffect(() => {
    setMinPublishingYear(allowedMinPublishingYear);
    setMaxPublishingYear(allowedMaxPublishingYear);
  }, [allowedMinPublishingYear,allowedMaxPublishingYear]);

  const scrollRef = useScrollToTop();

  const removeHistoryState = () => {
    history.replace({
      pathname: urls.LISTING,
      state: { }
    });
  }
  // Fetch page data without additional query (normal search)
  const fetchPageData = async () => {
    if (query !== "") {
      setSearchedQuery(query)
      if (sortBy === null) {
        const data = await fetchBook(
          searchCriteria,
          query,
          dispatch,
          itemsPerPage,
          isPageChange ? currentPage : 1,
          null,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
          selectedGenres
        );
        const total = await fetchBookCount(
          searchCriteria,
          query,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
          selectedGenres
        ); // Removed dispatch here as fetchBookCount doesn't require it

        removeHistoryState();
        setSearchResults(data.data); // Ensure data is correctly set
        if(totalResults !== total){
          setCurrentPage(1)
        }
        setTotalResults(total); // Update total results when new data is fetched
      } else {
        const data = await fetchBook(
          searchCriteria,
          query,
          dispatch,
          itemsPerPage,
          isPageChange ? currentPage : 1,
          sortBy,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
          selectedGenres
        );
        const total = await fetchBookCount(
          searchCriteria,
          query,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
          selectedGenres
        );  // Removed dispatch here as fetchBookCount doesn't require it

        removeHistoryState();
        setSearchResults(data.data); // Ensure data is correctly set
        if(totalResults !== total){
          setCurrentPage(1)
        }
        setTotalResults(total); // Update total results when new data is fetched
      }
    }
  };

  // Fetch data when using "search within search"
  const fetchSearchWithinSearch = async () => {
    if (searchedQuery && additionalQuery !== "") {
      if (sortBy === null) {
        const data = await searchWithInSearch(
          searchedQuery,
          additionalQuery,
          dispatch,
          itemsPerPage,
          currentPage
        );
        const total = await fetchBookCount("all",searchedQuery,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
            selectedGenres,
            additionalQuery); 

        removeHistoryState();
        setSearchResults(data.data); // Ensure data is correctly set
        if(totalResults !== total){
          setCurrentPage(1)
        }
        setTotalResults(total); // Update total results when new data is fetched
      } else {
        const data = await searchWithInSearch(
          query,
          additionalQuery,
          dispatch,
          itemsPerPage,
          currentPage,
          sortBy
        );
        const total = await fetchBookCount("all",query,
          minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
          maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
            selectedGenres,
            additionalQuery); 
        removeHistoryState();
        setSearchResults(data.data); // Ensure data is correctly set
        if(totalResults !== total){
          setCurrentPage(1)
        }
        setTotalResults(total); // Update total results when new data is fetched
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchSearchWithinSearch();
    }
  };

  // Call fetchPageData when search criteria, sortBy, or page changes
  useEffect(() => {
    fetchPageData();
  }, [currentPage, searchCriteria, sortBy]);

  useEffect(() => {
    setTimeout(fetchPageData, 1000);
  }, [selectedGenres, minPublishingYear, maxPublishingYear]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setIsPageChange(true);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setIsPageChange(true);
    }
  };

  const handleClear = () => {
    removeHistoryState();
    setSearchResults(null);
    setTotalResults(null);
    setQuery("");
  }

  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Listing", path: "/listing" },
  ];

  const allRecommendations = useSelector((state) => state.recommendation.recommendations);

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);
  const [hasCalledForWord, setHasCalledForWord] = useState(false);

  const handleRecommendationClick = (recommendation) => {
    const words = additionalQuery.trim().split(" ");
    
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setAdditionalQuery(words.join(" "));
    } else {
      setAdditionalQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };

  useEffect(() => {
    if(recommendationClicked) {
      fetchPageData();
      setCurrentPage(1)
      setRecommendationClicked(false);
    }
  },[recommendationClicked])


useEffect(() => {
  if (additionalQuery) {
    // If it’s the very first letter typed, call the API
    if (!hasCalledForWord) {
      getSuggestionsForNormalBooks(dispatch, "all", searchedQuery,additionalQuery.trim(), minPublishingYear, maxPublishingYear, selectedGenres, true);
      setHasCalledForWord(true);
    }
    
    // If a new word begins (i.e., there is a space followed by a letter), reset and call the API
    const pattern = /\s\S$/;
    if (pattern.test(additionalQuery)) {
      getSuggestionsForNormalBooks(dispatch, "all", searchedQuery,additionalQuery.trim(), minPublishingYear, maxPublishingYear, selectedGenres, true);
      setHasCalledForWord(true);
    }
  }

  // Reset hasCalledForWord when query is empty
  return () => {
    if (!additionalQuery) {
      setHasCalledForWord(false);
    }
  };
}, [additionalQuery]);

  useEffect(() => {
    if (additionalQuery) {
      const words = additionalQuery.trim().split(/\s+/);
      const searchWord = words.length === 1 ? additionalQuery.toLowerCase() : words[words.length - 1].toLowerCase();
      setSearchRecommedations(
        allRecommendations.filter(word => word.toLowerCase().startsWith(searchWord))
      );
    } else {
      setSearchRecommedations([]);
    }
  }, [additionalQuery, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };

  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false), 1000);
    setBlurTimeout(timeout);
  };

  return (
    <div className={classes.mainContainer} ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader crumbs={crumbs} title={"Our Collections"} />

      <div className={classes.nav}>
        <div className={classes.headerText}>
          <h2>Find your next book here </h2>
          {/* {searchResults.length !== 0 && <p>found {totalResults} results</p>} */}
        </div>
        <div className={classes.SearchComponent}>
          <SearchComponent query={query} setQuery={setQuery} onSearch={() => {fetchPageData(); setCurrentPage(1)}} minYear = {minPublishingYear} maxYear = {maxPublishingYear} selectedGenres={selectedGenres} />
          <div style={{display: "flex", alignItems: "center"}}>
          {searchResults && <div className={classes.sortBtn}>
            <select
              name="sortBy"
              id="sortBy"
              onClick={(e) => setSortBy(e.target.value)}
            >
              <option value="" defaultChecked disabled>
                Sort By
              </option>
              <option value="title ASC">A-Z</option>
              <option value="title DESC">Z-A</option>
            </select>
          </div>}
          </div>
        </div>
      </div>

      <div className={classes.mainSection}>
        <div style={{minWidth :!searchResults ? "11.5rem" : ""}} className={classes.sidebar}>
          {searchResults && <Sidebar
            allGenres={allGenres}
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            maxYear={maxPublishingYear}
            minYear={minPublishingYear}
            setMaxYear={setMaxPublishingYear}
            setMinYear={setMinPublishingYear}
            allowedMaxYear={allowedMaxPublishingYear}
            allowedMinYear={allowedMinPublishingYear}
            sidebarTop={sidebarTopPosition}
          />}
        </div>
        <div className={classes.content}>
          {searchResults && searchResults.length >= 0 ? (
            <div className={classes.searchResult} ref={searchHeadingRef}>
             <div className={classes.searchHeading} onClick={handleClear} >
              <FontAwesomeIcon className={classes.backBtn} icon={faAngleLeft} />
              <h2>Search Results {searchedQuery && <span>"{searchedQuery}"</span>}</h2>
             </div>
              <p className={classes.searchCount}>
                Showing {totalResults} results
              </p>

              <div className={classes.withinContainer}>
                <div className={classes.searchWithin}>
                  <Input
                    type="text"
                    value={additionalQuery}
                    onChange={(e) => setAdditionalQuery(e.target.value)}
                    placeholder="Search within these results"
                    onKeyDown={handleKeyPress}
                    onFocus={handleFocus}  
                    onBlur={handleBlur} 
                  />
                  <FontAwesomeIcon
                    className={classes.searchIconWithin}
                    icon={faMagnifyingGlass}
                    onClick={fetchSearchWithinSearch}
                  />
                </div>
                {/* Dropdown with Recommendations */}
                {searchRecommendations.length > 0 && focus && (
                  <div className={classes.dropdown}>
                    {searchRecommendations.slice(0,10).map((recommendation, index) => (
                      <div
                        key={index}
                        className={classes.dropdownItem}
                        onClick={() => handleRecommendationClick(recommendation)}
                      >
                        <FontAwesomeIcon icon={faSearchengin} />
                        {recommendation}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={classes.cardGrid}>
                {searchResults.map((card, index) => (
                  <Card
                    key={index}
                    image={
                      card.imageUrl
                    }
                    altText={
                      getRandomImageForNormalBook(card, allColorsData)?.altText
                    }
                    title={card.title}
                    author={card.author}
                    price={
                      getRandomImageForNormalBook(card, allColorsData)
                        ?.variantType === "deluxe"
                        ? card.deluxePriceGenuine
                        : card.superDeluxePriceGenuine
                    }
                    bookData={card}
                  />
                ))}
              </div>
              {searchResults.length > 0 && (
                <div className={classes.paginationControls}>
                  <div className={classes.paginatonBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                  </div>

                  <span>
                    {currentPage > 2 && (
                      <a onClick={() => {setCurrentPage(1); setIsPageChange(true);}}>1</a>
                    )}
                    {currentPage > 3 && (
                      <span className={classes.dots}>...</span>
                    )}

                    {currentPage > 1 && (
                      <a onClick={() => {setCurrentPage(currentPage - 1); setIsPageChange(true);}}>
                        {currentPage - 1}
                      </a>
                    )}

                    {/* Highlighted current page */}
                    <span className={`${classes.pageNumber} ${classes.active}`}>
                      {currentPage}
                    </span>

                    {currentPage < totalPages && (
                      <a onClick={() => {setCurrentPage(currentPage + 1); setIsPageChange(true);}}>
                        {currentPage + 1}
                      </a>
                    )}

                    {currentPage < totalPages - 2 && (
                      <span className={classes.dots}>...</span>
                    )}
                    {currentPage < totalPages - 1 && (
                      <a onClick={() => {setCurrentPage(totalPages); setIsPageChange(true);}}>
                        {totalPages}
                      </a>
                    )}
                  </span>

                  <div className={classes.paginatonBtn}>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className={classes.carousel}>
                <Carousel />
              </div>
              <TrendingBooks />
              <div className={classes.bestSeller}>
                <BestSeller />
              </div>
              <div className={classes.search}>
                <Community query={query} setQuery={setQuery} onSearch={() => {fetchPageData(); setCurrentPage(1)}} minYear = {minPublishingYear} maxYear = {maxPublishingYear} selectedGenres={selectedGenres}/>
              </div>
              <div className={classes.bestAuthor}>
                <BestAuthor query={query} setQuery={setQuery} onSearch={() => {fetchPageData(); setCurrentPage(1);}}/>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Listing;
