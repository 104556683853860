import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import browserHistory from '../history';
import Home from '../Modules/Home/Home';
import { urls } from './Urls';
import LoginComponent from '../Modules/Login/Login';
import { ForgotPassword } from '../Modules/ForgotPassword/ForgotPassword';
import SignUp from '../Modules/SignUp/SignUp';
import { ContactUs } from '../Modules/ContactUs/ContactUS';
import FAQ from '../Modules/FAQ/Faq';
import Listing from '../Modules/ListingPage/Listing';
import ProductDetail from '../Modules/ProductDetail/ProductDetail';
import Viewer from '../Modules/WebGLViewer/Viewer';
import Editor from '../Modules/Editor/Editor';
import Customization from '../Modules/Customization/Customization';
import Services from '../Modules/Services/Services';
import MyCart from '../Modules/MyCart/MyCart';
import BookRestoration from '../Modules/Services/BookRestoration/BookRestoration';
import PDFPrinting from '../Modules/Services/PDFPrinting/PDFPrinting';
import ThesisBinding from '../Modules/Services/ThesisBinding/ThesisBinding';
import LeatherBinding from '../Modules/Services/LeatherBinding/LeatherBinding';
import OurCompany from '../Modules/OurCompany/OurCompany';
import MyAccount from '../Modules/MyAccount/MyAccount';
import RBExclusive from '../Modules/RBExclusive/RBExclusive';
import { isUserLoggedIn } from '../Util';
import ForPublishers from '../Modules/Services/ForPulishers/ForPublishers';
import BecomePartner from '../Modules/Services/BecomePartner/BecomePartner';
import ForAuthors from '../Modules/Services/ForAuthors/ForAuthors';
import PrintOnDemand from '../Modules/Services/PrintOnDemand/PrintOnDemand';
import CorporateGifts from '../Modules/Services/CorporateGifts/CorporateGifts';
import Checkout from '../Modules/Checkout/Checkout';
import ThankYou from '../Modules/Checkout/ThankYou/ThankYou';
import Blog from '../Modules/Blogs/Blog/Blog';
import BestSeller from '../Modules/BestSeller/BestSeller';
import BestSellerPDP from '../Modules/ProductDetail/BestSellerPDP/BestSellerPDP';
import Blogs from "../Modules/Blogs/Blogs"
import PrivacyPolicy  from '../Modules/PrivacyPolicy/PrivacyPolicy';
import LimitedEdition from '../Modules/LimitedEdition/LimitedEdition';
import ReturnRefunds from '../Modules/ReturnRefunds/ReturnRefunds';
const MyRouter = () => {
    const Protected = ({ routeProps, children }) => { 
        if (isUserLoggedIn()) {
            return children;
        } else {
            return (
            <Redirect
                to={{
                pathname: urls.LOGIN,
                state: {
                    from: routeProps.location.pathname,
                },
                }}
            />
            );
        }
    };
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route exact path={`${urls.HOME}`}>
                    <Home />
                </Route>
                <Route exact path={`${urls.SIGNUP}`}>
                    <SignUp />
                </Route>
                <Route exact path={`${urls.LOGIN}`}>
                    <LoginComponent />
                </Route>
                <Route exact path={`${urls.FORGOTPASSWORD}`}>
                    <ForgotPassword />
                </Route>
                <Route exact path={`${urls.CONTACTUS}`}>
                    <ContactUs />
                </Route>
                <Route
                    path={urls.USERPROFILE}
                    render={(routeProps) => (
                        <Protected routeProps={routeProps}>
                          <MyAccount />
                        </Protected>
                    )}
                />
                <Route exact path={`${urls.FAQ}`}>
                    <FAQ />
                </Route>
                <Route exact path={`${urls.LISTING}`}>
                    <Listing />
                </Route>
                <Route  path={urls.BESTSELLER}>
                    <BestSeller />
                </Route>
                <Route path={`${urls.BESTSELLERPDP}/:id`}>
                    <BestSellerPDP />
                </Route>
                <Route path={`${urls.BLOG}`}>
                    <Blogs />
                </Route>
                <Route path={`${urls.VIEWBLOG}/:slug`}>
                    <Blog />
                </Route>
                <Route exact path={`${urls.PRODUCTDETAIL}/:id`}>
                    <ProductDetail />
                </Route>
                <Route exact path={`${urls.VIEWER}`}>
                    <Viewer />
                </Route>
                <Route exact path={urls.EDITOR}>
                    <Editor />
                </Route>
                <Route
                    path={`${urls.CUSTOMIZATION}/:id`}
                    render={(routeProps) => (
                        <Protected routeProps={routeProps}>
                          <Customization />
                        </Protected>
                    )}
                />
                <Route exact path={urls.SERVICES}>
                    <Services />
                </Route>
                <Route exact path={urls.BOOKRESTORATION}>
                    <BookRestoration />
                </Route>
                <Route exact path={urls.PDFPRINTING}>
                    <PDFPrinting />
                </Route>
                <Route exact path={urls.THESISBINDING}>
                    <ThesisBinding />
                </Route>
                <Route exact path={urls.LEATHERBINDING}>
                    <LeatherBinding />
                </Route>
                <Route
                    path={urls.CART}
                    render={(routeProps) => (
                        <Protected routeProps={routeProps}>
                          <MyCart />
                        </Protected>
                    )}
                />
                <Route
                    path={urls.CHECKOUT}
                    render={(routeProps) => (
                        <Protected routeProps={routeProps}>
                          <Checkout />
                        </Protected>
                    )}
                />
                <Route
                    path={urls.CHECKOUT_THANK_YOU}
                    render={(routeProps) => (
                        <Protected routeProps={routeProps}>
                          <ThankYou />
                        </Protected>
                    )}
                />
                <Route exact path={urls.OURCOMPANY}>
                    <OurCompany />
                </Route>
                <Route exact path={urls.PRIVACYPOLICY}>
                    <PrivacyPolicy />
                </Route>
                <Route exact path={urls.LIMITEDEDITION}>
                    <LimitedEdition />
                </Route>
                <Route exact path={urls.RETURNREFUNDS}>
                    <ReturnRefunds />
                </Route>
                <Route exact path={urls.RB_EXCLUSIVE}>
                    <RBExclusive/>
                </Route>
                <Route exact path={urls.FORPUBLISHERS}>
                    <ForPublishers/>
                </Route>
                <Route exact path={urls.BECOMEPARTNER}>
                    <BecomePartner/>
                </Route>
                <Route exact path={urls.FORAUTHOR}>
                    <ForAuthors/>
                </Route>
                <Route exact path={urls.PRINTONDEMAND}>
                    <PrintOnDemand/>
                </Route>
                <Route exact path={urls.CORPORATEGIFTS}>
                    <CorporateGifts />
                </Route>
                
            </Switch>
        </Router>
    )
}

export default MyRouter