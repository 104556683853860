import classes from "./ContactUs.module.css";

import Header from "../Header/Header";

import { useState } from "react";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import Input from "../../Components/Inputs/Input/Input";
import PrimaryTextArea from "../../Components/Inputs/PrimaryTextArea/PrimaryTextArea";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import { ContactUsAction } from "../../Store/actions/ContactUsAction";
import { urls } from "../../Routes/Urls";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faPinterest,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Modal, { ModalBody, ModalFooter } from "../../Components/Modal/Modal"

const locatonIcon = "/Assets/Icons/locationDot.svg";
const emailIcon = "/Assets/Icons/email-icon.svg";
const email = "info@rarebiblio.com";
const addresses = [
  "Isle Royal St, Fremont, California, United States",
  "Redpath Avenue, Toronto, Ontario, Canada",
];

export function ContactUs() {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Contact Us", path: urls.CONTACTUS },
  ];

  const [fullName, setFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  const clearForm = () => {
    setContactNumber("");
    setCountryCode("");
    setFullName("");
    setMessage("");
    setUserEmail("");
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    const data = {
      name: fullName,
      phoneNo: countryCode + contactNumber,
      email: userEmail.toLowerCase(),
      message: message,
    };

    ContactUsAction(data).then((response) => {
      if (response.data.success === true) {
        clearForm();
        setShow(true);
      }
    });
  };

  const scrollRef = useScrollToTop();
  const handleIconClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const isFormValid = () =>{
    return fullName && userEmail && countryCode && contactNumber && message;
  }

  const inputPrevent = (e) => {
    const invalidChars = ["e", "E", "+", "-", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }   

  return (
    <>
   { show && <Modal>
      <ModalBody>
        <div className={classes.confirmation}>Thank you for contacting us. We'll be touch soon.</div>
      </ModalBody>
      <ModalFooter>
        <div className={classes.actions}>
          <PrimaryButton onClick={() => setShow(false)} className={classes.primaryAction}>OK</PrimaryButton>
        </div>
      </ModalFooter>
    </Modal>}
    <div ref={scrollRef}>
      <Header />
      <BannerHeader crumbs={crumbs} title={"Contact Us"} />
      {/* <div className={classes.header}>
            <div className = {classes.background}>
            <BreadCrumb
                isBack={true}
                crumbs={crumbs}
                onBack={() => {
                }}
            />
            </div>
            <h1>Contact us</h1>
        </div> */}
      <div className={classes.container}>
        <div className={classes.details}>
          <h2>Let's Talk</h2>
          <p>
            Please don't hesitate to reach out to us at your convenience. We'll
            respond promptly!{" "}
          </p>
          <div className={classes.addresses}>
            <div>
              <img src={locatonIcon} alt="" />
            </div>
            <div>
              <h3>Address</h3>
              {addresses.map((address, key) => (
                <p key={key}>{address}</p>
              ))}
            </div>
          </div>
          <div className={classes.email}>
            <div>
              <img src={emailIcon} alt="" />
            </div>
            <div>
              <h3>Email</h3>
              <p>{email}</p>
            </div>
          </div>
          <div className={classes.socialMediaBtns}>
            <p>Social Media</p>
            <div className={classes.socialBtns}>
              <div className={classes.socialMediaBtn}>
                <FontAwesomeIcon
                  onClick={() =>
                    handleIconClick("https://www.facebook.com/Rarebiblio")
                  }
                  className={classes.footerIcons}
                  icon={faFacebook}
                />
              </div>
              <div className={classes.socialMediaBtn}>
                <FontAwesomeIcon
                  onClick={() =>
                    handleIconClick("https://www.instagram.com/Rarebiblio")
                  }
                  className={classes.footerIcons}
                  icon={faInstagram}
                />
              </div>
              <div className={classes.socialMediaBtn}>
                <FontAwesomeIcon
                  onClick={() =>
                    handleIconClick("https://www.twitter.com/Rarebiblio")
                  }
                  className={classes.footerIcons}
                  icon={faXTwitter}
                />
              </div>
              <div className={classes.socialMediaBtn}>
                <FontAwesomeIcon
                  onClick={() =>
                    handleIconClick(
                      "https://www.linkedin.com/company/Rarebiblio"
                    )
                  }
                  className={classes.footerIcons}
                  icon={faLinkedinIn}
                />
              </div>
              <div className={classes.socialMediaBtn}>
                <FontAwesomeIcon
                  onClick={() =>
                    handleIconClick("https://www.pinterest.com/rare_biblio")
                  }
                  className={classes.footerIcons}
                  icon={faPinterest}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.formSection}>
          <form>
            <div className={classes.formGroup}>
              <div className={classes.fullName}>
                <label htmlFor="fullName">Full Name</label>
                <Input
                  type="text"
                  id="userId"
                  placeholder="Full Name"
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  value={fullName}
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="userEmail">Email Id</label>
              <Input
                type="email"
                id="userId"
                placeholder="Email"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                value={userEmail}
              />
            </div>
            <div className={classes.formGroup}>
              <div className={classes.contactNumber}>
                <select
                  name="countryCode"
                  id="countryCode"
                  required
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="Country Code" defaultChecked={true}>
                    Country Code
                  </option>
                  <option value="+1">+1</option>
                  <option value="+91">+91</option>
                  <option value="+44">+44</option>
                </select>
                <Input
                  type="number"
                  id="userId"
                  placeholder="Contact Number"
                  onChange={(e) => {
                    if(String(e.target.value).length <= 10){
                      setContactNumber(e.target.value);
                    }
                  }}
                  value={contactNumber}
                  min={1}
                  onKeyDown={(e) => inputPrevent(e)}
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="message">Message</label>
              <PrimaryTextArea
                placeholder="Enter Your Message"
                autoFocus={true}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
            </div>
            <SecondaryButton
              className={classes.submitBtn}
              onClick={(e) => handleFormSubmission(e)}
              children={"Submit"}
              disabled={!isFormValid()}
            />
          </form>
        </div>
      </div>
    </div>
    </>
  );
}
