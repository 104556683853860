import React from "react";
import InfoNav from "./InfoNav/InfoNav";
import PrimaryNav from "./PrimaryNav/PrimaryNav";

function Header() {
  return (
    <>
      <InfoNav />
      <PrimaryNav />
      {/* {!isSmallScreen && <ItemsNav />} */}
    </>
  );
}

export default Header;
