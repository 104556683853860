import classes from "./SelectEndpaper.module.css";

import Accordion from "../../Components/Accordion/Accordion";

import { useState, useEffect, useContext } from "react";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import Slider from "react-slick/lib/slider";
import { CustomizationContext } from "../../Customization";
import { getEndPaper } from "../../../../Store/actions/ColorsAction";
import { addImageToCanvas } from "../../../../objectUtils";
import { useFabricCanvas } from "react-fabric-page";
import PreviewCard from "../../Components/PreviewCard/PreviewCard";

const endpaperTabs = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];


const getBounds = (canvas) => {
  const layer = canvas?.getObjects()?.find((o) => o.id === "background");
  if (layer) {
    return {
      left: layer.left,
      top: layer.top,
      width: layer.width,
      height: layer.height,
    };
  }
  return null;
};

function setBookEndpaper(endpaper, canvas) {
  // return addImageToEndpaper(canvas,{ filepath: endpaper?.designImage, fileType: "image" }, 1)
  addImageToCanvas({ filepath: endpaper?.designImage, fileType: "image" },1,() => getBounds(canvas)).then((img) => {
    const layer = canvas?.getObjects()?.find((o) => o?.id === "background");
    if (layer) {
      layer._objects = layer._objects?.filter((o) => o.id !== "endpaper");
      layer.dirty = true;
      layer.add(img);
      img.id = "endpaper";
      img.hasControls = false;
      img.selectable = false;
      img.evented = false;
      layer.dirty = true;
      canvas.renderAll();
    }
  });
}

const SelectEndpaper = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {

  const {selectedEndpaper,setSelectedEndpaper,wantEndpaper,
    setWantEndpaper,selectedDesigns} = useContext(CustomizationContext);
  const [allEndpapers, setAllEndpapers] = useState([]);
  const [allowedEndpapers, setAllowedEndpapers] = useState([]);

  const { isMediumScreen } = useScreenSize();
  const canvas = useFabricCanvas();

  useEffect(() => {
    if(!wantEndpaper){
      setWantEndpaper(endpaperTabs[1].value)
    }
  },[])

  const settings = {
    slidesToShow: allowedEndpapers?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.2,
        }
      }
    ]
  };

  const getEndpapersData = async () => {
    let filter = {
      // offset: (currPageNo - 1) * pageSize,
      // limit: pageSize,
    };

    try {
      const res = await getEndPaper(filter);
      if (res.status === 200) {
        setAllEndpapers(res.data?.data);
      }
    } catch (err) {
      
    }
  };

  useEffect(() => {
    getEndpapersData();
  }, []);

  useEffect(() => {
    const nonDisabledEndpapers = allEndpapers?.filter(
      (paper) => paper.disabled === false
    );
    setAllowedEndpapers(nonDisabledEndpapers);
  }, [allEndpapers]);

  const handleEndpaper = (endpaper) => {
    setSelectedEndpaper(endpaper);
    setBookEndpaper(endpaper,canvas)
  }

  const handleWantEndPaper = (option) => {
    setWantEndpaper(option)
      if(option === endpaperTabs[1].value){
        setSelectedEndpaper(null)
      }
  }


  useEffect(() => {
    if(wantEndpaper === endpaperTabs[1].value){
      const layer = canvas?.getObjects()?.find((o) => o?.id === "background");
      if(layer){
        layer._objects = layer._objects?.filter((o) => o.id !== "endpaper");
        // const imageObject = layer._objects?.find((o) => o.id === "endpaper");
        // if(imageObject){
        //   imageObject.visible = false
        //   imageObject.dirty = true
        // }
        layer.dirty = true;
        canvas.renderAll();
      }
    }
     else if(wantEndpaper === endpaperTabs[0].value ){
      if(selectedDesigns?.customization?.endpaper?.hadEndpaper) {
        const selectedEndpaperDesign = allowedEndpapers?.find(endpaper => endpaper?.id === selectedDesigns?.customization?.endpaper?.endpaper);
        if(selectedEndpaperDesign) {
          setSelectedEndpaper(selectedEndpaperDesign);
          setBookEndpaper(selectedEndpaperDesign,canvas)
        } else {
          setSelectedEndpaper(allowedEndpapers?.[0]);
          setBookEndpaper(allowedEndpapers?.[0],canvas)
        }
      }
      else {
        setSelectedEndpaper(allowedEndpapers?.[0]);
        setBookEndpaper(allowedEndpapers?.[0],canvas)
      }
     }
  },[wantEndpaper,canvas,allowedEndpapers])


  useEffect(() => {
    if(selectedDesigns?.customization?.endpaper?.hadEndpaper) {
      setWantEndpaper(endpaperTabs?.[0]?.value)
    }
  },[selectedDesigns])


  return (
    <div>
      {isMediumScreen ? (
            <div
            className={classes.selectColor}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Do you want endpaper?</h2>

            <div className={classes.endpaperOptions}>
              {endpaperTabs.map((tab) => (
                <OptionCapsules
                  option={tab.name}
                  active={wantEndpaper === tab.value}
                  onClick={() => handleWantEndPaper(tab.value)}
                  key={tab?.value}
                />
              ))}
            </div>

            {wantEndpaper ? (
              <div>
                <div className={classes.previewContainer}>
                  <Slider {...settings}>
                    {allowedEndpapers?.map((endpaper) => (
                        <PreviewCard key={endpaper?.designName} handleClick={() => handleEndpaper(endpaper)} image={endpaper?.designImage} isActive={selectedEndpaper?.designName === endpaper?.designName} />
                    ))}
                  </Slider>
                </div>
              </div>
            ) : <div className={classes.noPaper}>
                  <p>No Endpaper Added</p>
              </div>}
          </div>
      ) : (
        <div className={classes.mainContainer}>
          <Accordion
            dropdownName={"Endpaper"}
            onClick={onClick}
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            toolText={"Select the endpaper design"}
            currentDropdown={currentDropdown}
            title={"Endpaper"}
          />

          {dropdownOpen && currentDropdown === "Endpaper" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Do you want endpaper?</h2>

              <div className={classes.endpaperOptions}>
                {endpaperTabs.map((tab) => (
                  <OptionCapsules
                    option={tab.name}
                    active={wantEndpaper === tab.value}
                    onClick={() => handleWantEndPaper(tab.value)}
                    key={tab?.value}
                  />
                ))}
              </div>

              {wantEndpaper ? (
                <div>
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                      {allowedEndpapers?.map((endpaper) => (
                        // <div
                        //   key={endpaper?.designName}
                        //   className={`${classes.previewCard} ${
                        //     selectedEndpaper?.designName ===
                        //     endpaper?.designName
                        //       ? classes.activePreviewCard
                        //       : ""
                        //   }`}
                        //   onClick={() => handleEndpaper(endpaper)}
                        // >
                        //   <img src={endpaper?.designImage} alt="" />
                        // </div>
                        <PreviewCard key={endpaper?.designName} handleClick={() => handleEndpaper(endpaper)} image={endpaper?.designImage} isActive={selectedEndpaper?.designName === endpaper?.designName} />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : <div className={classes.noPaper}>
                    <p>No Endpaper Added</p>
                </div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectEndpaper;
