import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import classes from "./WishlistCard.module.css";

const cancelIcon = "/Assets/Icons/icon-x.svg";

const WishListCard = ({ 
  image, 
  bookName, 
  author, 
  price, 
  onClick, 
  onRemove, 
  altText, 
  isUnavailable 
}) => {
  const addToCartAndRemove = () => {
    onClick();
  };

  return (
    <div className={`${classes.main} ${isUnavailable ? classes.unavailable : ""}`}>
      {isUnavailable && <div className={classes.unavailableBanner}>Unavailable</div>}
      <div className={classes.image}>
        <img src={image} alt="" />
        <div className={classes.icon} onClick={onRemove}>
          <img src={cancelIcon} alt={altText} />
        </div>
      </div>
      <div className={classes.title}>{bookName}</div>
      <div className={classes.author}>{author?.slice(0, 2)}</div>
      <div className={classes.cart}>
        <div>${price}</div>
        <div>
          <PrimaryButton
            className={classes.button}
            onClick={addToCartAndRemove}
            disabled={isUnavailable} // Disable button when unavailable
          >
            Add to cart
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default WishListCard;
