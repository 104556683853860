import classes from "./EditText.module.css";
import { useState, useEffect, useContext } from "react";
import {  useFabricCanvas } from "react-fabric-page";
import {  addTextboxObject } from "../../../../objectUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import EditTextSmall from "./EditTextSmall/EditTextSmall";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";

const dropdownIcon = "/Assets/Icons/caret-down-solid.svg"


const EditText = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown }) => {
  const {frontCoverTextColor,setFrontCoverTextColor,frontCoverFontFamily,setFrontCoverFontFamily,fontData,frontCoverFontStyle,setFrontCoverFontStyle,allTextColors} = useContext(CustomizationContext)
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [colors,setColors] = useState([])

  const {isMediumScreen} = useScreenSize()

  const canvas = useFabricCanvas();

  // const getColor = async () => {
  //   let filter = {
  //     // offset: (currPageNo - 1) * pageSize,
  //     // limit: pageSize,
  //   };

  //   try {
  //     const res = await getFoilingColor(filter);
  //     if (res.status === 200) {
  //       setallFoilingColors(res.data?.data);
  //     }
  //   } catch (err) {
  //     
  //   }
  // };

  // useEffect(() => {
  //   getColor();
  // }, []);

  useEffect(() => {
    setColors(allTextColors);
  }, [allTextColors]);


  const handleColorChange = (color) => {
    setFrontCoverTextColor(color);
  };

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false)
    }
    else {
      toggleDropdown(true)
    }
  };

  const handleFontChange = (font) => {
    setFrontCoverFontFamily(font);
    setIsDropdownOpen(false);
  };

  const handleAddTextBox = () => {
    addTextboxObject(canvas);
  };

  // const handleTextStyleChange = (style) => {
  //   const obj = activeObject?._activeObject;
  //   if (!obj) return;
  
  //   switch (style) {
  //     case 'italic':
  //       obj.set('fontStyle', obj.fontStyle === 'italic' ? 'normal' : 'italic');
  //       break;
  //     case 'underline':
  //       obj.set('underline', !obj.underline);
  //       break;
  //     default:
  //       break;
  //   }
  
  //   obj.canvas.renderAll();
  // };

  const handleTextStyleChange = (style) => {
    setFrontCoverFontStyle((prevStyle) => {
      const newStyle = { ...prevStyle };
  
      switch (style) {
        case 'italic':
          newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
          break;
        case 'underline':
          newStyle.underline = !newStyle.underline;
          break;
        default:
          break;
      }
  
      return newStyle;
    });
  };
  


  // useEffect(() => {
  //   const changeCoverColor = (colorCode) => {
  //     const obj = activeObject;
  //     const selectedTextStyle = obj?._activeObject;
  //     if (selectedTextStyle) {
  //       selectedTextStyle.set("fontFamily", selectedFont.fontFamily);
  //       selectedTextStyle.set("fill", colorCode);
  //       obj.dirty = true;
  //       canvas.renderAll();
  //     }
  //   };

  //   changeCoverColor(frontCoverTextColor?.colorValue);
  // }, [frontCoverTextColor, selectedFont, activeObject, canvas]);

  // useEffect(() => {
  //   const changeCoverColor = (color) => {
  //     if (!canvas) return;
  
  //     const allObjects = canvas?.getObjects()?.find(obj => obj.id === 'contents');
  //     allObjects?._objects?.forEach((obj) => {
  //       if (obj.type === 'textArea') {
  //         obj.set("fontFamily", frontCoverFontFamily?.fontFamily);
  //         obj.set("fill", color);
  //         obj.dirty = true;
  //       }
  //     });
      
  //     canvas.renderAll();
  //   };
  
  //   changeCoverColor(frontCoverTextColor?.colorValue);
  // }, [frontCoverTextColor, canvas]);
  

  return (
    <div>
      {isMediumScreen ? (
        <EditTextSmall colors={colors} fontData={fontData}/>
      ) : (
        <div className={classes.ColorOption} onClick={() => handleClickAndSelect()}>
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
            <h2>Text Boxes</h2>
            <ToolTip tooltipText={"Click and select the text you want to edit"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Text Boxes" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Text Boxes" ? (
            <div className={classes.colorPickerDropdown} onClick={(e) => e.stopPropagation()}>
              <h3>Select font for front cover</h3>

              <div className={classes.selectColor}>
                <h2>Font family: {frontCoverFontFamily?.fontFamily}</h2>
                <div className={classes.customDropdown}>
                  <div
                    className={classes.selectedOption}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div className={classes.dropdownBar}>
                      <div className={classes.dropdownText}>
                        <span>{frontCoverFontFamily?.fontFamily}</span>
                        <span>{frontCoverFontFamily?.fontPrice > 0 && `+$${frontCoverFontFamily?.fontPrice}`}</span>
                      </div>
                      <div className={classes.dropdownIcon}>
                        <img src={dropdownIcon} alt="" />
                      </div>
                    </div>
                  </div>
                  {isDropdownOpen && (
                    <div className={classes.dropdownOptions}>
                      {fontData.map((font) => (
                        <div
                          key={font.fontFamily}
                          className={classes.option}
                          onClick={() => handleFontChange(font)}
                        >
                          <span>{font?.fontFamily}</span>
                          <span>{font?.fontPrice > 0 && `+$${font?.fontPrice}`}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

                <div className={classes.textStylingOptions}>
                  <h3>Select Text Styling</h3>
                  <div className={classes.stylingButtons}>
                    <button className={`${classes.stylingButton} ${frontCoverFontStyle?.fontStyle === "italic" ? classes.activeStylingButton : ""}`} onClick={() => handleTextStyleChange('italic')}>
                      <i>I</i>
                    </button>
                    <button className={`${classes.stylingButton} ${frontCoverFontStyle?.underline === true ? classes.activeStylingButton : ""}`} onClick={() => handleTextStyleChange('underline')}>
                      <u>U</u>
                    </button>
                    {/* <button className={classes.stylingButton} onClick={() => handleFontWeightChange('normal')}>
                      Ag
                    </button>
                    <button className={classes.stylingButton} onClick={() => handleFontWeightChange('bold')}>
                      AG
                    </button> */}
                  </div>
                </div>



              <div className={classes.selectColor}>
                <h2>Color: {frontCoverTextColor?.colorName}</h2>
                <ColorSelector availableColors={colors} selectedColor={frontCoverTextColor} handleColorChange={handleColorChange} />
              </div>

              <div className={classes.addTextBox} onClick={handleAddTextBox}>
                <div className={classes.addText} >
                  <h3>Add Textbox</h3>
                </div>
                <p>+$40</p>
              </div>
            </div>
          ) : (
            <p>{frontCoverTextColor?.colorName}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EditText;
