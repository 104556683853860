import classes from "./PaginatedBlogs.module.css";
import { useState, useEffect } from "react";
import {
  getBlogsCount,
  fetchAllPaginatedBlogs,
} from "../../../Store/actions/BlogsAction";
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import BlogCard from "../../Body/BlogSection/BlogCard/BlogCard";

const PaginatedBlogs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const blogData = useSelector((state) => state.blogs?.paginatedBlogs);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;
  const [totalBlogsCount, setTotalBlogsCount] = useState(0); // To store the total number of blogs

  useEffect(() => {
    // Fetch the total blog count for pagination
    getBlogsCount("").then((count) => setTotalBlogsCount(count));

    // Fetch blogs for the current page
    fetchAllPaginatedBlogs(currentPage, blogsPerPage, dispatch);
  }, [currentPage, blogsPerPage, dispatch]);

  const totalPages = Math.ceil((totalBlogsCount-4) / blogsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      // Show all pages if totalPages is less than or equal to 5
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Show first page, current page, last page and ellipsis
      pageNumbers.push(1);

      if (currentPage > 3) {
        pageNumbers.push("...");
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push("...");
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number, index) => (
      <p
        key={index}
        className={number === currentPage ? classes.activePage : classes.page}
        onClick={() => typeof number === "number" && handlePageClick(number)}
      >
        {number}
      </p>
    ));
  };

  const handleBlogRedirect = (slug) => {
    history.push(`${urls.VIEWBLOG}/${slug}`);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className={classes.otherBlogs}>
      <div className={classes.headerSection}>
        <h2>You may also like these blogs</h2>

        <div className={classes.paginationControl}>
          <p onClick={handlePrevPage} className={currentPage === 1 ? classes.disabled : ""}>Prev</p>
          {/* <p>{currentPage}</p> */}
          {renderPagination()}
          <p onClick={handleNextPage} className={currentPage === totalPages ? classes.disabled : ""}>Next</p>
        </div>
      </div>

      <div className={classes.blogs}>
        {blogData?.map((data, index) => (
          <BlogCard
            key={index}
            onClick={() => handleBlogRedirect(data?.urlSlug)}
            countryCode={data?.countryCode}
            className={classes.card}
            index={index}
            image={data?.bannerImagePath}
            title={data?.title}
            authorName={data?.authorName}
            date={formatDate(data?.updateTime)}
            category={data?.category}
          />
        ))}
      </div>
    </div>
  );
};

export default PaginatedBlogs;
