import React, { useEffect, useState } from 'react';
import classes from './SearchBarSmall.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter } from '@fortawesome/free-solid-svg-icons';
import { fetchBook,fetchBookCount, getSuggestionsForNormalBooks } from '../../../../Store/actions/BookAction';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchBarSmall = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [query,setQuery] = useState("")
  const [filter,setFilter] = useState("all")


  const history = useHistory()
  const toggleDropdown = () => {
    setIsDropdownVisible(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
};

  const dispatch = useDispatch();
  const handleSearch = async () => {
    if (query === "") return;

    const searchResults = await fetchBook(filter, query, dispatch);
    const searchCount = await fetchBookCount(filter,query);

    // Redirect to LISTING page with search results as state
    history.push({
      pathname: urls.LISTING,
      state: { 
          searchResults, 
          searchCount,
          filter,
          query
      }
  });
  };

  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
        handleSearch()
    }
  }   

  const allRecommendations = useSelector((state) => state.recommendation.recommendations);

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setQuery(words.join(" "));
    } else {
      setQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };
  
  useEffect(() => {
    if(recommendationClicked) {
      handleSearch();
      setRecommendationClicked(false);
    }
  },[recommendationClicked])
  
  useEffect(() => {
    if (query) {
      const pattern = /\s\S+$/;
      const match = query.match(pattern);
      if (match) {
        const modifiedQuery = query.replace(pattern, "").trim(); // "the a" -> "the"
        getSuggestionsForNormalBooks(dispatch, modifiedQuery, true);
      }
    }
  }, [query]);

  useEffect(() => {
      if (query) {
        const words = query.trim().split(/\s+/);
        const searchWord = words.length === 1 ? query.toLowerCase() : words[words.length - 1].toLowerCase();
        setSearchRecommedations(
          allRecommendations.filter(word => word.toLowerCase().startsWith(searchWord))
        );
      } else {
        setSearchRecommedations([]);
      }
  }, [query, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };
  
  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false),1000);
    setBlurTimeout(timeout);
  };

  return (
    <div className="container">
      <div className={classes.mainContainer}>
        <div className={classes.searchIcon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch}/>
          <input 
            type="text" 
            placeholder="Search Books" 
            value={query} 
            onChange={handleInputChange} 
            onKeyDown={(e) => handleKeyPress(e)} 
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
        <div className={classes.filter} onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faFilter} />
          {isDropdownVisible && (
            <div className={classes.dropdownli}>
              <ul>
                  <li value="all" onClick={() => setFilter("all")}>All</li>
                  <li value="author" onClick={() => setFilter("authors")}>Author</li>
                  <li value="item_name" onClick={() => setFilter("item_name")}>Book Title</li>
              </ul>
            </div>
          )}
        </div>

      </div>
      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
        {searchRecommendations.slice(0,10).map((recommendation, index) => (
          <div
            key={index}
            className={classes.dropdownItem}
            onClick={() => handleRecommendationClick(recommendation)}
          >
            <FontAwesomeIcon icon={faSearchengin}/>
            {recommendation}
          </div>
        ))}
        </div>
      )}
    </div>
  );
};

export default SearchBarSmall;
