import { useDispatch, useSelector } from "react-redux"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import CustomerReview from "../../Body/CustomerReview/CutomerReview"
import Gallery from "../../Body/Gallery/Gallery"
import Footer from "../../Footer/Footer"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import { ContactUs } from "../Components/ContactUs/ContactUs"
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo"
import FAQ from "../FAQ/FAQ"
import classes from "./PrintOnDemand.module.css"
import { useEffect } from "react"
import { getAllFaq } from "../../../Store/actions/FaqAction"
import Timeline from "../Components/Timeline/Timeline"
import AboutPOD from "./AboutPOD/AboutPOD"
import { urls } from "../../../Routes/Urls"
import useScrollToTop from "../../../Hooks/useScrollToTop";

const image = "/Assets/Images/CustomizationOverview.svg"
const steps = [
    {
      number: '01',
      title: 'Upload Your Manuscript',
      description:
        'To upload your manuscript, you fill out the form below. To meet your demands, select from a range of formats, sizes, and binding choices.',
      image: image, // replace with image path if needed
      align: 'left',
    },
    {
      number: '02',
      title: 'Design Your Book',
      description:
        "Use our design tools to personalize your book. Choose your favorite layout, upload your cover art, and watch a real-time preview of your book.",
      image: image, // replace with image path if needed
      align: 'right',
    },
    {
      number: '03',
      title: 'Submit Your Order',
      description:
        " Use our safe method to submit your order as soon as you're happy with your design. The rest is up to our excelled craftsmen, including packing and printing.",
      image: image, // replace with image path if needed
      align: 'left',
    },
    {
      number: '04',
      title: 'Receive Your Book',
      description:
        "Take advantage of quick shipping and premium books delivered right to your home. We go above and beyond to make sure each book lives up to your expectations.",
      image: image, // replace with image path if needed
      align: 'right',
    },
  ];

const PrintOnDemand = () => {
    const dispatch = useDispatch()
    const crumbs = [
        { title: "Home", path: ("/") },
        { title: "Print On Demand", path: urls.PRINTONDEMAND },
    ]

    const video = useSelector((state) => state.serviceVideo?.videos?.printOnDemandVideo)
    const allFaq = useSelector(state => state.Faq.questions);

    useEffect(() => {
        getAllFaq("category","Print on Demand","",dispatch)
    },[])

    const scrollRef = useScrollToTop();

    return (
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader crumbs={crumbs} title={"Rare Biblio is your one-stop print-on-demand solution."} desc={"Partner with Us to Expand Your Reach and Grow Your Business"} />

            <ServiceVideo
                title={"We Bring Ideas To Life"}
                videoSrc={video}
                desc={"We at Rare Biblio provide state-of-the-art print-on-demand services that enable you to produce outstanding printed goods from your digital designs. Our print-on-demand platform offers a smooth and effective solution to fulfill your demands, whether you're a creative professional, a business, or just trying to make your ideas a reality."}
            />

            <AboutPOD />
            <div className={classes.join}>
                <h2>
                How It Works
                </h2>
                {/* <p>
                    Find out how Rare Biblo's superior bookbinding services might improve your publishing offers. Come celebrate the craft of bookmaking with us as we provide your writers and authors with premium quality leather-bound handcrafted customized books that are exceptional in terms of elegance and quality.
                    Would you like a partnership with us? To get in touch with us and find out more about how we may collaborate, click the link below.
                </p> */}
            </div>

            
            <div className={classes.ThesisBindingSteps}>
                <h2 className={classes.title}>
                </h2>
                {/* <p className={classes.subtitle}> the premier destination for bibliophiles and book lovers who appreciate the art of bookmaking. As a rare books store.
                    we specialize in following things:</p> */}
                <Timeline steps={steps} />
            </div>

            <Gallery />

            <CustomerReview />

            <FAQ data={allFaq} />
            <div className={classes.contactUs}>
                <ContactUs title={"Let’s Get Started"}
                  desc={"With Rare Biblio, turn your text into a gorgeously printed book. Start your publishing adventure now by looking through our print-on-demand alternatives! Our committed team of experts is available to assist you if you need it or if you have any queries.Are you prepared to release your book? To begin creating, fill out the form below, or get in touch with us for further details."}
                 />   
            </div>

            <Footer />
        </div>
    )
}

export default PrintOnDemand