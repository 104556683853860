import React from "react";
import classes from "./CustomerCard.module.css";

function CustomerCard({ image, desc, name }) {
    return (
        <div className={classes.customerCardContainer}>
            <div className={classes.authorImage}>
                <img src={image} alt="Customer" className={classes.roundImage} />
            </div>
            <div className={classes.cardDescription}>
                <div className={classes.text}>
                    <p>{desc}</p>
                </div>

            </div>
            <div className={classes.author}>
                    <p>{name}</p>
            </div>
        </div>
    );
}

export default CustomerCard;
