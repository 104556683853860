import './App.css';
import WarningDialogue from "./Components/WarningDialogue/WarningDialogue";
import Loader from "./Components/Loader/Loader";
import MyRouter from './Routes/MyRouter';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchUserDetails } from './Store/actions/UsersActions';
import { fetchFeaturedCollection } from './Store/actions/FeaturedCollectionActions';
import { fetchGalleryByType } from './Store/actions/GalleryActions';
import { fetchAllBlogs, getBlogCategories } from './Store/actions/BlogsAction';
import { fetchAllBookColors } from './Store/actions/BookColorAction';
import { fetchAllVideos } from './Store/actions/ServiceVideoAction';
import { fetchAllCartItems } from './Store/actions/CartAction';
import { isUserLoggedIn } from './Util';
import { getSuggestionsForNormalBooks } from './Store/actions/BookAction';

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAllBookColors(dispatch);
    dispatch(fetchUserDetails())
    fetchAllBlogs(dispatch);
    getBlogCategories(dispatch);
    fetchAllVideos(dispatch)
    if(isUserLoggedIn()){
      fetchAllCartItems(dispatch);
    }
    fetchGalleryByType(dispatch);
    fetchFeaturedCollection(dispatch);
    getSuggestionsForNormalBooks(dispatch);
  },[dispatch])

  return (
    <div className='App'>
      <WarningDialogue />
      <MyRouter />
      <Loader />
    </div>
  );
}

export default App;
