import classes from "./ContactUs.module.css"
import { useState } from "react"
import Input from "../../../../Components/Inputs/Input/Input"
import PrimaryTextArea from "../../../../Components/Inputs/PrimaryTextArea/PrimaryTextArea"
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton"
import { ContactUsAction } from "../../../../Store/actions/ContactUsAction"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedinIn, faPinterest, faXTwitter } from "@fortawesome/free-brands-svg-icons"

const locatonIcon = "/Assets/Icons/locationDot.svg"
const emailIcon = "/Assets/Icons/email-icon.svg"

const email = "info@rarebiblio.com"
const addresses = [
    "Isle Royal St, Fremont, California, United States",
    "Redpath Avenue, Toronto, Ontario, Canada",
]

export const ContactUs = ({title,desc}) => {
    const [fullName,setFullName] = useState("")
    const [userEmail,setUserEmail] = useState("")
    const [countryCode,setCountryCode] = useState("")
    const [contactNumber,setContactNumer] = useState("")
    const [message,setMessage] = useState("")

    const clearForm = () => {
        setContactNumer("")
        setCountryCode("")
        setFullName("")
        setMessage("")
        setUserEmail("")
    }

    const handleFormSubmission = (e) => {
        e.preventDefault()
        const data = {
            "name":fullName,
            "phoneNo":countryCode+contactNumber,
            "email":userEmail,
            "message":message
        }
        
        ContactUsAction(data).then((response) => {
            if(response.data.success === true){
                clearForm();
            }

        })
    }

    const handleIconClick = (url) => {
        window.open(url, "_blank", "noopener,noreferrer"); 
      };

    return (
        <>
        <div className={classes.container}>
            <div className={classes.details}>
                <h2>{title || "Have Customization Request ?"}</h2>
                <p>{desc || "Please don't hesitate to reach out to us at your convenience. We'll respond promptly!"}</p>
                <div className={classes.addresses}>
                <div>
                    <img src={locatonIcon} alt="" />
                </div>
                <div>
                    <h3>Address</h3>
                    {addresses.map((address,key) => (
                        <p key={key} >{address}</p>
                    ))}
                </div>
                </div>
                <div className={classes.email}>
                    <div>
                        <img src={emailIcon} alt="" />
                    </div>
                    <div>
                        <h3>Email</h3>
                        <p>{email}</p>
                    </div>
                </div>

                
                <div className={classes.socialMediaBtns}>
                    <p>Social Media</p>
                    <div className={classes.socialBtns}>
                        <div className={classes.socialMediaBtn}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.facebook.com/Rarebiblio")} className={classes.footerIcons} icon={faFacebook} />
                        </div>
                        <div className={classes.socialMediaBtn}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.instagram.com/Rarebiblio")} className={classes.footerIcons} icon={faInstagram} />
                        </div>
                        <div className={classes.socialMediaBtn}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.twitter.com/Rarebiblio")} className={classes.footerIcons} icon={faXTwitter} />
                        </div>
                        <div className={classes.socialMediaBtn}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.linkedin.com/company/Rarebiblio")} className={classes.footerIcons} icon={faLinkedinIn} />
                        </div>
                        <div className={classes.socialMediaBtn}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.pinterest.com/rare_biblio")} className={classes.footerIcons} icon={faPinterest} />
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={classes.formSection}>
            <form>
          <div className={classes.formGroup}>
            <div className={classes.fullName}>
                <label htmlFor="fullName">Full Name</label>
            <Input
                type='text'
                id='userId'
                placeholder="Full Name"
                onChange={(e) => { setFullName(e.target.value) }}
                value={fullName}
              />
            </div>
          </div>
          <div className={classes.formGroup}>
          <label htmlFor="userEmail">Email Id</label>
          <Input
              type='email'
              id='userId'
              placeholder="Email"
              onChange={(e) => { setUserEmail(e.target.value) }}
              value={userEmail}
            />
          </div>
          <div className={classes.formGroup}>
            <div className={classes.contactNumber}>
              <select name="countryCode" id="countryCode" className={classes.selectInput} required onChange={(e) => setCountryCode(e.target.value)}>
                <option value="Country Code" defaultChecked = {true}>Country Code</option>
                <option value="+1">+1</option>
                <option value="+91">+91</option>
                <option value="+44">+44</option>
              </select>
              <Input
                type='number'
                id='userId'
                placeholder="Contact Number"
                onChange={(e) => { setContactNumer(e.target.value) }}
                value={contactNumber}
              />
            </div>
          </div>

          <div className={classes.formGroup}>
          <label htmlFor="message">Message</label>
          <PrimaryTextArea
              placeholder="Enter Your Message"
              onChange={(e) => { setMessage(e.target.value) }}
              value={message}
            />
          </div>

          <SecondaryButton className={classes.submitBtn} onClick={(e) => handleFormSubmission(e)} children={"Submit Now"} />
        </form>
                
            </div>
        </div>
        </>
    )
}

