import { format } from "date-fns";


export function getClasses(...classes) {
  return classes.filter(i => i).join(" ");
}

export function getCurrentUserRole() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.role || "-";
}

export function hasPermission(permissions, checkFor) {
  return permissions.includes(checkFor);
}

export function validateEmail(email) {
  let obj = {
    valid: true,
    message: ""
  };

  let validEmail = /^[a-zA-Z0-9_.]+@[a-zA-Z0-9]+(.[a-zA-Z0-9]{2,})+$/;

  if (!email?.match(validEmail)) {
    obj.valid = false;
    obj.message = "Email is not valid.";
    return obj;
  }
  return obj;
}

export function validateMobileNumber(mobileNumber) {
  if (!mobileNumber) {
    return "Please enter a mobile number.";
  }

  // Regular expression to validate mobile numbers
  let validMobileNumber = /^[0-9]{10}$/;

  if (!mobileNumber.match(validMobileNumber)) {
    return "Mobile number is not valid. It should be 10 digits long and contain only numbers.";
  }

  return false; // Return false if validation passes
}

export function getFormattedDate(dateStr, includeTime) {
  if (!dateStr) {
    return "-";
  }

  const inputDate = new Date(dateStr);
  if (isNaN(inputDate.getTime())) {
    return "-";
  }

  const dateFormat = `dd-MM-yyyy${includeTime ? " hh:mm aa" : ""}`;
  return format(inputDate, dateFormat);
}

export function getFormattedTime(dateStr) {
  if (!dateStr) {
    return "-";
  }

  const inputDate = new Date(dateStr);
  if (isNaN(inputDate.getTime())) {
    return "-";
  }

  return format(new Date(inputDate.getTime()), "hh:mm aa");
}

export function getFormattedTimeLowerCase(timestamp) {

  if(isNaN(timestamp)){
    return
  }

  return format(new Date(timestamp), 'hh:mm aaa');
}

export function getFormattedDateWithFullMonth(date) {
  if (!date) {
    return "-";
  }

  const inputDate = new Date(date);
  if (isNaN(inputDate.getTime())) {
    return "-";
  }

  return format(new Date(inputDate.getTime()), "dd MMMM yyyy");
}

export function validatePassword(password) {
  const specialCharacters = /[@$!&#%*]/;
  const capitalLetter = /[A-Z]/;
  const numeric = /[0-9]/;
  if (!password) {
    return "Please enter a password.";
  }

  if (password.length < 8) {
    return "Password length must be greater than 8 characters.";
  }

  if (!specialCharacters.test(password)) {
    return "Password must contain at least one special character (@, $, !, &, #, %, *).";
  }

  if (!capitalLetter.test(password)) {
    return "Password must contain one capital letter.";
  }

  if (!numeric.test(password)) {
    return "Password must contain one number.";
  }

  return false;
}

export function getGreeting() {
  const now = new Date();
  const currentHour = now.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}

export function downloadDoc(file, type, fileName) {
  if (type && type === "text/html" && fileName.indexOf(".") !== -1) {
    fileName = fileName.substr(0, fileName.lastIndexOf(".")) + ".html";
  }
  const blob = new Blob([file], { type: type || "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "";
  link.click();
  link.remove();
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function highLightQuery(text, query) {
  if (typeof text === "string") {
    if (query && typeof query === "string") {
      const containsQuery = text
        .toLocaleLowerCase()
        ?.includes(query.toLocaleLowerCase());
      if (containsQuery) {
        return (
          <span style={{ background: "var(--app-primary-light)" }}>{text}</span>
        );
      } else return text;
    } else {
      return text;
    }
  }
}

export function dataURItoBlob(dataURI) {
  var byteString = window.atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function isUserLoggedIn() {
  const token = localStorage.getItem("isLoggedIn");
  if (!token) {
    return false;
  }
  return true;
}


export const copyTextToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((resolve, reject) => {
      document.execCommand("copy") ? resolve() : reject();
      textArea.remove();
    });
  }
};



export const getRandomImageForBook = (bookData, allColorsData, specificColor = null, specificLeather = null, specificVariant = null) => {
  const bookImageString = bookData?.images;

  // Parse bookData images if they exist and are not empty or null
  if (bookImageString && bookImageString.trim() !== "") {
    const bookImageJson = JSON.parse(bookImageString);
    let validKeys = Object.keys(bookImageJson);

    // Case when specificColor, specificLeather, and specificVariant are provided
    if (specificColor && specificLeather && specificVariant) {
      const key = `${specificColor}_${specificVariant}_${specificLeather}`;

      if (bookImageJson[key] && bookImageJson[key]?.links?.some(link => link)) {
        // Return the first available non-null/empty link
        const validLink = bookImageJson[key]?.links.find(link => link);
        const altText = bookImageJson[key]?.altText || "Product Image";
        
        if (validLink) {
          return {
            image: validLink,
            altText: altText,
            variantType: specificVariant,
          };
        }
      }
    }

    // Case when only specificColor is provided and we need to find any available variant/leatherType
    if (specificColor) {
      // Filter valid keys by the specific color
      validKeys = validKeys.filter(key => key.startsWith(specificColor));
      
      if (validKeys.length > 0) {
        // Pick a random key from the valid set of keys that match the color
        const randomKey = validKeys[Math.floor(Math.random() * validKeys.length)];
        const links = bookImageJson[randomKey]?.links?.filter(link => link); // Ensure no empty links
        const altText = bookImageJson[randomKey]?.altText || "Product Image";
        const variantType = randomKey.split("_")[1]; // Extract variant from key

        if (links && links.length > 0) {
          return {
            image: links[0], // First valid link
            altText: altText,
            variantType: variantType,
          };
        }
      }
    }

    // Case when no specificColor is provided, but we still check bookData images
    if (validKeys.length > 0) {
      const randomKey = validKeys[Math.floor(Math.random() * validKeys.length)];
      const links = bookImageJson[randomKey]?.links?.filter(link => link); // Filter non-empty links
      const altText = bookImageJson[randomKey]?.altText || "Product Image";
      const variantType = randomKey.split("_")[1]; // Extract variant from key

      if (links && links.length > 0) {
        return {
          image: links[0],
          altText: altText,
          variantType: variantType,
        };
      }
    }
  }

  // If bookData images are empty or invalid, fallback to allColorsData
  const fallbackImages = allColorsData?.filter((color) => {
    // Only consider colors that are not disabled
    return color.disabled === false && 
           (!specificColor || color.colorName === specificColor) &&
           (!specificLeather || color.leatherType === specificLeather) &&
           (!specificVariant || color.variantType === specificVariant);
  });

  if (fallbackImages && fallbackImages.length > 0) {
    const randomIndex = Math.floor(Math.random() * fallbackImages.length);
    return {
      image: fallbackImages[randomIndex].baseImg,
      altText: fallbackImages[randomIndex].altText || "Product Image",
      variantType: fallbackImages[randomIndex].variantType,
    };
  }

  // If nothing is found, return null safely
  return null;
};

// For normal books with optional specificColor, specificLeather, and specificVariant
export const getRandomImageForNormalBook = (bookData, allColorsData, specificColor = null, specificLeather = null, specificVariant = null) => {
  const filteredColors = allColorsData?.filter(color => color.isBestSeller === false && color.disabled === false);

  return getRandomImageForBook(
    bookData,
    filteredColors,
    specificColor, 
    specificLeather,
    specificVariant
  );
};

// For best-seller books with optional specificColor, specificLeather, and specificVariant
export const getRandomImageForBestSellerBook = (bookData, allColorsData, specificColor = null, specificLeather = null, specificVariant = null) => {
  const filteredColors = allColorsData?.filter(color => color.isBestSeller === true && color.disabled === false);

  return getRandomImageForBook(
    bookData,
    filteredColors,
    specificColor, 
    specificLeather, // Default to "realLeather" if not provided
    null // Don't filter by specificVariant for best sellers
  );
};

export const getBookPrice = (bookData,leatherType,variantType) => {
  if (
    leatherType === "realLeather" &&
    variantType === "deluxe"
  ) {
    return bookData?.deluxePriceGenuine?.toFixed(2);
  } else if (
    leatherType === "realLeather" &&
    variantType === "superDeluxe"
  ) {
    return bookData?.superDeluxePriceGenuine?.toFixed(2);
  } else if (
    leatherType === "veganLeather" &&
    variantType === "deluxe"
  ) {
    return bookData?.deluxePriceVegan?.toFixed(2);
  }
  return bookData?.superDeluxePriceVegan?.toFixed(2);
};


export const getEnum = (name) => {
    switch (name) {
        case "deluxe":
           return "Deluxe";
        case "superDeluxe":
          return "Super Deluxe";
        case "realLeather":
          return "Real Leather";
        case "veganLeather":
          return "Vegan Leather";
        default: 
          return "";
    }
} 