import axios from "../../Axios";
import { SERVICE_URL } from "../ServiceUrls";


export const CREATE_ORDER = SERVICE_URL.ORDERS + "/create"

export function fetchOrdersData(type = "") {
  return axios({
    url: `${SERVICE_URL.ORDERS}/my-orders`,
    method: "get",
  })
    .then((response) => {
      if (response.status === 200) {
        // dispatch(ordersActions.fetchOrders(response.data));
      }
      return response
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function createOrder(paymentGateway) {
    return axios({
      url: CREATE_ORDER,
      method: "post",
      params: {
        paymentGateway: paymentGateway
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
