import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./CartMini.module.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { updateCartItem, deleteCartItem } from "../../../../Store/actions/CartAction";
import Modal from "../../../../Components/Modal/Modal";
import AddressForm from "../../../MyAccount/ManageAddress/AddressForm/AddressForm";
import OptionButton from "../../../MyCart/Components/OptionButton/OptionButton";
import { getEnum, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../../Util";

const CartMini = ({setSelectedShippingSpeeds,selectedShippingSpeeds, }) => {
    const allColorsData = useSelector(state => state.bookColor.colors);
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems)?.filter((item) => item?.book?.isDeleted === false || item?.bestSellerBook?.isDeleted === false);
    const currentUser = useSelector((state) => state.user.currentUser);
   
    const [isEdit, setIsEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleQtyIncrement = (cartItem) => {
        updateCartItem(cartItem.id, cartItem.quantity + 1,cartItem.shippingAddress, dispatch, cartItem.boxWrapping, cartItem.giftWrapping);
    };

    const handleQtyDecrement = (cartItem) => {
        if (cartItem.quantity > 1) {
            updateCartItem(cartItem.id, cartItem.quantity - 1,cartItem.shippingAddress, dispatch, cartItem.boxWrapping, cartItem.giftWrapping);
        }
    };

    const handleDeleteItem = (cartItemId) => {
        deleteCartItem(cartItemId, dispatch);
    };

    const handleAddressChange = (cartItem, event) => {
        const selectedAddressId = event.target.value;
        updateCartItem(cartItem.id,cartItem.quantity,selectedAddressId,dispatch, cartItem.boxWrapping,cartItem.giftWrapping)
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setIsEdit(false);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    // Handle shipping speed change
    const handleShippingSpeedChange = (cartItem,shippingSpeed) => {
        if(!cartItem?.shippingAddress){
            return;
        }
        updateCartItem(cartItem.id,cartItem.quantity,cartItem.shippingAddress,dispatch,cartItem.boxWrapping,cartItem.giftWrapping,shippingSpeed)
    };

    const getBookImage = (cartItem) => {

        if(cartItem?.book){
            return getRandomImageForNormalBook(cartItem?.book,allColorsData,cartItem?.color,allColorsData?.leatherType,cartItem?.variantType)?.image
        }
        else if(cartItem?.bestSellerBook){
            const link =  getRandomImageForBestSellerBook(cartItem?.bestSellerBook,allColorsData,cartItem?.color,allColorsData?.leatherType,cartItem?.variantType)?.image
            return link
        }
        return ""
    } 

    return (
        <>
            <div className={classes.cartTableWrapper}>
                <table className={classes.cartTable}>
                    <thead>
                        <tr>
                            <th>Products</th>
                            <th style={{textAlign:"center"}}>Quantity</th>
                            <th>Address</th>
                            <th></th> {/* Empty header for delete button column */}
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems && cartItems?.map((item, itemIdx) => (
                            <>
                                {/* Cart Item Row */}
                                <tr key={item.id}>
                                    <td>
                                        <div className={classes.product}>
                                            <div className={classes.productImage}>
                                                <img src={getBookImage(item)} alt="Book" />
                                            </div>
                                            <div className={classes.productDetails}>
                                                <h2>{item?.book?.title || item.bestSellerBook?.title }</h2>
                                                <p>Edition: {getEnum(item.variantType)} | {item.book ? `Color : ${item.color}` : ""}</p>
                                                <h2>${Number(item.itemPrice * item.quantity).toFixed(2)}</h2>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={classes.quantity}>
                                        <div className={classes.qtyController}>
                                            <p onClick={() => handleQtyDecrement(item)}>-</p>
                                            <h2>{item.quantity}</h2>
                                            <p onClick={() => handleQtyIncrement(item)}>+</p>
                                        </div>
                                    </td>
                                    <td className={classes.addressColumn}>
                                        <select
                                            className={classes.addressDropdown}
                                            value={item?.shippingAddress?.addressId ? item?.shippingAddress?.addressId : ""}
                                            disabled={!currentUser?.shippingAddresses?.length}
                                            style={{
                                                backgroundColor: currentUser?.shippingAddresses?.length ? '#fff' : '#f6f6f6',
                                            }}
                                            onChange={(event) => handleAddressChange(item, event)}
                                        >
                                            <option disabled value="">
                                                  ---Select Address---     
                                            </option>
                                            {currentUser?.shippingAddresses?.length ? (
                                                currentUser.shippingAddresses.map((address, index) => (
                                                    <option key={index} value={address.addressId}>
                                                        {`${address.addressLine1} ${address.addressLine2} ${address.city} ${address.state}`}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>No saved address</option>
                                            )}
                                        </select>
                                        <div className={classes.additionalOptions}>
                                            <p onClick={() => setModalOpen(true)}>Add new address</p> 
                                        </div>
                                    </td>
                                    <td className={classes.deleteItem}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={() => handleDeleteItem(item.id)}
                                            className={classes.deleteIcon}
                                        />
                                    </td>
                                </tr>
                                <tr key={`shipping-${item.id}`}>
                                    <td colSpan="4">
                                        <div className={classes.shippingSpeed}>
                                            <h4>Shipping speed:</h4>
                                            <div className={classes.shippingSpeedOption}>
                                                {item?.shippingCharges.map((option, index) => (
                                                    <div key={index} className={classes.shippingOption}>
                                                        <input
                                                            type="radio"
                                                            name={`shippingSpeed-${item.id}`}
                                                            value={option}
                                                            id={`shippingSpeed-${item.id}-${index}`}
                                                            checked={item.shippingSpeed === option.shippingSpeed}
                                                            onChange={() => handleShippingSpeedChange(item, option.shippingSpeed)}
                                                        />
                                                        <label htmlFor={`shippingSpeed-${item.id}-${index}`}>{`${formatDate(option.shipByDate)} (${ option.shippingSpeed === "standard" ? "Free" : ("+$"+ option.totalAmount.toFixed(2))})`}</label>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={4}>
                                        <div className={classes.addOn}>
                                            <h2 className={classes.addOnHeader}>Add on</h2>
                                            <div className={classes.options}>
                                                <OptionButton option={"Box Wrapping"} id={`box-wrapping-${item.id}-${itemIdx}`} price={15} cartItem={item}/>
                                                <OptionButton option={"Gift Wrapping"} id={`gift-wrapping-${item.id}-${itemIdx}`} price={15} cartItem={item}/>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalOpen && <Modal title={isEdit === true ? "Update Address" : "Add Address"} onClose={handleModalClose}>
                    <AddressForm type="shipping" initialData={null} onClose={handleModalClose}/>
                </Modal>}
        </>
    );
};

export default CartMini;
