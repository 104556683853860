import classes from "./MarblePaper.module.css"

import { useContext, useEffect, useState } from "react";

import { useFabricCanvas } from "react-fabric-page";
import { addImageToCanvas } from "../../../../objectUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import { getEndPaper } from "../../../../Store/actions/ColorsAction";
import { CustomizationContext } from "../../Customization";
import Slider from "react-slick/lib/slider";
import PreviewCard from "../../Components/PreviewCard/PreviewCard";

const MarblePaper = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown,isCanvasReady }) => {
    const {frontMarblePaper,setFrontMarblePaper,selectedDesigns} = useContext(CustomizationContext);
    const wantinnerBorder = "Yes"

    const [allPapers,setAllPapers] = useState([]);

    const canvas = useFabricCanvas()

    const {isMediumScreen} = useScreenSize()

    const settings = {
        slidesToShow: allPapers?.length > 1 ? 2.5 : 1,
        swipeToSlide: true,
        infinite: false,
        centerPadding: "10px",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: allPapers?.length > 1 ? 3.5 : 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: allPapers?.length > 1 ? 2.5 : 1,
              }
            },
            {
                breakpoint: 400,  // New breakpoint for screens below 400px
                settings: {
                  slidesToShow: allPapers?.length > 1 ? 1.5 : 1,
                }
              }
          ]
      };

    const getEndPapers = async () => {
        let filter = {
        };
    
        try {
          const res = await getEndPaper(filter);
          if (res.status === 200) {
            const allowedPapers = res.data?.data?.filter((paper) => paper.disabled === false)
            setAllPapers(allowedPapers);
          }
        } catch (err) {
          
        }
      };
    
      useEffect(() => {
        getEndPapers();
      }, []);

      useEffect(() => {
        setFrontMarblePaper(allPapers?.[0])
      },[allPapers])

    const handleClickAndSelect = () => {
        onClick()
        if (dropdownOpen) {
            toggleDropdown(false)
        }
        else {
            toggleDropdown(true)
        }
    }
    const getBounds = () => {
        const imageBox = canvas?.getObjects().find(o => o.id==="image")

        if(imageBox){
            return {
                left: imageBox.left,
                top: imageBox.top,
                width: imageBox.width,
                height:imageBox.height
            }
        }
        else{
            return null
        }

    }


    const setCoverImage = (imageUrl) => {
        addImageToCanvas({filepath:imageUrl,fileType:"image"},1,getBounds,null,false,false).then((img) => {
            const layer = canvas?.getObjects()?.find(o => o?.id === 'image');
            if(layer){
                layer.add(img);
                canvas.dirty = true;
                canvas.renderAll();
            }
          }).catch((error) => {
            
          })
    };

    useEffect(() => {
            if (allPapers?.length > 0 && isCanvasReady === true) {
                if(selectedDesigns?.customization?.marblePaper?.endpaper) {
                  const selectedPaper = allPapers?.find((paper) => paper?.id === selectedDesigns?.customization?.marblePaper?.endpaper);
                  if(selectedPaper) {
                    setCoverImage(selectedPaper?.designImage);
                    setFrontMarblePaper(selectedPaper);
                  } else {
                    setCoverImage(allPapers?.[0]?.designImage);
                    setFrontMarblePaper(allPapers?.[0]);
                  }
                } else {
                  setCoverImage(allPapers?.[0]?.designImage);
                  setFrontMarblePaper(allPapers?.[0]);
                }
            } 
    }, [canvas,allPapers,isCanvasReady,selectedDesigns]);

    const handleChangeMarblePaper = (paper) => {
        setFrontMarblePaper(paper);
        if(frontMarblePaper?.designName !== paper?.designName){
            setCoverImage(paper?.designImage)
        }
    }
    
    return (
        <div>
            {isMediumScreen ? (
                <div onClick={(e) => e.stopPropagation()}>

                {wantinnerBorder === "Yes" && <div className={classes.innerBorderTypes}>
                    <p>Select Design :</p>


                    <div className={classes.innerBorderTypePreview}>
                        <div className={classes.previewContainer}>
                        <Slider {...settings}>
                        {allPapers?.map((marblePaper) => (
                          <div
                            key={marblePaper?.designName}
                            className={`${classes.previewCard} ${
                              frontMarblePaper?.designName ===
                              marblePaper?.designName
                                ? classes.activePreviewCard
                                : ""
                            }`}
                            onClick={() => handleChangeMarblePaper(marblePaper)}
                          >
                            <img src={marblePaper?.designImage} alt="" />
                          </div>
                        ))}
                      </Slider>
                        </div>

                    </div>
                    <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                </div>}

            </div>
            ) : (
                <div className={classes.innerBorder} onClick={() => handleClickAndSelect()}>
                    <div className={classes.innerBorderHeader}>
                        <h2>Marble Paper</h2>
                        <div className={classes.dropDownImage} onClick={toggleDropdown}>
                            {dropdownOpen && currentDropdown === "Marble Paper" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>

                    {dropdownOpen && currentDropdown === "Marble Paper" ? (
                <div onClick={(e) => e.stopPropagation()}>

                {wantinnerBorder === "Yes" && <div className={classes.innerBorderTypes}>
                    <p>Select Design :</p>


                    <div className={classes.innerBorderTypePreview}>
                        <div className={classes.previewContainer}>
                        <Slider {...settings}>
                        {allPapers?.map((marblePaper) => (
                        //   <div
                        //     key={marblePaper?.designName}
                        //     className={`${classes.previewCard} ${
                        //       frontMarblePaper?.designName ===
                        //       marblePaper?.designName
                        //         ? classes.activePreviewCard
                        //         : ""
                        //     }`}
                        //     onClick={() => handleChangeMarblePaper(marblePaper)}
                        //   >
                        //     <img src={marblePaper?.designImage} alt="" />
                        //   </div>
                          <PreviewCard
                          key={marblePaper?.designName}
                          image={marblePaper?.designImage}
                          altText={marblePaper?.designName}
                          handleClick={() => handleChangeMarblePaper(marblePaper)}
                          isActive={
                            frontMarblePaper?.designName === marblePaper?.designName
                          }
                        />
                        ))}
                      </Slider>
                        </div>

                    </div>
                    <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                </div>}

            </div>
                    ) : (
                        <div></div>
                    )
                    }
                </div>

            )}

        </div>
    )
}

export default MarblePaper