import classes from "./AboutCorporateGifts.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title:"Knowledge and Morale Boost",
        desc:"Well-written books are investments in your staff members' personal and professional development, rather than just small presents. Give thoughtful, classic gifts to your team to let them know you appreciate their growth.",
        image:image1
    },
    {
        title:"Flexible Ordering",
        desc:"We offer flexible ordering choices to meet your needs, regardless of the size of your employees. We can assist small teams as well as major businesses.",
        image:image2
    },
    {
        title:"Personalized to Perfection",
        desc:" Adding personalization to business gifts gives them a special touch. To make the gift even more personalized, add a personal note, the names of the employees, or your company logo to each book.",
        image:image3
    },
    {
        title:"Employee Recognition",
        desc:" Give individualized presents to recognize each employee's accomplishments. Personalized leather-bound volumes with the recipient's name, accomplishment, or a unique message can be used as rewards or tokens of appreciation.",
        image:image3
    },
    {
        title:"Classic Style",
        desc:"Books with leather bindings radiate elegance and sophistication, making them a perfect present to impress partners, clients, and staff. The traditional design and rich texture demonstrate your company's dedication to excellence and quality.",
        image:image3
    },
    {
        title:"Enduring Gift",
        desc:"A beautifully bound book can be treasured for years to come, acting as a permanent reminder of your kindness, unlike ordinary or fragile gifts. Every leather-bound book is a timeless memento, whether it's a corporate history, a custom notebook, or a famous literary work.",
        image:image3
    },
    {
        title:"Holiday Seasons",
        desc:"Give your staff and customers a unique and luxurious gift that will make a lasting impression. A book with a leather cover is a festive, original, and considerate gift that anybody may appreciate.",
        image:image3
    },
    {
        title:"Events & Milestones for Corporate",
        desc:"Recall important business achievements, anniversaries, or new product introductions with a gift that perfectly reflects the moment. A custom book might record the company's history, significant events, or group accomplishments",
        image:image3
    },
    {
        title:"Client Appreciation",
        desc:"By expressing your value for their business and participation, a thoughtful, well-chosen present can fortify your bonds with important clients and partners.",
        image:image3
    },
]

const AboutCorporateGifts = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Why Choose Premium Leather-Bound Books for Corporate Gifting?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutCorporateGifts; 