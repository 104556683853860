import { useState, useEffect } from "react";
import classes from "./SignUp.module.css"
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import Password from '../../Components/Password/Password'
import { signUpUser, signInUser } from '../../Store/actions/UsersActions'
import { useDispatch } from 'react-redux'
import SocialMediaButton from "../../Components/Buttons/SocialMediaButton/SocialMediaButton";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import PrimaryInput from "../../Components/Inputs/PrimaryInput/PrimaryInput";
import Checkbox from "../../Components/Checkbox/Checkbox";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";

const slide1 = "/Assets/Images/signUp1.svg"
const slide2 = "/Assets/Images/SignUp2.svg"
const slide3 = "/Assets/Images/SignUp3.svg"
const google = "/Assets/Icons/google.svg"

const config = await fetch("/api/openAuth/googleAuthData").then(i => i.json()).then(i => i.data);
const scope = "openid profile email";
const state = "random_string_to_prevent_csrf";
const encode = window.encodeURIComponent;
const authEndpoint = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encode(config.clientId)}&redirect_uri=${encode(config.redirectUrl)}&scope=${encode(scope)}&state=${encode(state)}`;


function SignUp() {


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [showModal, setShowModal] = useState(false)
 
  const [agreeTC, setAgreeTC] = useState(false)

  const referralCode = localStorage.getItem("referral_code") || ""

  const dispatch = useDispatch();
  const handleMobileNumber = (e) => {
    if(String(e.target.value).length <= 10){
      setContactNo(e.target.value);
    }
    // const validationMessage = validateMobileNumber(e.target.value); 
    // setMobileError(validationMessage);
  }

  function handleFormSubmission(e) {
    e.preventDefault()
    if (firstName && lastName && email && password && contactNo && countryCode && agreeTC) {
      if (password === confirmPassword) {
        const data = {
          phoneNo: countryCode + contactNo,
          fullName: firstName + " " + lastName,
          password,
          userId: email.toLowerCase(),
        }

        if (referralCode) {
          data.usingReferralCode = referralCode;
        }

        signUpUser(data, dispatch).then((response) => {
          if (response && response.data && response.data.success) {
            setShowModal(true);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.removeItem("referral_code")
          }
          else{
            alert("Error")
          }
        })
      }
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      image: slide1,
      text: '"Cherish the Craftsmanship of Every Leather-Bound Edition."'
    },
    {
      image: slide2,
      text: '"Embrace the Timeless Elegance of Leather-Bound Treasures."'
    },
    {
      image: slide3,
      text: `"Elevate your book collection with us"`
    },
  ];

  const isDisabled = () => {
    return !(
      firstName &&
      lastName &&
      email &&
      password &&
      confirmPassword &&
      contactNo &&
      countryCode &&
      agreeTC &&
      password === confirmPassword &&
      String(contactNo).length === 10
    );
  };

  

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 2000);
    return () => clearInterval(interval);
  },);

  return (
    <div>
      {showModal && (
        <ConfirmationModal
          email={email}
          onClose={() => setShowModal(false)}
          onResend={handleFormSubmission}
        />
      )}

      <div>
        <PrimaryNav />
        <div className={classes.signupContainer}>
          {/* {showMessageDialog && (
            <div >

              <MessageDialog
                {...dialogContent}
                onClose={() => onClickCloseMessageDialogue(false)}
                closeText="Close"
                width={Consts.MESSAAGE_DIALOG_WIDTH}
                dialogueIcon={successIcon}
              />
            </div>

          )} */}
          <div className={classes.signupForm}>
            <h2>Sign up</h2>
            <p>Let's get you all set up so you can access your personal account.</p>
            <form>
              <div className={classes.formGroup1}>
                <div className={classes.firstName}>
                  <PrimaryInput
                    type='text'
                    id='firstName'
                    placeholder="First Name"
                    autoFocus={true}
                    onChange={(e) => { setFirstName(e.target.value) }}
                    value={firstName}
                    required
                  />
                </div>
                <div className={classes.lastNamee}>
                  <PrimaryInput
                    type='text'
                    id='lastName'
                    placeholder="Last Name"
                    autoFocus={true}
                    onChange={(e) => { setLastName(e.target.value) }}
                    value={lastName}
                    required

                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <PrimaryInput
                  type='email'
                  id='userId'
                  placeholder="Email"
                  autoFocus={true}
                  onChange={(e) => { setEmail(e.target.value) }}
                  value={email}
                />
              </div>
              <div className={classes.formGroup}>
                <div className={classes.contactNumber}>
                  <select name="countryCode" id="countryCode" required onChange={(e) => setCountryCode(e.target.value)}>
                    <option value="Country Code" defaultChecked={true} className={classes.placeholder}>Country Code</option>
                    <option value="+1">+1</option>
                    <option value="+91">+91</option>
                    <option value="+44">+44</option>


                  </select>
                  <PrimaryInput
                    type='number'
                    id='contactNumber'
                    placeholder="Contact Number"
                    autoFocus={true}
                    onChange={handleMobileNumber}
                    value={contactNo}
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div className={classes.password}>
                  <Password
                    id='password'
                    placeholder="Password"
                    togglePasswordVisibility={true}
                    height='2.25rem'
                    onChange={(e) => { setPassword(e.target.value) }}
                    autoComplete="new-password"
                    onCommit=""
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div className={classes.password}>
                  <Password
                    id='password'
                    placeholder="Confirm Password"
                    togglePasswordVisibility={true}
                    height='2.25rem'
                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                    autoComplete="off"
                    onCommit=""
                  />
                </div>
              </div>
              <div className={classes.options}>
                <div>
                  <Checkbox id={"TC"} label={"I agree to all the Terms and Privacy Policies"} checked={agreeTC} onChange={() => setAgreeTC(!agreeTC)} />
                </div>
                <div>
                  <Checkbox id={"daily-update"} label={"Opt in to daily updates from us"} onChange={() => { }} />
                </div>
              </div>
              <PrimaryButton type="submit" className={classes.signupButton} disabled={isDisabled()} onClick={(e) => handleFormSubmission(e)} children={"Create Account"} />
            </form>
            <p className={classes.loginText}>
              Already have an account? <Link to="/login">Login</Link>
            </p>
            <div className={classes.orSignUpWith}>Or Sign up with</div>
            <div className={classes.socialButtons}>
              <SocialMediaButton url={authEndpoint} className={classes.socialButton} icon={google} callback={e => {
                let data = {
                  with: "google",
                  token: e.id_token
                }
                signInUser(data, dispatch).then((response) => {
                  if (response && response.data && response.data.success) {
                    localStorage.setItem("isLoggedIn", "true");
                    
                  } else if (response.data?.errorCode) {
                    
                  }
                });
              }} />
            </div>
          </div>
          <div className={classes.carouselContainer}>
            <div className={classes.carousel}>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`${classes.slide} ${index === currentIndex ? classes.active : ''}`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                >
                  <p className={index % 2 !== 0 ? classes.slideTextTop : ""}>{slide.text}</p>
                </div>
              ))}
            </div>
            <div className={classes.dots}>
              {slides.map((_, index) => (
                <span
                  key={index}
                  className={`${classes.dot} ${index === currentIndex ? classes.activeDot : ''}`}
                  onClick={() => goToSlide(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
  )
}

export default SignUp;