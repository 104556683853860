import React, { useEffect, useState } from 'react';
import classes from "./SearchComponent.module.css"
import { fetchBook,fetchBookCount, getKeywords, getSuggestionsForNormalBooks } from '../../../../Store/actions/BookAction';
import { useDispatch, useSelector } from 'react-redux';
import useScreenSize from '../../../../Hooks/UseScreenSize';
import SearchBarSmall from '../SearchBarSmall/SearchBarSmall';
import { urls } from '../../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PrimaryButton from '../../../../Components/Buttons/PrimaryButton/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchComponent = () => {
    const keywords = useSelector((state) => state.keywords.keywords);
    const [query, setQuery] = useState("");
    const [searchCriteria, setSearchCriteria] = useState("all");

    const history = useHistory()
    const {isSmallScreen} = useScreenSize()

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const dispatch = useDispatch();

    const handleSearch = async () => {
        if (query === "") return;
        const searchResults = await fetchBook(searchCriteria, query, dispatch);
        const searchCount = await fetchBookCount(searchCriteria,query);
    
        // Redirect to LISTING page with search results as state
        history.push({
            pathname: urls.LISTING,
            state: { 
                searchResults, 
                searchCount,
                searchCriteria,
                query
            }
        });
    };

    const handleKeyWordSearch = async (keyword) => {
        if(keyword){
            const searchCriteria = "genres";
            const searchResults = await fetchBook(searchCriteria, keyword, dispatch);
            const searchCount = await fetchBookCount("","","","",[keyword]);
            
            // Redirect to LISTING page with search results as state
            history.push({
                pathname: urls.LISTING,
                state: { 
                    searchResults, 
                    searchCount,
                    searchCriteria,
                    keyword
                }
            });
        }
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleSearch()
        }
    }   

    useEffect(() => {
        getKeywords(dispatch);
    }, []);

    const allRecommendations = useSelector((state) => state.recommendation.recommendations);

    const [searchRecommendations,setSearchRecommedations] = useState([]);
    const [recommendationClicked,setRecommendationClicked] = useState(false);
    const [focus,setFocus] = useState(false);
    const [blurTimeout, setBlurTimeout] = useState(null);
  
    const handleRecommendationClick = (recommendation) => {
      const words = query.trim().split(" ");
      if (words.length > 1) {
        words[words.length - 1] = recommendation;
        setQuery(words.join(" "));
      } else {
        setQuery(recommendation);
      }
      setSearchRecommedations([]);
      setRecommendationClicked(true);
    };
  
    useEffect(() => {
      if(recommendationClicked) {
        handleSearch();
        setRecommendationClicked(false);
      }
    },[recommendationClicked])
    
    useEffect(() => {
      if (query) {
        const pattern = /\s\S+$/;
        const match = query.match(pattern);
        if (match) {
          const modifiedQuery = query.replace(pattern, "").trim(); // "the a" -> "the"
          getSuggestionsForNormalBooks(dispatch, searchCriteria, modifiedQuery, true,searchCriteria === 'all' ? "title,author" : searchCriteria);
        }
      }
    }, [query,searchCriteria]);

    useEffect(() => {
        if (query) {
          const words = query.trim().split(/\s+/);
          const searchWord = words.length === 1 ? query.toLowerCase() : words[words.length - 1].toLowerCase();
          setSearchRecommedations(
            allRecommendations.filter(word => word.toLowerCase().startsWith(searchWord))
          );
        } else {
          setSearchRecommedations([]);
        }
      }, [query, allRecommendations]);

      const handleFocus = () => {
        if (blurTimeout) {
          clearTimeout(blurTimeout); 
        }
        setFocus(true);
      };
    
      const handleBlur = () => {
        const timeout = setTimeout(() => setFocus(false),1000);
        setBlurTimeout(timeout);
      };
    

    return (
        <div className={classes.search}>
            {!isSmallScreen ? (
                <div className={classes.container}>
                  <div className={classes.searchContainer}>
                    <div className={classes.dropdownContainer}>
                      <select
                          name="searchField"
                          id="searchField"
                          required
                          onChange={(e) => setSearchCriteria(e.target.value)}
                      >
                          <option value="all">All</option>
                          <option value="author">Author</option>
                          <option value="title">Book Title</option>
                          {/* <option value="keyword">Keyword</option>
                          <option value="ISBN">ISBN</option> */}
                      </select>
                    </div>

                    <input
                        type="text"
                        className={classes.searchInput}
                        placeholder="Search via , Book title, Author"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onFocus={handleFocus}  
                        onBlur={handleBlur} 
                    />
                    <PrimaryButton className={classes.searchButton} onClick={handleSearch}>
                        Search Now
                    </PrimaryButton>
                  </div>
                  {/* Dropdown with Recommendations */}
                  {searchRecommendations.length > 0 && focus && (
                    <div className={classes.dropdown}>
                    {searchRecommendations.slice(0,10).map((recommendation, index) => (
                      <div
                        key={index}
                        className={classes.dropdownItem}
                        onClick={() => handleRecommendationClick(recommendation)}
                      >
                        <FontAwesomeIcon icon={faSearchengin}/>
                        {recommendation}
                      </div>
                    ))}
                    </div>
                  )}
              </div>
            ) : (
                <SearchBarSmall/>
            )}

            <div className={classes.keywords}>
              {keywords.map((keyword, index) => (
                <span key={index} onClick={() => handleKeyWordSearch(keyword)}>{keyword}</span>
              ))}
            </div>
        </div>
    );
};

export default SearchComponent;
