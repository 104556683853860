import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import useScrollToTop from "../../Hooks/useScrollToTop";
import Coupons from "./Coupons/Coupons";
import ManageAddress from "./ManageAddress/ManageAddress";
import classes from "./MyAccount.module.css";
import { MyProfile } from "./MyProfile/MyProfile";
import Orders from "./Orders/Orders";
import Sidebar from "./SideBar/Sidebar";
import Wallet from "./Wallet/Wallet";
import Wishlist from "./Wishlist/Wishlist";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";

const menuIcon = "/Assets/Icons/icon-bars.svg";

const MyAccount = () => {
  const { path } = useRouteMatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const scrollRef = useScrollToTop();
  const [show, setShow] = useState(false);
  const dropdownRef = useRef();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <div className={classes.sideForSmallScreen} ref={dropdownRef}>
        {show && <Sidebar onClose={() => setShow((e) => !e)} />}
      </div>
      <div className={classes.main}>
        <div className={classes.headerContainer}>
          <div className={classes.menuIcon}>
            <img onClick={() => setShow((e) => !e)} src={menuIcon} alt="" />
          </div>
          <div className={classes.title}>My Account</div>
        </div>
        <div className={classes.name}>{currentUser.fullName}</div>
        <div style={{ display: "flex" }}>
          <div className={classes.sidebar}>
            <Sidebar />
          </div>
          <div className={classes.container}>
            <Switch>
              <Route exact path={`${path}`} component={MyProfile} />
              <Route exact path={`${path}/my-profile`} component={MyProfile} />
              <Route exact path={`${path}/my-orders`} component={Orders} />
              <Route
                exact
                path={`${path}/manage-address`}
                component={ManageAddress}
              />
              <Route exact path={`${path}/coupons`} component={Coupons} />
              <Route exact path={`${path}/wishlist`} component={Wishlist} />
              <Route exact path={`${path}/my-wallet`} component={Wallet} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
