import classes from "./AddLogo.module.css";
import { useState, useEffect, useContext } from "react";
import { useFabricCanvas } from "react-fabric-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Components/LogoModal/LogoModal";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { fabric } from "react-fabric-page";
import { useSelector } from "react-redux";

const AddLogo = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown }) => {
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const {frontCoverLogo,frontCoverLogoColor,setFrontCoverLogoColor,allFoilingColors} = useContext(CustomizationContext);
  const canvas = useFabricCanvas();
  //   const [activeObject, update] = useActiveFabricObject(ActiveTextboxObject);
  // const [photo, setPhoto] = useState(null);
  // const [imageURL, setImageURL] = useState("");

  const [colors,setColors] = useState([])

  // const getColor = async () => {
  //   let filter = {
  //     // offset: (currPageNo - 1) * pageSize,
  //     // limit: pageSize,
  //   };

  //   try {
  //     const res = await getFoilingColor(filter);
  //     if (res.status === 200) {
  //       setallFoilingColors(res.data?.data);
  //     }
  //   } catch (err) {
  //     
  //   }
  // };

  // useEffect(() => {
  //   getColor();
  // }, []);

  useEffect(() => {
    const allowedColors = allFoilingColors?.filter(
      (color) => color.disabled === false
    );
    setColors(allowedColors);
  }, [allFoilingColors]);

  useEffect(() => {
    setFrontCoverLogoColor(colors?.[0])
  },[colors])


  const { isMediumScreen } = useScreenSize()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen && currentDropdown === 'All Logo') {
      toggleDropdown(false)
    }
    else {
      toggleDropdown(true)
    }
  };

  const handleColorChange = (color,e) => {
    e.stopPropagation()
    setFrontCoverLogoColor(color);
  }

  // const handlePhotoUpload = (e) => {
  //   const file = e.target.files[0]
  //   setPhoto(file)
  //   if (file) {
  //     const blob = new Blob([file], { type: file.type });
  //     const url = window.URL.createObjectURL(blob);
  //     setImageURL(url);
  //     setFrontCoverLogo(url)
  //   }
  // }

  const changeLogoColor = () => {
    if (canvas) {
      const layer = canvas?.getObjects()?.find((o) => o.id === "contents");
      if (layer) {
        const activeObject = layer?._objects?.filter(
          (o) => o.type === "imageBox"
        )?.[0];
        if (activeObject && colors) {
          activeObject.filters[0] = new fabric.Image.filters.PatternColor({
            color: frontCoverLogoColor?.colorValue,
            convert: true,
          });
          activeObject.applyFilters();
          layer.dirty = true;
        }
      }
      canvas.renderAll();
    }
  };

  useEffect(() => {
    changeLogoColor()
  },[frontCoverLogoColor,frontCoverLogo])

  // useEffect(() => {
  //   const updateImageURL = (canvas, newImageURL) => {
  //     const layer = canvas?.getObjects().find(o => o.id === 'contents');
  //     if (layer) {
  //       const imageObject = layer._objects.find(obj => obj.type === 'imageBox');
  //       if (imageObject) {
  //         imageObject.updateSrc(newImageURL)
  //         layer.dirty = true
  //         canvas.renderAll()
  //       }
  //     }
  //   };

  //   updateImageURL(canvas, imageURL)

  // }, [photo])

  return (
    <div>
      <Modal onClose={handleCloseModal} isOpen={isModalOpen} />
      {isMediumScreen ? (
        <div className={classes.colorPickerDropdown} onClick={(e) => e.stopPropagation()}>
          <h3>Upload your personalized design for Foiling.</h3>

          <div className={classes.uploadLogo}>
            {/* <div className={classes.uploadBtn}>
                                <input type="file" id="photoUpload" accept="image/*" onChange={(e) => handlePhotoUpload(e)} title="Select Photos" className={classes.photoUpload} />
                                <label htmlFor="photoUpload" className={classes.photoUploadButton}>Upload Photo</label>
                            </div> */}
            <div className={classes.uploadBtn} onClick={handleOpenModal}>
              <div className={classes.addLogo}>
                <FontAwesomeIcon icon={faPlusCircle} className={classes.addLogoIcon} />
                <p>Upload your unique design!</p>
              </div>
              <div className={classes.logoPrice}>
                +${customizationPricing?.logoFrontPrice}
              </div>
            </div>

            <div className={classes.logoColor}>
              <p>Pick Your Preferred Foil Color!</p>
              {/* <div className={classes.logoColorOptions}>
                {logoColor.map((color, index) => (
                  activeColor === color ? (
                    <div key={index} className={classes.activeColorPicker}>
                      <div className={classes.activeColor}>
                        <div className={classes.color} style={{background:color.hex}}></div>
                      </div>
                      <p>{color.name}</p>
                    </div>
                  ) : (
                    <div key={index} className={classes.colorPicker}>
                      <div className={classes.color} style={{background:color.hex}} onClick={() => handleColorChange(color)}>

                      </div>
                      <p>{color.name}</p>
                    </div>
                  )
                ))}
              </div> */}
              <ColorSelector availableColors={colors} selectedColor={frontCoverLogoColor} handleColorChange={handleColorChange} />
            </div>

          </div>
          
        </div>
      ) : (
        <div className={classes.ColorOption} onClick={() => handleClickAndSelect()}>
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
                <h2>Unique Design</h2>
                <ToolTip tooltipText={"Add Custom logo for your book"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Add Text" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Unique Design" ? (
        <div className={classes.colorPickerDropdown} onClick={(e) => e.stopPropagation()}>
        <h3>Please upload a line art of your logo</h3>

        <div className={classes.uploadLogo}>
          {/* <div className={classes.uploadBtn}>
                              <input type="file" id="photoUpload" accept="image/*" onChange={(e) => handlePhotoUpload(e)} title="Select Photos" className={classes.photoUpload} />
                              <label htmlFor="photoUpload" className={classes.photoUploadButton}>Upload Photo</label>
                          </div> */}
          <div className={classes.uploadBtn} onClick={handleOpenModal}>
            <div className={classes.addLogo}>
              <FontAwesomeIcon icon={faPlusCircle} className={classes.addLogoIcon} />
              <p>Upload your unique design!</p>
            </div>
            <div className={classes.logoPrice}>
              +${customizationPricing?.logoFrontPrice}
            </div>
          </div>

          <div className={classes.logoColor}>
            <p>Select Logo Color for front color</p>
            <ColorSelector availableColors={colors} selectedColor={frontCoverLogoColor} handleColorChange={handleColorChange} />
          </div>

        </div>
        
      </div>
          ) : (
            <div></div>
          )}
        </div>

      )}

    </div>
  );
};

export default AddLogo;
