import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./ProductDetailSmall.module.css"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { useContext, useState } from "react"
import { CustomizationContext } from "../../Customization"
import { getBookPrice } from "../../../../Util"
import CustomizationDetails from "../CustomizationDetails/CustomizationDetails"

const ProductDetailSmall = () => {
    const {bookData,bookLeather,bookVariant} = useContext(CustomizationContext)

    const [showDetailModal,setShowDetailModal] = useState(false)
    return(
        <div className={classes.mainContainer}>
            <h2>{bookData?.title}</h2>
            <div className={classes.priceSection}>
                <p>${getBookPrice(bookData,bookLeather,bookVariant)}</p>
                <FontAwesomeIcon className={classes.icon} icon={faAngleRight} onClick={() => setShowDetailModal(true)}/>
            </div>

            {showDetailModal && <CustomizationDetails onClose = {() => setShowDetailModal(false)} />}
        </div>
    )
}

export default ProductDetailSmall;