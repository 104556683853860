import { useParams } from "react-router-dom/cjs/react-router-dom"
import BannerHeader from "../../../Components/BannerHeader/BannerHeader"
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav"
import classes from "./Blog.module.css"
import { useSelector } from "react-redux"
import HtmlViewer from "../../../Components/HtmlViewer/HtmlViewer"
import { urls } from "../../../Routes/Urls"
import Footer from "../../Footer/Footer"
import useScrollToTop from "../../../Hooks/useScrollToTop"

const Blog = () => {
    const blogData = useSelector((state) => state.blogs.allBlogs);
    const { slug } = useParams()
    const currentBlog = blogData?.filter(blog => blog.urlSlug === slug)[0]

    

    const crumbs = [
        {title:"Home",path:("/")},
        {title:"Blogs",path:(urls.BLOG)},
        {title:currentBlog?.title,path:`${urls.BLOG}/${currentBlog?.urlSlug}`}
    ]

    const scrollRef = useScrollToTop()

    return (
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader crumbs={crumbs} title={"Blogs"} />

            <div className={classes.mainContainer}>
                <h1>{currentBlog?.title}</h1>

                <div className={classes.blogBanner}>
                    <img src={currentBlog?.bannerImagePath}
                        alt={currentBlog?.bannerAlt || currentBlog?.title || "blog image"}
                    />
                </div>
            <HtmlViewer src={currentBlog?.contentPath} />
            </div>
            <Footer />
            </div>
    )
}

export default Blog