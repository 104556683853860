import React from "react";
import classes from "./ItemsNav.module.css";
import NavDropdown from "../NavDropdown/NavDropdown";
import NavItem from "../NavItem/NavItem";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { urls } from "../../../Routes/Urls";

function ItemsNav() {
  const history = useHistory()

  const handleNavLinks = (option) => {
    if(option === 'Contact Us'){
      history.push(urls.CONTACTUS)
    }
    else if(option === "Our Collection"){
      history.push(urls.LISTING)
    }
    else if(option === "Best Seller"){
      history.push(urls.BESTSELLER)
    }
    else if(option === 'Customization'){
      history.push(urls.CUSTOMIZATION)
    } else if (option === "RB-Exclusive"){
      history.push(urls.RB_EXCLUSIVE);
    }
    else if(option === "Our Blogs"){
      history.push(urls.BLOG)
    }
  }

  return <nav className={classes.main}>
        <div className={classes.container}>
            <NavItem onClick={() => handleNavLinks("Our Collection")}>Our Collections</NavItem>
            <NavItem onClick={() => handleNavLinks("Best Seller")}>Best Sellers</NavItem>
            <NavDropdown options={[{label: "Book Customization"},{label:"Leather Binding"},{label:"PDF Printing"},{label:"Book Restoration"},{label:"Thesis Binding"},{label:"For Publishers"},{label:"Become Our Partner"},{label:"For Authors"},{label:"Print On Demand"},{label:"Corporate Gifts"}]}><div>Our Services</div></NavDropdown>
            <NavDropdown options={[{label: "Our Company"},{label: "Contact us"},{label:"FAQs"}]}><div>About us</div></NavDropdown>
            <NavItem onClick={() => handleNavLinks("Our Blogs")}>Our Blogs</NavItem>
            <div>
            <SecondaryButton className={classes.exclusiveBtn} onClick={() => handleNavLinks("RB-Exclusive")}>RB Exclusive</SecondaryButton>
            </div>
        </div>
  </nav>;
}

export default ItemsNav;
