import classes from "./Logo.module.css";
import { useState, useEffect, useContext } from "react";
import { useFabricCanvas } from "react-fabric-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../LogoModal/LogoModal";
import { CustomizationContext } from "../../../Customization";
import OptionCapsules from "../../../../../Components/OptionCapsules/OptionCapsules";
import Slider from "react-slick/lib/slider";
import { getLogos } from "../../../../../Store/actions/ColorsAction";
import PreviewCard from "../../../Components/PreviewCard/PreviewCard";
import { addLogoToSection } from "../../../../../spineUtils";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
import { fabric } from "react-fabric-page";
import { useSelector } from "react-redux";

const logoTabs = [
  {
    name: "Embossing",
    value: "embossing",
  },
  {
    name: "Foiling",
    value: "foiling",
  },
];
const Logo = ({ activeSectionNumber, isCanvasReady, selectedOption }) => {
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const canvas = useFabricCanvas();
  const {
    spineLogo,
    setSpineLogo,
    spineLogoType,
    setSpineLogoType,
    allFoilingColors,
    spineLogoColor,
    setSpineLogoColor,
  } = useContext(CustomizationContext);
  const [allLogo, setAllLogo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getLogosData = async () => {
    let filter = {};

    try {
      const res = await getLogos(filter);
      if (res.status === 200) {
        const allowedLogos = res.data?.data?.filter(
          (logo) => logo.logoType === "spine"
        );
        setAllLogo(allowedLogos);
      }
    } catch (err) {
      
    }
  };

  useEffect(() => {
    getLogosData();
  }, []);

  const settings = {
    slidesToShow: allLogo?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    centerPadding: "10px",
  };

  useEffect(() => {
    if (!spineLogo[activeSectionNumber]?.logoImage && allLogo?.length > 0) {
      // If no logo is set for the active section, set the first one
      setSpineLogo((prev) => ({
        ...prev,
        [activeSectionNumber]: allLogo[0],
      }));
     }  else {
      updateImageURL(spineLogo[activeSectionNumber]?.logoImage)
    }
  }, [
    activeSectionNumber,
    allLogo,
    spineLogoType,
    spineLogo,
    allFoilingColors,
  ]);

  const changeFoilingColor = (img) => {
    if (img) {
      if(spineLogoColor[activeSectionNumber]?.colorValue) {
        img.filters[0] = new fabric.Image.filters.PatternColor({
          color: spineLogoColor[activeSectionNumber]?.colorValue,
          convert: true,
        });
        img.applyFilters();
        img.dirty = true;
        canvas.renderAll()
      }
    } else {
      if (canvas) {
        const layer = canvas
          ?.getObjects()
          ?.find((o) => o.id === `rib-section-${activeSectionNumber}`);
        if (layer) {
          const activeObject = layer?._objects?.filter(
            (o) => o.id === "section-logo"
          )?.[0];
          if (activeObject && spineLogoColor[activeSectionNumber]?.colorValue) {
            activeObject.filters[0] = new fabric.Image.filters.PatternColor({
              color: spineLogoColor[activeSectionNumber]?.colorValue,
              convert: true,
            });
            activeObject.applyFilters();
            layer.dirty = true;
          }
        }
        canvas.renderAll();
      }
    }
  };

  useEffect(() => {
    changeFoilingColor();
  }, [spineLogoColor, spineLogo, activeSectionNumber]);

  const updateImageURL = async (imageURL) => {
    const layer = canvas
        ?.getObjects()
        .find((o) => o.id === `rib-section-${activeSectionNumber}`);
      if (layer){    
        const img = await addLogoToSection(canvas, layer, imageURL);
        layer.add(img);
        layer.bringToFront();
        layer.dirty = true;
        canvas.renderAll();

        changeFoilingColor()
      }
  };
  

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleColorChange = (color) => {
    setSpineLogoColor((prev) => ({
      ...prev,
      [activeSectionNumber]: color,
    }));
    changeFoilingColor()
  };

  const handleLogoTypeChange = (logoType) => {
    setSpineLogoType((prev) => ({
      ...prev,
      [activeSectionNumber]: logoType,
    }));

    if (logoType === "foiling" && allFoilingColors?.length > 0) {
      setSpineLogoColor((prev) => ({
        ...prev,
        [activeSectionNumber]: allFoilingColors[0],
      }));
    }
    else if(logoType === logoTabs?.[0].value) {
      setSpineLogoColor((prev) => ({
        ...prev,
        [activeSectionNumber]:null
      }))
    }
  };

  useEffect(() => {
    setSpineLogoType((prev) => ({
      ...prev,
      [activeSectionNumber]: logoTabs[0].value,
    }));
  }, [activeSectionNumber]);

  const handleChangeLogo = (logo) => {
    setSpineLogo((prev) => ({
      ...prev,
      [activeSectionNumber]: logo,
    }));
  };

  useEffect(() => {
    // updateImageURL();
    if (selectedOption === "foiling") {
      setSpineLogoColor((prev) => ({
        ...prev,
        [activeSectionNumber]:allFoilingColors?.[0]
      }))
      changeFoilingColor();
    }

    else if(selectedOption === logoTabs?.[0].value) {
      setSpineLogoColor((prev) => ({
        ...prev,
        [activeSectionNumber]:null
      }))
    }
  }, [selectedOption, activeSectionNumber, isCanvasReady]);

  return (
    <div
      className={classes.colorPickerDropdown}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.logoType}>
        {logoTabs.map((logoType) => (
          <OptionCapsules
            key={logoType.value}
            option={logoType.name}
            onClick={() => handleLogoTypeChange(logoType.value)}
            active={spineLogoType[activeSectionNumber] === logoType.value}
          />
        ))}
      </div>
      {/* <h3>Please upload a line art of your logo</h3> */}

      <div className={classes.previewContainer}>
        <Slider {...settings}>
          {allLogo?.map((logo) => (
            <PreviewCard
              key={logo?.id}
              image={logo?.logoImage}
              altText={logo?.logoName}
              handleClick={() => handleChangeLogo(logo)}
              isActive={
                spineLogo[activeSectionNumber]?.logoName === logo?.logoName
              }
            />
          ))}
        </Slider>
      </div>

      <div className={classes.uploadLogo}>
        <div className={classes.uploadBtn} onClick={handleOpenModal}>
          <div className={classes.addLogo}>
            <FontAwesomeIcon
              icon={faPlusCircle}
              className={classes.addLogoIcon}
            />
            <p>Add your own design</p>
          </div>
          <div className={classes.logoPrice}>+${customizationPricing?.logoOnSpinePrice}</div>
        </div>

        {spineLogoType[activeSectionNumber] === logoTabs[1].value && (
          <div className={classes.logoColor}>
            <p>Select Logo Color for front color</p>
            <ColorSelector
              availableColors={allFoilingColors}
              handleColorChange={handleColorChange}
              selectedColor={spineLogoColor[activeSectionNumber]}
            />
          </div>
        )}
      </div>
      <Modal
        onClose={handleCloseModal}
        isOpen={isModalOpen}
        activeSectionNumber={activeSectionNumber}
      />
    </div>
  );
};

export default Logo;
