import classes from "./ColorPicker.module.css"
import { useContext, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown,faAngleUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useFabricCanvas,useFabricEditor } from "react-fabric-page";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ColorPickerSmall from "./ColorPickerSmall";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
import { useSelector } from "react-redux";

const ColorPicker = ({onClick,toggleDropdown,dropdownOpen,currentDropdown}) => {
    const {bookLeather,bookVariant,backCoverEditor,frontCoverColor,setFrontCoverColor,selectedDesigns} = useContext(CustomizationContext);

    const allColorsData = useSelector((state) => state.bookColor.colors);
    const allowedColors = allColorsData?.filter((color) => color.disabled === false && color.isBestSeller === false && color.variantType === bookVariant && color?.leatherType === bookLeather)

    const {isMediumScreen} = useScreenSize()
    const canvas = useFabricCanvas()
    const editor = useFabricEditor()

    const handleColorChange = (color,e) => {
        e.stopPropagation()
        setFrontCoverColor(color)
    }

    useEffect(() => {
        if(selectedDesigns?.customization) {
            const selectedColor = allowedColors?.find(color => color.colorName === selectedDesigns?.customization?.leatherColor)
            if(selectedColor) {
                setFrontCoverColor(selectedColor)
            } else {
                setFrontCoverColor(allowedColors?.[0])
            }
        } else {
            setFrontCoverColor(allowedColors?.[0])
        }
    },[allColorsData])

    const handleClickAndSelect = () => {
        onClick()
        if(dropdownOpen){
            toggleDropdown(false)
        }
        else{
            toggleDropdown(true)
        }
    }

    const changeCoverColor = (colorCode) => {
        const obj = canvas?.getObjects()?.find(o => o.id === "color");
          if (obj) {
            obj.set("fill", colorCode);
            const objBack = backCoverEditor?.canvas?.getObjects()?.find(o => o.id === "color")
            if(objBack){
                objBack.set("fill", colorCode);
                objBack.dirty = true;
                backCoverEditor.leatherColor = colorCode;
                backCoverEditor?.canvas?.renderAll()
            }
            editor.leatherColor = colorCode;
            editor.saveCurrentState();
            obj.dirty = true;
            canvas.renderAll();
        }
    }

    changeCoverColor(frontCoverColor?.colorValue)

    useEffect(() => {
        changeCoverColor(frontCoverColor?.colorValue)
    },[frontCoverColor])

    return (
        <div>
            {isMediumScreen ? <ColorPickerSmall  frontCoverColor={frontCoverColor} allowedColors={allowedColors}  handleColorChange={handleColorChange}/> : (
                    
            <div className={classes.ColorOption} onClick={() => handleClickAndSelect()}>
            <div className={classes.colorHeader}>
                <div className={classes.headerText}>
                    <h2>Leather Color</h2>
                    <ToolTip tooltipText={"Customize front cover"}/>
                </div>
                <div className={classes.dropDownImage} onClick={toggleDropdown}>
                    {dropdownOpen && currentDropdown === "Leather Color" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </div>

            {dropdownOpen && currentDropdown === "Leather Color" ? (
                <div className={`${classes.colorPickerDropdown} ${classes.open}`} onClick={(e) => e.stopPropagation()}>
                    <h3>Choose your preferred leather color</h3>

                    <div className={classes.selectColor}>
                        <h2>Color : {frontCoverColor?.colorName}</h2>
                        <div className={classes.colors}>
                            {allowedColors?.map((color, index) => (
                                frontCoverColor?.colorValue === color?.colorValue ? (
                                    <div key={index} className={classes.selected}>
                                        <div className={classes.active} style={{ background: color?.colorValue }} >
                                        </div>
                                    </div>
                                ) : (

                                    <div key={index} className={classes.colorPicker} style={{ background: color?.colorValue }} onClick={(e) => handleColorChange(color,e)}> </div>
                                )
                            ))}
                        </div>
                        <p className={classes.disclaimer}>Note: Back Cover will be same as the Front Cover</p>
                    </div>

                    <div className={classes.info}>
                        <p >
                          <FontAwesomeIcon icon={faCircleInfo} />Note: Back Cover will be same as the Front Cover
                        </p>
                    </div>
                </div>
            ) : (
                <p>{frontCoverColor?.name}</p>
            )}

        </div>
            )}

        </div>
    )   
}

export default ColorPicker;