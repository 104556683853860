import React, { useState } from 'react';
import classes from './AddressCard.module.css';
import Modal from '../../../Components/Modal/Modal';
import AddressForm from '../../MyAccount/ManageAddress/AddressForm/AddressForm';

const AddressCard = ({ address, onSelect, isSelected, type }) => {
    const [modalOpen,setmodalOpen] = useState(false)

    const handleModalClose = () => {
        setmodalOpen(false)
    }

  return (
    <div className={classes.addressCard}>
      <div className={classes.radioContainer}>
        <input 
        type="radio" 
        name="address"
         id={address.addressId}
         value={address.addressId}
          checked={isSelected}
          onChange={() => onSelect(address)}
          />
        <label htmlFor={address.addressId} className={classes.addressLabel}>
          <strong>{address.fullName}</strong>
          <p>
            {address.addressLine1}
            {address.addressLine2 && `, ${address.addressLine2}`}
            , {address.city}, {address.state}, {address.zipCode}, {address.country}
          </p>
          <p>{address.phoneNo}</p>
        </label>
      </div>
      <div className={classes.actions}>
        <div onClick={() => setmodalOpen(true)}>Edit</div>
      </div>

      {modalOpen && (
                <Modal title={address ? "Update Address" : "Add Address"} onClose={handleModalClose}>
                    <AddressForm
                        type={type || "shipping"}
                        onClose={handleModalClose}
                        initialData={address}
                    />
                </Modal>
            )}
    </div>
  );
};

export default AddressCard;

