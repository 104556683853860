import { useDispatch, useSelector } from "react-redux";
import BannerHeader from "../../../Components/BannerHeader/BannerHeader";
import CustomerReview from "../../Body/CustomerReview/CutomerReview";
import Gallery from "../../Body/Gallery/Gallery";
import Footer from "../../Footer/Footer";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import { ContactUs } from "../Components/ContactUs/ContactUs";
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo";
import FAQ from "../FAQ/FAQ";
import AboutForPublishers from "./AboutForPublishers/AboutForPublishers";
import classes from "./BecomePartner.module.css";
import { useEffect } from "react";
import { getAllFaq } from "../../../Store/actions/FaqAction";
import { urls } from "../../../Routes/Urls";
import useScrollToTop from "../../../Hooks/useScrollToTop";

const BecomePartner = () => {
  const dispatch = useDispatch();
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Become Our Partner", path: urls.BECOMEPARTNER },
  ];
  const video = useSelector(
    (state) => state.serviceVideo?.videos?.becomeOurPartnerVideo
  );
  const allFaq = useSelector((state) => state.Faq.questions);

  useEffect(() => {
    getAllFaq("category", "For Booksellers / Become our Partner", "", dispatch);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader
        crumbs={crumbs}
        title={"Become Our Partner"}
        desc={
          "Unite with us in a shared commitment to excellence, where every book is a testament to the craftsmanship and the enduring power of stories."
        }
      />

      <ServiceVideo
        title={"Let’s Start Publishing Together"}
        videoSrc={video}
        desc={
          "Rare Biblio is an expert in creating leather-bound books that can be personalized and customized are entirely hand-crafted and are distinguished by their high quality, exquisite design, and classic appeal. We are thrilled to invite booksellers like you to become a part of this special alliance. Working with us will enable you to provide your clients with a unique selection of beautifully constructed, leather-bound books that will win them over and add value to their libraries."
        }
      />

      <AboutForPublishers />
      <div className={classes.join}>
        <h2>How to Become Our Partner</h2>
        <p>
          To show your interest in joining us as a partner, get in touch with us
          by sending us a message at info@rarebiblio.com. We'll arrange an
          introductory meeting to talk about how we can best collaborate and
          understand your needs.
        </p>
      </div>

      <Gallery />

      <CustomerReview />

      <FAQ data={allFaq} />
      <div className={classes.contactUs}>
        <ContactUs />
      </div>

      <Footer />
    </div>
  );
};

export default BecomePartner;
