import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../Components/Inputs/Input/Input";
import classes from "./Orders.module.css";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import OrderCard from "./OrderCard/OrderCard";
import OrderDetails from "./OrderDetails/OrderDetails";
import { fetchOrdersData } from "../../../Store/actions/OrdersActions";

const tabData = [
  {
    title: "In Process Orders",
    value: "pending"
  },
  {
    title: "In Transit Orders",
    value: "shipped"
  },
  {
    title: "Delivered Orders",
    value: "delivered"
  },
  {
    title: "Dispute Orders",
    value: "disputed"
  },
];

const Orders = () => {
  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [orderDetails, setOrderDetails] = useState({show: false, data: {}});
  const [orders, setOrders] = useState([]);

  const getOrders = () => {
    fetchOrdersData()
      .then((res) => {
        setOrders(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    getOrders();
  }, []);

  const getOrderData = () => {
      return orders.filter((data) => (data.paymentStatus === "success" &&  data.orderStatus  === activeTab.value)) || []
  }

  return (
    <>
      {!orderDetails.show ? (
        <div>
          <div className={classes.headerSection}>
            <h2>My Orders</h2>
            <div className={classes.filters}>
              <div className={classes.searchBar}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <Input />
              </div>

              <select name="" id="">
                <option value="">Past 6 Months</option>
                <option value="">Past 12 Months</option>
              </select>
            </div>
          </div>

          <div className={classes.tabs}>
            {tabData.map((tabs, idx) => (
              <div
                className={`${classes.tab} ${
                  activeTab.title === tabs.title ? classes.active : ""
                } `}
                onClick={() => setActiveTab(tabs)}
                key={idx}
              >
                {tabs.title}
              </div>
            ))}
          </div>

          {getOrderData()?.map((order) => (
            <div className={classes.orders}
            key={order.id}>
              <OrderCard
                key={order.id}
                activeTab={activeTab.value}
                viewDetails={() => setOrderDetails({show: true, data: order})}
                data={order}
              />
            </div>
          ))}
        </div>
      ) : (
        <OrderDetails data={orderDetails.data} onBack={() => setOrderDetails({show: false, data: {}})} />
      )}
    </>
  );
};

export default Orders;
