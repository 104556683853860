import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./Cart.module.css"
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { updateCartItem, deleteCartItem } from "../../../Store/actions/CartAction"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { urls } from "../../../Routes/Urls"
import { getEnum, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../Util"

const Cart = () => {
    const allColorsData = useSelector(state => state.bookColor.colors);
    const dispatch = useDispatch()
    const history = useHistory()
    const cartItems = useSelector((state) => state.cart.cartItems)

    const handleQtyIncrement = (cartItemId, qty) => {
        updateCartItem(cartItemId, qty + 1, null, dispatch)
    }

    const handleQtyDecrement = (cartItemId, qty) => {
        if (qty > 1) {
            updateCartItem(cartItemId, qty - 1, null, dispatch)
        }
    }

    const handleDeleteItem = (cartItemId) => {
        deleteCartItem(cartItemId, dispatch)
    }

    const getBookImage = (cartItem) => {

        if(cartItem?.book){
            return getRandomImageForNormalBook(cartItem?.book,allColorsData,cartItem?.color,allColorsData?.leatherType,cartItem?.variantType)?.image
        }
        else if(cartItem?.bestSellerBook){
            const link =  getRandomImageForBestSellerBook(cartItem?.bestSellerBook,allColorsData,cartItem?.color,allColorsData?.leatherType,cartItem?.variantType)?.image
            return link
        }
        return ""
    } 

    return (
      <>
        <div className={classes.mainContainer}>
          <div className={classes.itemCount}>
            <h3>{cartItems?.length} Items</h3>
          </div>

          <div className={classes.cartTableWrapper}>
            <table className={classes.cartTable}>
              <thead>
                <tr>
                  <th>Products</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Customization</th>
                  <th colSpan={2}>Sub total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems.map((item) => (
                    <tr
                      key={item.id}
                      className={
                        item?.book?.isDeleted || item?.bestSellerBook?.isDeleted
                          ? classes.unavailableRow
                          : ""
                      }
                    >
                      <td>
                        <div className={classes.product}>
                          <div className={classes.productImage}>
                            {item?.book?.isDeleted ||
                            item?.bestSellerBook?.isDeleted ? (
                              <span className={classes.unavailableText}>
                                Unavailable
                              </span>
                            ) : (
                              <img src={getBookImage(item)} alt="Book" />
                            )}
                          </div>
                          <div className={classes.productDetails}>
                            <h2>
                              {item?.book?.title || item?.bestSellerBook?.title}
                            </h2>
                            <p>
                              Edition: {getEnum(item.variantType)} |{" "}
                              {item?.book
                                ? `Color : ${item.color}`
                                : item?.bestSellerBook
                                ? `Color : ${item.color}`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className={classes.quantity}>
                        {item.isDeleted ? (
                          <span className={classes.unavailableText}>
                            Unavailable
                          </span>
                        ) : (
                          <div className={classes.qtyController}>
                            <p
                              onClick={() =>
                                handleQtyDecrement(item.id, item.quantity)
                              }
                              className={item?.book?.isDeleted || item?.bestSellerBook?.isDeleted ? classes.disabled : ""}
                            >
                              -
                            </p>
                            <h2>{item.quantity}</h2>
                            <p
                              onClick={() =>
                                handleQtyIncrement(item.id, item.quantity)
                              }
                              className={item?.book?.isDeleted || item?.bestSellerBook?.isDeleted ? classes.disabled : ""}
                            >
                              +
                            </p>
                          </div>
                        )}
                      </td>
                      <td className={classes.price}>
                        <p>
                          {item.isDeleted
                            ? "—"
                            : `$${item.itemPrice.toFixed(2)}`}
                        </p>
                      </td>
                      <td className={classes.customization}>
                        <p>
                          {item.isDeleted
                            ? "—"
                            : `$${item.customizationCharges}`}
                        </p>
                      </td>
                      <td className={classes.subTotal}>
                        <p>
                          {item.isDeleted
                            ? "—"
                            : `$${(item.itemPrice * item.quantity).toFixed(2)}`}
                        </p>
                      </td>
                      <td className={classes.deleteItem}>
                        {!item.isDeleted && (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => handleDeleteItem(item.id)}
                            className={classes.deleteIcon}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={classes.backBtn}
          onClick={() => history.push(urls.LISTING)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className={classes.leftArrow} />
          <p>Return To Shop</p>
        </div>
      </>
    );
}

export default Cart
