import React from 'react';
import classes from './PreviewCard.module.css';

const PreviewCard = ({ image, altText, isActive, handleClick, design }) => {
  const activeClass = typeof isActive === 'function' 
    ? isActive(design) 
      ? classes.activePreviewCard 
      : '' 
    : isActive 
      ? classes.activePreviewCard 
      : '';

  return (
    <div
      className={`${classes.previewCard} ${activeClass}`}
      onClick={() => handleClick(design)}
    >
      <img src={image} alt={altText || 'Preview'} />
    </div>
  );
};


export default PreviewCard;
