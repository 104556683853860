import classes from "./InnerBorder.module.css";
import Slider from "react-slick";
import { useState, useEffect, useContext } from "react";
import { fabric } from "react-fabric-page";
import { useFabricCanvas } from "react-fabric-page";
import { addImageToCanvas } from "../../../../objectUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faPlusCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import {
  getBorderDesigns,
} from "../../../../Store/actions/ColorsAction";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { useSelector } from "react-redux";

const innerBorderTabs = [
  {
    title: "None",
    value: "none",
  },
  {
    title: "Embossing",
    value: "embossing",
  },
  {
    title: "Foiling",
    value: "foiling",
  },
];

const getBounds = (canvas) => {
  const padding = 0;
  const shiftUp = 0;
  const layer = canvas?.getObjects()?.find((o) => o.id === "contents");
  if (layer) {
    const textObjects = layer._objects.filter(
      (obj) => obj.allowedLeft && obj.allowedTop
    );

    if (textObjects.length > 0) {
      const minLeft = Math.min(
        ...textObjects.map((obj) => obj.allowedLeft[0][0])
      );
      const maxLeft = Math.max(
        ...textObjects.map((obj) => obj.allowedLeft[0][1])
      );
      const minTop = Math.min(
        ...textObjects.map((obj) => obj.allowedTop[0][0])
      );
      const maxTop = Math.max(
        ...textObjects.map((obj) => obj.allowedTop[0][1])
      );

      return {
        left: minLeft,
        top: minTop - padding - shiftUp,
        width: maxLeft - minLeft,
        height: maxTop - minTop + 2 * padding,
      };
    }
  }
  return null;
};

const changeCoverBorder = (border,canvas,bookSize) => {
  let imageUrl = "";
  if (bookSize === "F1") {
    imageUrl = border?.designImage1;
  }
  if (bookSize === "F2") {
    imageUrl = border?.designImage2;
  }
  if (bookSize === "F3") {
    imageUrl = border?.designImage3;
  }
  if (bookSize === "F4") {
    imageUrl = border?.designImage4;
  }

  if(imageUrl){
    return addImageToCanvas({ filepath: imageUrl, fileType: "image" }, 1, () => getBounds(canvas),null,false,false)
      .then((img) => {
        const layer = canvas?.getObjects()?.find((o) => o?.id === "decoration");
        if (layer) {
          layer._objects = layer._objects.filter(o => o.id !== "innerBorder")
          layer.add(img);
          img.id = "innerBorder";
          img.hasControls = false;
          layer.dirty = true;
          canvas.renderAll();
        }
      })
      .catch((error) => {
        
      });
  }
};

const InnerBorder = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const {
    bookSize,
    selectedInnerBorder,
    setSelectedInnerBorder,
    selectedInnerBorderType,
    setSelectedInnerBorderType,
    InnerBorderFoilingColor, 
    setInnerBorderFoilingColor,
    bookVariant,allFoilingColors,
    userInnerBorder,setUserInnerBorder,
    selectedDesigns
  } = useContext(CustomizationContext);

  const { isMediumScreen } = useScreenSize();

  const [allBorderDesigns, setAllBorderDesigns] = useState([]);
  const [borderDesigns, setBorderDesigns] = useState([]);
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [FoilingColors, setFoilingColor] = useState([]);

  const canvas = useFabricCanvas();

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const blob = new Blob([file], { type: file.type });
      const url = window.URL.createObjectURL(blob);
      setUserInnerBorder(url); // Set the file to state
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const settings = {
    slidesToShow: borderDesigns?.length > 1 ?  2.5 : 1,
    swipeToSlide: true,
    infinite:false,
    centerPadding: "10px",
    }


  // const getColor = async () => {
  //   let filter = {
  //     // offset: (currPageNo - 1) * pageSize,
  //     // limit: pageSize,
  //   };

  //   try {
  //     const res = await getFoilingColor(filter);
  //     if (res.status === 200) {
  //       setAllFoilingColor(res.data?.data);
  //     }
  //   } catch (err) {
  //     
  //   }
  // };

  // useEffect(() => {
  //   getColor();
  // }, []);

  useEffect(() => {
    const allowedFoilingColors = allFoilingColors?.filter(
      (color) => color.disabled === false
    );
    setFoilingColor(allowedFoilingColors);
  }, [selectedInnerBorderType]);


  const fetchBorderDesigns = async () => {
    try {
      const res = await getBorderDesigns({});
      if (res.status === 200) {
        setAllBorderDesigns(res.data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchBorderDesigns();
  }, []);

  useEffect(() => {
    const allowedBorders = allBorderDesigns?.filter(
      (border) =>
        border.borderType === "inner" &&
        border.borderStyle === selectedInnerBorderType &&
        border.variantType === bookVariant &&
        border.disabled === false
    );
    setBorderDesigns(allowedBorders);
  }, [selectedInnerBorderType]);

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false);
    } else {
      toggleDropdown(true);
    }
  };

  const changeFoilingColor = () => {
    if (canvas) {
      const layer = canvas?.getObjects()?.find((o) => o.id === "decoration");
      if (layer) {
        const activeObject = layer?._objects?.filter((o) => o.id === "innerBorder")?.[0];
        if (activeObject && InnerBorderFoilingColor) {
          activeObject.filters[0] = new fabric.Image.filters.PatternColor({
            color: InnerBorderFoilingColor?.colorValue,
            convert: true,
          });
          activeObject.applyFilters();
          layer.dirty = true;
        }
      }
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if(selectedInnerBorderType === "foiling"){
      changeFoilingColor()
    }
  },[InnerBorderFoilingColor,selectedInnerBorder,selectedInnerBorderType])

  useEffect(() => {
    if (selectedInnerBorderType === "none") {
      setSelectedInnerBorderType("none");
      setSelectedInnerBorder(null);
      setInnerBorderFoilingColor(null);
      const layer = canvas?.getObjects()?.find((o) => o.id === "decoration");
      if (layer) {
        layer._objects = layer._objects.filter((o) => o.id !== "innerBorder");
        layer.dirty = true;
        canvas.renderAll();
      }
    } else { 
      if(selectedDesigns?.customization?.innerBorder?.designId) {
        const selectedInnerDesign = borderDesigns?.find(border => border?.id === selectedDesigns?.customization?.innerBorder?.designId);
        if(selectedInnerDesign) {
          (async function (params) {
              await changeCoverBorder(selectedInnerDesign,canvas,bookSize);
              setSelectedInnerBorder(selectedInnerDesign);
      
            if (selectedInnerBorderType === "foiling" && FoilingColors.length > 0) {
              if(selectedDesigns?.customization?.innerBorder?.foilingColor) {
                const foilColor = FoilingColors?.find((color) => color?.colorName === selectedDesigns?.customization?.innerBorder?.foilingColor);
                if(foilColor) {
                  setInnerBorderFoilingColor(foilColor);
                  changeFoilingColor()
                } else {
                  setInnerBorderFoilingColor(FoilingColors[0]);
                  changeFoilingColor()
                }
              }
              setInnerBorderFoilingColor(FoilingColors[0]);
              changeFoilingColor()
            }
          })()
      } else {
        (async function (params) {
          if (borderDesigns.length > 0) {
            await changeCoverBorder(borderDesigns[0],canvas,bookSize);
            setSelectedInnerBorder(borderDesigns[0]);
          }
    
          if (selectedInnerBorderType === "foiling" && FoilingColors.length > 0) {
            setInnerBorderFoilingColor(FoilingColors[0]);
            changeFoilingColor()
          }
        })()
      }} else {
        (async function (params) {
          if (borderDesigns.length > 0) {
            await changeCoverBorder(borderDesigns[0],canvas,bookSize);
            setSelectedInnerBorder(borderDesigns[0]);
          }
    
          if (selectedInnerBorderType === "foiling" && FoilingColors.length > 0) {
            setInnerBorderFoilingColor(FoilingColors[0]);
            changeFoilingColor()
          }
        })()
      }
     
    }

  }, [selectedInnerBorderType,FoilingColors,selectedDesigns]);

  const handleChangeBorder = (border) => {
    if(selectedInnerBorder?.paletteImage !== border?.paletteImage ){
        // setSelectedInnerBorder(border);
        // changeCoverBorder(border,canvas,bookSize);
      (async function(params){
        await changeCoverBorder(border,canvas,bookSize);
        setSelectedInnerBorder(border);
      })()
    }
  };

  const handleColorChange = (color,e) => {
    e.stopPropagation()
    setInnerBorderFoilingColor(color)
}

useEffect(() => {
  if(selectedDesigns?.customization?.innerBorder?.style){
    setSelectedInnerBorderType(selectedDesigns?.customization?.innerBorder?.style);
  }
},[selectedDesigns,allBorderDesigns])


  return (
    <div>
      {isMediumScreen ? (
            <div onClick={(e) => e.stopPropagation()}>
            <div className={classes.innerBorderTypes}>
              <h2>Select type of inner border</h2>
              <div className={classes.innerBorderTypePreview}>
                <div className={classes.innerBorderType}>
                  {innerBorderTabs.map((option) => (
                    <OptionCapsules
                      option={option.title}
                      active={selectedInnerBorderType === option.value}
                      onClick={() => setSelectedInnerBorderType(option.value)}
                      key={option.title}
                    />
                  ))}
                </div>

                {selectedInnerBorderType !== "none" && (
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                    {borderDesigns?.map((border) => (
                      <div
                        key={border?.designName}
                        className={`${classes.previewCard} ${
                          selectedInnerBorder?.designName ===
                          border?.designName
                            ? classes.activePreviewCard
                            : ""
                        }`}
                        onClick={() => handleChangeBorder(border)}
                      >
                        <img src={border?.paletteImage} alt="" />
                      </div>
                    ))}
                    </Slider>
                  </div>
                )}

                {selectedInnerBorderType === "foiling" && borderDesigns?.length > 0 && (
                  <div className={classes.foilingColorSection}>
                    <h2>Select Foiling Color</h2>
                    <ColorSelector
                      availableColors={FoilingColors}
                      selectedColor={InnerBorderFoilingColor}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                )}
              </div>

              <div className={classes.uploadBtn} onClick={handleClick}>
                <div className={classes.addLogo}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classes.addLogoIcon}
                  />
                  <p>Add your own design</p>
                </div>
                <div className={classes.logoPrice}>+${customizationPricing?.superDeluxeInnerBorderPrice}</div>
              </div>

              {/* Hidden file input to select an image */}
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect} // Trigger on file selection
              />
              {/* Optional: Image preview (if image is selected) */}
              {userInnerBorder && (
                <div className={classes.imagePreview}>
                  <img
                    src={userInnerBorder} // Convert file to URL for preview
                    alt="Selected Design"
                  />
                  <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserInnerBorder(null)} />
                </div>
              )}
              <p className={classes.disclaimer}>
                Disclaimer : uploaded design won’t be available for live
                preview
              </p>
            </div>
          </div>
      ) : (
        <div
          className={classes.innerBorder}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.innerBorderHeader}>
            <div className={classes.headerText}>
              <h2>Inner Border</h2>
              <ToolTip tooltipText={"Add Inner Border"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Inner Border" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Inner Border" ? (
            <div onClick={(e) => e.stopPropagation()}>
              <div className={classes.innerBorderTypes}>
                <div className={classes.innerBorderTypePreview}>
                  <div className={classes.innerBorderType}>
                    {innerBorderTabs.map((option) => (
                      <OptionCapsules
                        key={option.title}
                        option={option.title}
                        active={selectedInnerBorderType === option.value}
                        onClick={() => setSelectedInnerBorderType(option.value)}
                      />
                    ))}
                  </div>

                  {selectedInnerBorderType !== "none" && (
                    <div className={classes.previewContainer}>
                      <Slider {...settings}>
                      {borderDesigns?.map((border) => (
                        <div
                          key={border?.designName}
                          className={`${classes.previewCard} ${
                            selectedInnerBorder?.designName ===
                            border?.designName
                              ? classes.activePreviewCard
                              : ""
                          }`}
                          onClick={() => handleChangeBorder(border)}
                        >
                          <img src={border?.paletteImage} alt="" />
                        </div>
                      ))}
                      </Slider>
                    </div>
                  )}

                  {selectedInnerBorderType === "foiling" && borderDesigns?.length > 0 && (
                    <div className={classes.foilingColorSection}>
                      <h2>Select Foiling Color</h2>
                      <ColorSelector
                        availableColors={FoilingColors}
                        selectedColor={InnerBorderFoilingColor}
                        handleColorChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Add your own design</p>
                  </div>
                  <div className={classes.logoPrice}>+${customizationPricing?.superDeluxeInnerBorderPrice}</div>
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {userInnerBorder && (
                  <div className={classes.imagePreview}>
                    <img
                      src={userInnerBorder} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserInnerBorder(null)} />
                  </div>
                )}
                <p className={classes.disclaimer}>
                  Disclaimer : uploaded design won’t be available for live
                  preview
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default InnerBorder;
