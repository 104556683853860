import classes from "./OrderCard.module.css";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../../../../Components/Buttons/ButtonLight/ButtonLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import DisputeOrderForm from "../DisputeOrderForm/DisputeOrderForm";
import { useState } from "react";
import ReviewModal from "../../../../Components/ReviewModal/ReviewModal";
import { getClasses, getFormattedDate, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../../Util";
import { useSelector } from "react-redux";

const OrderCard = ({ viewDetails, activeTab, data }) => {
  const allColorsData = useSelector(state => state.bookColor.colors);
  const [disputeModal, setDisputeModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedBook,setSelectedBook] = useState(null)

  const getVariantType = (type) => {
    switch (type) {
      case "deluxe":
        return "Deluxe";
      case "superDeluxe":
        return "Super Deluxe";
      default:
        break;
    }
  };

  const getOrderStatusOfItem = (itemId) => {
    let status = "";
    data?.subOrders?.forEach((sub) => {
      if (sub?.cartItemIds.includes(itemId)) {
        status = sub?.orderStatus;
      }
    });

    if (status) {
      return status;
    } else {
      return data?.orderStatus;
    }
  };

  const getSubOrders = (itemId) => {
    let subOrder = {};
    data?.subOrders?.forEach((sub) => {
      if (sub?.cartItemIds.includes(itemId)) {
        subOrder = sub;
      }
    });
    if (subOrder) {
      return subOrder;
    }
  };

  const openReviewModal = (bookData) => {
    const book = bookData?.book ? bookData?.book : bookData?.bestSellerBook;
    book.imageUrl = bookData?.book ? getRandomImageForNormalBook(bookData.book, allColorsData, bookData.color, bookData.leatherType, bookData.variantType)?.image : getRandomImageForBestSellerBook(bookData.bestSellerBook, allColorsData, bookData.color, bookData.leatherType, bookData.variantType)?.image
    setSelectedBook(book)
    setReviewModal(true)
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerSection}>
        <div className={classes.headerItem}>
          <h2>Order Placed</h2>
          <p>{getFormattedDate(data?.createdAt)}</p>
        </div>
        <div className={classes.headerItem}>
          <h2>Amount Paid</h2>
          <p>${Number(data.payableAmount).toFixed(2)}</p>
        </div>
        <div className={classes.headerItem}>
          <h2>Order No.</h2>
          <p>{data?.orderNo}</p>
        </div>
        <div className={classes.actions}>
          {(activeTab === "delivered" || activeTab === "disputed") && (
            <div className={`${classes.headerItem} ${classes.invoice}`}>
              <h2>Download Invoice</h2>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faDownload}
              />
            </div>
          )}
        </div>
      </div>

      <div className={classes.mainBody}>
        {data?.cartItems?.map((item, idx) => (
          <div key={idx} className={classes.itemContainer}>
            <div className={classes.orderDetails}>
              <h2>Expected deliver : {getFormattedDate(item?.shippingCharges?.find((date) => date.shippingSpeed === item?.shippingSpeed)?.shipByDate)}</h2>
            </div>
            {activeTab === "shipped" && (
              <div className={classes.subOrder}>
                {getOrderStatusOfItem(item.id) === "shipped" && (
                  <div className={getClasses(classes.status, classes.transit)}>
                    <p>In Transit</p>
                  </div>
                )}
                {getOrderStatusOfItem(item.id) === "delivered" && (
                  <div
                    className={getClasses(classes.status, classes.completed)}
                  >
                    <p className={classes.delivered}>Delivered</p>
                  </div>
                )}
                {getSubOrders(item.id)?.trackingNo && (
                  <div className={classes.trackingName}>
                    Tracking No:{" "}
                    <span>{getSubOrders(item.id)?.trackingNo}</span>
                  </div>
                )}
                {getSubOrders(item.id)?.courierName && (
                  <div className={classes.trackingName}>
                    Courier Name:{" "}
                    <span>{getSubOrders(item.id)?.courierName}</span>
                  </div>
                )}
              </div>
            )}
            <div className={classes.orderItem}>
              <div className={classes.bookDetailsContainer}>
                <div className={classes.bookDetails}>
                  <div className={classes.bookImage}>
                    <img
                      src={
                        item?.book
                          ? getRandomImageForNormalBook(
                              item.book,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                          : getRandomImageForBestSellerBook(
                              item.bestSellerBook,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                      }
                      alt=""
                    />
                  </div>
                  <div className={classes.bookData}>
                    <h2>{item?.book?.title || item?.bestSellerBook?.title}</h2>
                    <h3>{getVariantType(item.variantType)}</h3>
                    <p>Qty: {item?.quantity}</p>
                    <p>
                      Ship To:{" "}
                      <span className={classes.address}>{`${
                        item?.shippingAddress?.addressLine1
                      } ${item?.shippingAddress?.addressLine2 || ""}, ${
                        item?.shippingAddress?.city
                      }, ${item?.shippingAddress?.state}, ${
                        item?.shippingAddress?.country
                      }, ${item?.shippingAddress?.zipCode}`}</span>
                    </p>
                  </div>
                </div>
                <p className={classes.price}>
                  $ {Number(item?.totalAmount).toFixed(2)}
                </p>
              </div>

              {idx === 0 && (
                <div className={classes.btnContainer}>
                  <PrimaryButton onClick={viewDetails}>
                    {activeTab === "shipped" ? "Track Order" : "View Order"}
                  </PrimaryButton>
                  {activeTab !== "disputed" && (
                    <ButtonLight onClick={() => setDisputeModal(true)}>
                      Dispute Order
                    </ButtonLight>
                  )}
                </div>
              )}
            </div>
            <p
              className={classes.writeReview}
              onClick={() => 
                openReviewModal(item)
            }
            >
              Write a Review
            </p>
          </div>
        ))}
      </div>
      {disputeModal && (
        <DisputeOrderForm
          data={data}
          activeTab={activeTab}
          onClose={() => setDisputeModal(false)}
        />
      )}
      {reviewModal && <ReviewModal bookData={selectedBook} onClose={() => {setSelectedBook(null);setReviewModal(false)}} />}
    </div>
  );
};

export default OrderCard;
