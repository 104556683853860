import classes from "./Blogs.module.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import BlogCard from "../Body/BlogSection/BlogCard/BlogCard";

import {
  fetchAllBlogs,
  fetchBlogsByCategory,
  getBlogsCount,
} from "../../Store/actions/BlogsAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { urls } from "../../Routes/Urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Header from "../Header/Header";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import SearchComponent from "./SearchComponent/SearchComponent";
import BlogCardLarge from "./BlogCardLarge/BlogCardLarge";
import BlogPostCard from "../../Components/Card/BlogPostCard/BlogPostCard";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import PaginatedBlogs from "./PaginatedBlogs/PaginatedBlogs";
import useScreenSize from "../../Hooks/UseScreenSize";

function NextArrow(props) {
  const {onClick } = props;
  return (
    <div className={classes.leftArrow} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={classes.RightArrow} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}
const person = "/Assets/Images/person.svg";

const Blogs = () => {
  const dispatch = useDispatch();
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Blogs", path: "/blogs" },
  ];
  const {screenSize} = useScreenSize()
  const history = useHistory();
  const blogData = useSelector((state) => state.blogs?.allBlogs);
  const blogCategories = useSelector((state) => state.blogs?.categories);

  const [searchedBlogs, setSearchedBlogs] = useState(null);
  const [searchQuery, setSerachQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;
  const [totalBlogsCount, setTotalBlogsCount] = useState(0); // To store the total number of blogs

  useEffect(() => {
    // Fetch the total blog count for pagination
    getBlogsCount("").then(count => setTotalBlogsCount(count));

    // Fetch blogs for the current page
    fetchAllBlogs(dispatch);
}, [currentPage, blogsPerPage, dispatch]);

  const [offset, setOffset] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const visibleTagsCount = screenSize > 660 ? 4 : 3; // Display 7 tags at a time

  const handleNextClick = () => {
    if (startIndex+visibleTagsCount <= blogCategories?.length) {
      window.requestAnimationFrame(() => {
        setStartIndex((prevIndex) =>  prevIndex + 2);
      });
    } 
  };

  const handlePrevClick = () => {
    if (startIndex > 0) {
      window.requestAnimationFrame(() => {
        setStartIndex((prevIndex) => prevIndex - 2);
      });
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchBlogsByCategory(category).then(setSearchedBlogs);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    infinite : true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: false,
    centerMode:false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
    ]
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // if (blogData || blogData.length === 0) {
  //     return <Loader />
  // }

  const handleBlogRedirect = (slug) => {
    history.push(`${urls.VIEWBLOG}/${slug}`);
  };

  const handlePageReset = () => {
    fetchAllBlogs();
    setSearchedBlogs(null);
    setSelectedCategory("");
    setSerachQuery("");
  };

  const scrollRef = useScrollToTop();

  return (
    <div className={classes.container} ref={scrollRef}>
      <Header />

      <BannerHeader
        crumbs={crumbs}
        title={"Uncover the Stories Behind the Rarest Books"}
        desc={"Insights, Reviews, and News from the World of Antiquarian Books"}
      />
      <div className={classes.nav}>
        <div className={classes.tagsContainer}>
          <h2>Tags:</h2>
          <div className={classes.tagScrollWrapper}>
            <button className={classes.scrollButton} onClick={handlePrevClick}>
              <FontAwesomeIcon
                className={classes.controlBtn}
                icon={faAngleLeft}
              />
            </button>
            <div className={classes.tagListWrapper}>
              <div
                className={classes.tagList}
                style={{ transform: `translateX(${offset}px)` }}
              >
                {blogCategories
                  ?.slice(startIndex, startIndex + visibleTagsCount)
                  ?.map((tag, index) => (
                    <div
                      className={
                        selectedCategory === tag
                          ? classes.activeTag
                          : classes.tag
                      }
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCategoryClick(tag)}
                    >
                      <p>{tag}</p>
                    </div>
                  ))}
              </div>
            </div>
            <button className={classes.scrollButton} onClick={handleNextClick}>
              <FontAwesomeIcon
                className={classes.controlBtn}
                icon={faAngleRight}
              />
            </button>
          </div>
        </div>

        <div className={classes.search}>
          <SearchComponent
            searchQuery={searchQuery}
            setSearchQuery={setSerachQuery}
            setBlogdata={setSearchedBlogs}
          />
        </div>
      </div>

      <div className={classes.mainSection}>
        {searchedBlogs === null ? (
          <>
            <div className={classes.blogSection}>
              <div className={classes.bigCard}>
                <BlogCardLarge
                  onClick={() => handleBlogRedirect(blogData?.[0]?.urlSlug)}
                  authorName={blogData?.[0]?.authorName}
                  countryCode={blogData?.[0]?.countryCode}
                  title={blogData?.[0]?.title}
                  image={blogData?.[0]?.bannerImagePath}
                  desc={blogData?.[0]?.description}
                  category={blogData?.[0]?.category}
                  className={classes.blogCardLarge}
                  date={formatDate(blogData?.[0]?.updateTime)}
                />
              </div>
              <div className={classes.smallCard}>
                {blogData?.slice(1, 4).map((blogs, index) => (
                  <div key={index} className={classes.smallCards}>
                    <BlogPostCard
                      onClick={() => handleBlogRedirect(blogs?.urlSlug)}
                      label={blogs.category}
                      countryCode={blogs.countryCode}
                      authorRole={"Writer"}
                      author={blogs.authorName}
                      date={formatDate(blogs.updateTime)}
                      description={blogs.description}
                      title={blogs.title}
                      readTime={blogs.readTime}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.banner}>
              <h2>Want to feature your blog?</h2>
              <div className={classes.bannerBtn}>
                <SecondaryButton onClick={() => history.push(urls.CONTACTUS)}>
                  Get Your Blogs Featured
                </SecondaryButton>
              </div>
            </div>

            <div className={classes.trending}>
              <h2 className={classes.trendingHeader}>Trending Blogs</h2>
              <div className={classes.sliderContainer}>
                <Slider {...settings}>
                  {blogData
                    ?.filter((blog) => blog.trending === true)
                    ?.map((data, index) => (
                      <BlogCard
                        key={index}
                        index={index}
                        className={classes.trendingBlogCard}
                        image={data?.bannerImagePath}
                        countryCode={data?.countryCode}
                        title={data?.title}
                        authorImg={person}
                        authorName={data?.authorName}
                        date={formatDate(data?.updateTime)}
                        category={data?.category}
                        onClick={() => handleBlogRedirect(data?.urlSlug)}
                      />
                    ))}
                </Slider>
              </div>
            </div>

            <PaginatedBlogs />
          </>
        ) : (
          <div className={classes.searchedBlogs}>
            <div className={classes.searchHeader} onClick={handlePageReset}>
              <FontAwesomeIcon className={classes.backBtn} icon={faAngleLeft} />
              <h2>Search Results : {searchQuery || selectedCategory}</h2>
            </div>
            <div className={classes.blogs}>
              {searchedBlogs?.map((data, index) => (
                <BlogCard
                  key={index}
                  onClick={() => handleBlogRedirect(data.urlSlug)}
                  countryCode={data?.countryCode}
                  className={classes.card}
                  index={index}
                  image={data?.bannerImagePath}
                  title={data?.title}
                  authorName={data?.authorName}
                  date={formatDate(data?.updateTime)}
                  category={data?.category}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
