import React, { useEffect, useState } from 'react';
import classes from "./SearchComponent.module.css"
import Input from '../../../Components/Inputs/Input/Input';
import { getSuggestionsForBestSellerBooks } from '../../../Store/actions/BookAction';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const SearchIcon = "/Assets/Icons/icon-search.svg"

const SearchComponent = ({query, onChange,setQuery,minYear,maxYear,selectedGenres, onSearch}) => {

  const handleSearchOnKeyPress = (e) => {
    if(e.key === "Enter"){
      onSearch();
    }
  }

  const [allRecommendations,setAllRecommendations] = useState([]);

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setQuery(words.join(" "));
    } else {
      setQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };

  function getSet(obj) {
    return Array.from(
      Object.keys(obj).reduce((prev, curr) => {
        obj[curr].forEach(e => {
          prev.add(e.value);
        });
        return prev;
      }, new Set())
    );
  }

  useEffect(()=>{
    getSuggestionsForBestSellerBooks("all", query, minYear, maxYear, selectedGenres, false).then((data) => {
      setAllRecommendations(getSet(data));
    })
  },[])
  
  useEffect(() => {
    if(recommendationClicked) {
      onSearch();
      setRecommendationClicked(false);
    }
  },[recommendationClicked])
  
  useEffect(() => {
    if (query) {
      const pattern = /\s\S+$/;
      const match = query.match(pattern);
      if (match) {
        const modifiedQuery = query.replace(pattern, "").trim(); // "the a" -> "the"
        getSuggestionsForBestSellerBooks("all", modifiedQuery, minYear, maxYear, selectedGenres, true);
      }
    }
  }, [query]);
  
  useEffect(() => {
    if (query) {
      const words = query.trim().split(/\s+/);
      const searchWord = words.length === 1 ? query.toLowerCase() : words[words.length - 1].toLowerCase();
      setSearchRecommedations(
        allRecommendations.filter(word => word.toLowerCase().startsWith(searchWord))
      );
    } else {
      setSearchRecommedations([]);
    }
  }, [query, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };

  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false), 1000);
    setBlurTimeout(timeout);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchContainer}>
        <img className={classes.searchIcon} src={SearchIcon} alt="" />
        <Input
          type="text"
          className={classes.searchInput}
          placeholder="Search via Author, Book name"
          value={query}
          onChange={onChange}
          onKeyDown={handleSearchOnKeyPress}
          onFocus={handleFocus}  
          onBlur={handleBlur} 
        />
        <button className={classes.searchButton} onClick={onSearch}>
          Search Now
        </button>
      </div>
      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
        {searchRecommendations.slice(0,10).map((recommendation, index) => (
          <div
            key={index}
            className={classes.dropdownItem}
            onClick={() => handleRecommendationClick(recommendation)}
          >
            <FontAwesomeIcon icon={faSearchengin}/>
            {recommendation}
          </div>
        ))}
        </div>
      )}

    </div>
  );
};

export default SearchComponent;
