import classes from "./DedicatedPage.module.css";

import Accordion from "../../Components/Accordion/Accordion";

import { useState, useContext } from "react";
import AddText from "./AddText/AddText";
import AddImage from "./AddImage/AddImage";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";
import { useSelector } from "react-redux";
// import { useFabricCanvas } from "react-fabric-page";

const dedicationPageTabs = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

const DedicatedPage = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const { wantDedicationPage, setWantDedicationPage } =
    useContext(CustomizationContext);
    const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [addOption, setAddOption] = useState("text");
  const { isMediumScreen } = useScreenSize();
  // const [isCanvasReady,setIsCanvasReady] = useState(false);

  // const canvas = useFabricCanvas()

  return (
    <div>
      {isMediumScreen ? (
        <div
          className={classes.selectColor}
          onClick={(e) => e.stopPropagation()}
        >
          <h2>Select Endpaper for your book</h2>

          <div className={classes.tabSection}>
            <div
              className={addOption === "text" ? classes.tabActive : classes.tab}
              onClick={() => setAddOption("text")}
            >
              <h2>Add Text</h2>
            </div>
            <div
              className={
                addOption === "image" ? classes.tabActive : classes.tab
              }
              onClick={() => setAddOption("image")}
            >
              <h2>Add Image</h2>
            </div>
          </div>

          {addOption === "text" ? <AddText /> : <AddImage />}
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <Accordion
            dropdownName={"Dedication Page"}
            onClick={onClick}
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            currentDropdown={currentDropdown}
            toolText={"Addd Custom text/image to endpaper"}
            title={"Dedication Page"}
          />

          {dropdownOpen && currentDropdown === "Dedication Page" && (
            <div
              className={classes.selectColor}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={classes.dedicationPageOptions}>
                {dedicationPageTabs.map((option) => (
                  <OptionCapsules
                    option={option.name}
                    active={wantDedicationPage === option.value}
                    onClick={() => setWantDedicationPage(option.value)}
                    key={option.name}
                  />
                ))}
              </div>

              {wantDedicationPage === true ? (
                <div>
                  <div className={classes.price}>
                    <p>Any customization will cost additional</p>
                    <h2>+${customizationPricing?.dedicationPagePrice}</h2>
                  </div>

                  <div className={classes.tabSection}>
                    <div
                      className={
                        addOption === "text" ? classes.tabActive : classes.tab
                      }
                      onClick={() => setAddOption("text")}
                    >
                      <h2>Add Text</h2>
                    </div>
                    <div
                      className={
                        addOption === "image" ? classes.tabActive : classes.tab
                      }
                      onClick={() => setAddOption("image")}
                    >
                      <h2>Add Image</h2>
                    </div>
                  </div>

                  {addOption === "text" ? <AddText /> : <AddImage />}
                </div>
              ) : (
                <div className={classes.noPaper}>
                  <p>No Endpaper Added</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DedicatedPage;
