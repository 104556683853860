import { useSelector } from "react-redux";
import classes from "./BillingAddress.module.css";
import AddressCard from "../AddressCard/AddressCard";
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight";
import { useState } from "react";
import Modal from "../../../Components/Modal/Modal";
import AddressForm from "../../MyAccount/ManageAddress/AddressForm/AddressForm";

const BillingAddress = ({selectedAddress, setSelectedAddress, shippingAddress }) => {
    const currentUser = useSelector((state) => state.user.currentUser);
    const userBillingAddresses = currentUser?.billingAddresses || [];

    const [modalOpen, setModalOpen] = useState(false)
    return (

        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>2 | Billing Address</h2>
                <p>Select Billing address</p>
            </div>

            {/* <div className={classes.radioContainer}>
                <input
                    type="radio"
                    name="address"
                    id="same-as-shipping"
                    onClick={() => setSelectedAddress(shippingAddress)}
                />
                <label htmlFor="same-as-shipping" className={classes.addressLabel}>
                    <strong>Same as Shipping Address</strong>
                </label>
            </div> */}

            {userBillingAddresses?.map((address) => (
                <AddressCard
                    key={address.addressId}
                    address={address}
                    onSelect={setSelectedAddress}
                    isSelected={selectedAddress?.addressId === address.addressId}
                    type="billing"
                />
            ))}

            <div className={classes.btnContainer}>

                {userBillingAddresses.length === 0 && <ButtonLight onClick={() => setModalOpen(true)}>Add Billing Address</ButtonLight>}

                {modalOpen && (
                    <Modal title={"Add Address"} onClose={() => setModalOpen(false)}>
                        <AddressForm
                            type="billing"
                            onClose={() => setModalOpen(false)}
                        />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default BillingAddress;
