import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./OrderDetails.module.css"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Cart from "./Cart/Cart"
import { getFormattedDate } from "../../../../Util"

const OrderDetails = ({data, onBack}) => {
    const [activeTab,setActiveTab] = useState(0);
    const totalProductsQuantity = data.cartItems.reduce((acc, value) => {
        return acc + value.quantity;
    }, 0);

    const uniqueAddresses = data.cartItems
    .map((item) => item.shippingAddress)
    .filter((address, index, self) =>
      index === self.findIndex((a) => 
        a.street === address.street && 
        a.city === address.city &&
        a.zipCode === address.zipCode
      )
    );

  
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <FontAwesomeIcon className={classes.backBtn} icon={faAngleLeft} onClick={onBack}/>
                <h2 style={{cursor:"pointer"}} onClick={onBack}>Order Details</h2>
            </div>
            <div className={classes.orders}>
                <div className={classes.orderSummary}>
                    <p>Order Number</p>
                    <h2>{data?.orderNo}</h2>
                    <div className={classes.addressDetails}>
                        <p>{totalProductsQuantity} products</p>
                        <p>Order Placed : {getFormattedDate(data?.createdAt)}</p>
                        {/* <p>Ship to : {`${shippingAddress?.addressLine1}, ${shippingAddress?.city}, ${shippingAddress?.state}, ${shippingAddress?.country}, ${shippingAddress?.zipCode}`}</p> */}
                    </div>
                </div>
                <h2>${Number(data?.payableAmount).toFixed(2)}</h2>
            </div>

            <div className={classes.tabs}>
                {uniqueAddresses?.map((tab,index) => (
                    <div key={index} className={`${classes.tab} ${activeTab === index ? classes.activeTab : ""} `} onClick={() => setActiveTab(index)}>
                        {`Shipment ${index+1}`}
                    </div>
                ))}
            </div>
            <Cart cartItems={data?.cartItems?.filter((item) => item?.shippingAddress?.addressId === uniqueAddresses?.[activeTab]?.addressId)}/>
            <div className={classes.cardSection}>
                <div className={classes.card}>
                    <h2>Shipping Address</h2>
                    <h3>{uniqueAddresses?.[activeTab]?.fullName}</h3>
                    <p>{uniqueAddresses?.[activeTab]?.phoneNo}</p>
                    <p>{`${uniqueAddresses?.[activeTab]?.addressLine1}, ${uniqueAddresses?.[activeTab]?.city}, ${uniqueAddresses?.[activeTab]?.state}, ${uniqueAddresses?.[activeTab]?.country}, ${uniqueAddresses?.[activeTab]?.zipCode}`}</p>
                </div>
                <div className={classes.card}>
                    <h2>Billing Address</h2>
                    <h3>{data?.billingAddress?.fullName}</h3>
                    <p>{data?.billingAddress?.phoneNo}</p>
                    <p>{`${data?.billingAddress?.addressLine1}, ${data?.billingAddress?.city}, ${data?.billingAddress?.state}, ${data?.billingAddress?.country}, ${data?.billingAddress?.zipCode}`}</p>
                </div>
                <div className={classes.card}>
                    <h2>Order Summary</h2>
                    <h3>Total Items : {data?.cartItems?.length}</h3>
                    <p>Item(s) Subtotal: ${Number(data?.orderTotal)?.toFixed(2)}
                    {/* <br/>Shipping:  $40.00 */}
                    </p>
                    <h2>Total : ${Number(data?.payableAmount)?.toFixed(2)}</h2>
                </div>
                <div className={classes.card}>
                    <h2>Payment Details</h2>
                    <h3>Payment Status : {data?.paymentStatus}</h3>
                    <p>Payment ID: ${data?.paymentId} </p>
                </div>
            </div>

        </div>
    )
}

export default OrderDetails;