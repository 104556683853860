import classes from "./EditTextSmall.module.css"
import { useState, useContext } from "react";
import { useFabricCanvas } from "react-fabric-page";
import { addTextboxObject } from "../../../../../objectUtils";
import OptionCard from "../../../Components/OptionCard/OptionCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { CustomizationContext } from "../../../Customization";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
const dropdownIcon = "/Assets/Icons/caret-down-solid.svg"

const fontIcon = "/Assets/Icons/customization/fontIcon.svg";
const editTextIcon = "/Assets/Icons/customization/editTextIcon.svg";
const textStyleIcon = "/Assets/Icons/customization/textStyleIcon.svg";

const options = [
   {name : "Font" , icon : fontIcon}, 
    {name : "Text Color", icon : editTextIcon}, 
    {name : "Text Styling" , icon : textStyleIcon}
]

const EditTextSmall = ({ colors }) => {
    const {frontCoverTextColor,setFrontCoverTextColor,frontCoverFontFamily,setFrontCoverFontFamily,fontData,frontCoverFontStyle,setFrontCoverFontStyle} = useContext(CustomizationContext)
    // const [selectedFont, setSelectedFont] = useState(fontData[0]);
    // const [selectedColor, setSelectedColor] = useState(colors[0]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [currentOption, setCurrentOption] = useState("")


    let canvas = useFabricCanvas();
    // const [activeObject, update] = useActiveFabricObject(ActiveTextboxObject);

    const handleColorChange = (color) => {
        setFrontCoverTextColor(color);
    };


    const handleFontChange = (font) => {
        setFrontCoverFontFamily(font);
        setIsDropdownOpen(false);
      };
    

    const handleAddTextBox = () => {
        addTextboxObject(canvas);
    };


    const handleTextStyleChange = (style) => {
        setFrontCoverFontStyle((prevStyle) => {
          const newStyle = { ...prevStyle };
      
          switch (style) {
            case 'italic':
              newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
              break;
            case 'underline':
              newStyle.underline = !newStyle.underline;
              break;
            default:
              break;
          }
      
          return newStyle;
        });
      };
      


    const rednerStep = (option) => {
        if (option === options[0].name) {
            return (
              <div
                className={classes.colorPickerDropdown}
                onClick={(e) => e.stopPropagation()}
              >
                <h3>Select font for front cover</h3>

                <div className={classes.selectColor}>
                  <h2>Font family: {frontCoverFontFamily?.fontFamily}</h2>
                  <div className={classes.customDropdown}>
                    <div
                      className={classes.selectedOption}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <div className={classes.dropdownBar}>
                        <div className={classes.dropdownText}>
                          <span>{frontCoverFontFamily?.fontFamily}</span>
                          <span>
                            {frontCoverFontFamily?.fontPrice > 0 && frontCoverFontFamily?.fontPrice}
                          </span>
                        </div>
                        <div className={classes.dropdownIcon}>
                          <img src={dropdownIcon} alt="" />
                        </div>
                      </div>
                    </div>
                    {isDropdownOpen && (
                      <div className={classes.dropdownOptions}>
                        {fontData.map((font) => (
                          <div
                            key={font.fontFamily}
                            className={classes.option}
                            onClick={() => handleFontChange(font)}
                          >
                            <span>{font?.fontFamily}</span>
                            <span>{font.fontPrice > 0 && `${font?.fontPrice}}`}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className={classes.selectColor}>
                  <h2>Color: {frontCoverTextColor?.colorName}</h2>
                  <ColorSelector
                    availableColors={colors}
                    selectedColor={frontCoverTextColor}
                    handleColorChange={handleColorChange}
                  />
                </div>

                <div className={classes.addTextBox} onClick={handleAddTextBox}>
                  <div className={classes.addText}>
                    <h3>Add Textbox</h3>
                  </div>
                  <p>+$40</p>
                </div> */}
              </div>
            );
        }

        else if (option === options[2].name) {
            return (
                <div className={classes.textStylingOptions}>
                <h3>Select Text Styling</h3>
                <div className={classes.stylingButtons}>
                  <button
                    className={`${classes.stylingButton} ${
                      frontCoverFontStyle?.fontStyle === "italic"
                        ? classes.activeStylingButton
                        : ""
                    }`}
                    onClick={() => handleTextStyleChange("italic")}
                  >
                    <i>I</i>
                  </button>
                  <button
                    className={`${classes.stylingButton} ${
                      frontCoverFontStyle?.underline === true
                        ? classes.activeStylingButton
                        : ""
                    }`}
                    onClick={() => handleTextStyleChange("underline")}
                  >
                    <u>U</u>
                  </button>
                  {/* <button className={classes.stylingButton} onClick={() => handleFontWeightChange('normal')}>
                  Ag
                </button>
                <button className={classes.stylingButton} onClick={() => handleFontWeightChange('bold')}>
                  AG
                </button> */}
                </div>
              </div>
            )
        }

        else {
            return (

                <div className={classes.selectColor}>
                <h2>Color: {frontCoverTextColor?.colorName}</h2>
                <ColorSelector
                  availableColors={colors}
                  selectedColor={frontCoverTextColor}
                  handleColorChange={handleColorChange}
                />
              </div>
            )
        }
    }


    return (
      <div
        className={classes.colorPickerDropdown}
        onClick={(e) => e.stopPropagation()}
      >
        {currentOption === "" ? (
            <>
            <div className={classes.optionSection}>
                {options.map((option, index) => (
                <div
                    key={index}
                    className={classes.options}
                    onClick={() => setCurrentOption(option.name)}
                >
                    <OptionCard
                    active={currentOption === option.name}
                    image={option?.icon}
                    desc={option?.name}
                    className={classes.optionCard}
                    />
                </div>
                ))}
            </div>
                <div className={classes.addTextBox} onClick={handleAddTextBox}>
                <div className={classes.addText}>
                    <h3>Add Textbox</h3>
                </div>
                <p>+$40</p>
                </div>
            
            </>
        ) : (
          <div className={classes.openedOption}>
            <div className={classes.header}>
              <FontAwesomeIcon
                onClick={() => setCurrentOption("")}
                icon={faAngleLeft}
              />
              <h2>{currentOption}</h2>
              <p onClick={() => setCurrentOption("")}>Done</p>
            </div>
            {rednerStep(currentOption)}
          </div>
        )}
      </div>
    );
}

export default EditTextSmall