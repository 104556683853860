import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import classes from "./Services.module.css";
import CustomizationSteps from "./CustomizationSteps/CustomizationSteps";
import WhyUs from "./WhyUs/WhyUs";
import BookCustomization from "../Body/BookCustomization/BookCustomization";
import Gallery from "../Body/Gallery/Gallery";
import CustomerReview from "../Body/CustomerReview/CutomerReview";
import FAQ from "./FAQ/FAQ";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import Footer from "../Footer/Footer";

import { useEffect } from "react";
import ServiceVideo from "./Components/ServiceVideo/ServiceVideo";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllVideos } from "../../Store/actions/ServiceVideoAction";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { getAllFaq } from "../../Store/actions/FaqAction";

const banner = "/Assets/Images/SericeOverlay.svg";
const Services = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Book Customization", path: "/services" },
  ];

  const video = useSelector((state) => state.serviceVideo.videos?.servicesVideo);
  const allFaq = useSelector((state) => state.Faq.questions);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllVideos(dispatch);
  }, []);

  useEffect(() => {
    getAllFaq("category", "Customization", "", dispatch);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div className={classes.mainContainer} ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader
        title={`"Your Book, Your Way: Customize Every Aspect from Cover to Gilding"`}
        crumbs={crumbs}
        desc={
          "Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding"
        }
        bgImage={banner}
      />

      <div className={classes.customisedPersonalised}>
        {video && <ServiceVideo
          title={"Customized And Personalized Books"}
          desc={
            "At Rare Biblio, we understand that books are more than just stories on paper; they are a reflection of your individuality, a cherished keepsake, and a symbol of heartfelt sentiments. That's why we take immense pride in offering a world of customization options for our leather-bound books, allowing you to craft literary masterpieces that are uniquely yours."
          }
          videoSrc={video}
        />}
        {/* <CustomisedBooks /> */}
      </div>
      <div className={classes.customizationSteps}>
        <CustomizationSteps />
      </div>
      <div className={classes.whyUs}>
        <WhyUs />
      </div>
      <div className={classes.bookCustomization}>
        <BookCustomization />
      </div>
      <div className={classes.gallery}>
        <Gallery />
      </div>
      <div className={classes.customerReview}>
        <CustomerReview />
      </div>

      <div className={classes.faqSection}>
        <FAQ data={allFaq}/>
      </div>
      <div className={classes.contactUs}>
        <ContactUs />
      </div>

      <div className={classes.footerSection}>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
