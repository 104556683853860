import classes from "./AboutForAuthors.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title:"Enhance the Attractiveness of Your Book",
        desc:"A beautifully designed leather-bound version will provide readers with an improved physical and visual experience. The elegant appearance and lavish feel are sure to amaze.",
        image:image1
    },
    {
        title:"Preserve Your Legacy",
        desc:" A family legacy, a leather-bound book is more than just a work of literature. Make sure your work endures the test of time by leaving a legacy for future generations to treasure.",
        image:image2
    },
    {
        title:"Higher Royalties",
        desc:"Benefit from higher royalties based on the deal and demand for premium editions. Leather-bound books command a higher price point, translating to increased earnings for you as the author.",
        image:image3
    },
    {
        title:"Increased Exposure",
        desc:" To increase awareness and reach a wider audience, list your leather-bound edition on multiple marketplaces. Make a splash in the overcrowded market with a high-quality, distinctive product.",
        image:image3
    },
    {
        title:"Superior Craftsmanship",
        desc:" Upgrade your book with high-quality materials and luxurious binding choices, such as custom embossing, gilded edges, and leather-bound covers.",
        image:image3
    },
    {
        title:"Collectors Editions",
        desc:" Create special editions of your work that are only available to collectors and die-hard readers.",
        image:image3
    },
    {
        title:"New Edition for Elite Readers",
        desc:"Give your elite readers premium leather-bounded books and make them also get a sense of royalty in every touch and experience luxury.",
        image:image3
    },
    {
        title:"Customized gift options",
        desc:" To make sure your vision is realized, add unique design components and a 3D preview function to each copy for your loved ones.",
        image:image3
    },
]

const AboutForPublishers = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Why Partner with Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers