import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getClasses } from "../../../../Util"
import classes from "./OptionCard.module.css"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons"

const OptionCard = ({image,desc,active,onClick,className,disabeled = false,allowDelete = false,onDelete}) => {
    return(
        <div className={ active ? getClasses(className,classes.active,disabeled ? classes.disbaled : "") : getClasses(className,classes.cardContainer,disabeled ? classes.disbaled : "")} onClick={onClick}>
                <div className={classes.imageSection}>
                    <div className={classes.imageContainer}>

                        <img src={image} alt="" />
                    </div>
                </div>

                <p>{desc}</p>
            {active && <FontAwesomeIcon className={classes.selectedIcon} icon={faCircleCheck} />}
            <div>
                {allowDelete && <FontAwesomeIcon className={classes.deleteIcon} icon={faTrashAlt} onClick={onDelete} />}
            </div>
        </div>
    )
}

export default OptionCard