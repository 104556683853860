import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, { ModalBody, ModalFooter } from "../../../../Components/Modal/Modal";
import classes from "./DisputeOrderForm.module.css";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import PrimaryTextArea from "../../../../Components/Inputs/PrimaryTextArea/PrimaryTextArea";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { getFormattedDate } from "../../../../Util";
import { useState } from "react";
import { DisputeOrderAction } from "../../../../Store/actions/ContactUsAction";

const DisputeOrderForm = ({ data, activeTab, onClose }) => {

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [selectedBook,setSelectedBook] = useState("");
  const [additionalText,setAdditionalText] = useState("");

  const handleImageChange = (event) => {

    if (!image1) setImage1(event.target.files[0]);
    else if (!image2 ) setImage2(event.target.files[0]);
    else if (!image3 ) setImage3(event.target.files[0]);
  };

  const handleRemoveImage = (index) => {
    if (index === 1) setImage1(null);
    if (index === 2) setImage2(null);
    if (index === 3) setImage3(null);
  };

  const clearForm = () => {
    setImage1(null)
    setImage2(null)
    setImage3(null)
    setAdditionalText("")
    setSelectedBook("")
  }

  const handleFormSubmission = () => {
    const disputeData = {
        order: data,
        bookName: selectedBook,
        additionalMessage: additionalText,
      };
  
  
    const formData = new FormData();
  
    formData.append("order", JSON.stringify(disputeData));
  
    if (image1) formData.append("image1", image1);
    if (image2) formData.append("image2", image2);
    if (image3) formData.append("image3", image3);
  
    DisputeOrderAction(formData).then((response) => {
      if (response?.data?.success === true) {
        clearForm();
        onClose();
      }
    });
  };
  

  return (
    <Modal
      title={
        <div className={classes.title}>
          <p>Have issue with your order</p>
          <h2>Dispute Order Request</h2>
        </div>
      }
      onClose={onClose}
    >
      <ModalBody>
        <div className={classes.main}>
          <div className={classes.formBody}>
            <div className={classes.headerSection}>
              <div className={classes.headerItem}>
                <h2>Order Placed</h2>
                <p>{getFormattedDate(data?.createdAt)}</p>
              </div>
              <div className={classes.headerItem}>
                <h2>Amount Paid</h2>
                <p>${Number(data.payableAmount).toFixed(2)}</p>
              </div>
              <div className={classes.headerItem}>
                <h2>Billing Address</h2>
                <p>{`${data?.billingAddress?.addressLine1}, ${data?.billingAddress?.city}, ${data?.billingAddress?.state}, ${data?.billingAddress?.country}, ${data?.billingAddress?.zipCode}`}</p>
              </div>
              <div className={classes.headerItem}>
                <h2>Order No.</h2>
                <p>{data?.orderNo}</p>
              </div>
              {activeTab !== "transit" && (
                <div className={`${classes.headerItem} ${classes.invoice}`}>
                  <h2>Download Invoice</h2>
                  <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faDownload} />
                </div>
              )}
            </div>

            <div className={classes.disputeReason}>
              <div className={classes.disputeForm}>
                <div className={classes.bookName}>
                  <h2>Book Name</h2>
                  <div className={classes.dropdown}>
                    <select name="name" id="book-name" value={selectedBook} onChange={(e) => setSelectedBook(e.target.value)}>
                      <option disabled value="" defaultChecked={true}>
                        --Select Book Name--
                      </option>
                      {data?.cartItems?.map((item) => (
                        <option
                          key={item?.book ? item?.book?.id : item?.bestSellerBook?.id}
                          value={item?.book ? item?.book?.title : item?.bestSellerBook?.title}
                        >
                          {item?.book ? item?.book?.title : item?.bestSellerBook?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className={classes.disputeImages}>
                <h2>Add photos</h2>
                <p>Upload photos that show the issue with the order</p>

                <div className={classes.uploadSection}>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    disabled={image1 && image2 && image3}
                    onChange={handleImageChange}
                    className={classes.fileInput}
                    id="file-upload"
                  />
                  <label
                    htmlFor="file-upload"
                    className={
                      image1 && image2 && image3
                        ? classes.disabledUploadLabel
                        : classes.uploadLabel
                    }
                  >
                    Upload Images
                  </label>

                  <div className={classes.imagePreview}>
                    {[image1, image2, image3].map((image, index) => (
                      image && (
                        <div key={index} className={classes.previewContainer}>
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index + 1}`}
                            className={classes.previewImage}
                          />
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={classes.removeIcon}
                            onClick={() => handleRemoveImage(index + 1)}
                          />
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </div>

              <div className={classes.additionalText}>
                <label htmlFor="">Additional Message</label>
                <PrimaryTextArea
                  onChange={e => setAdditionalText(e.target.value)}
                  placeholder={"Enter Additional Message"}
                  rows="5"
                  className={classes.additionalMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <PrimaryButton disabled={!selectedBook || !additionalText} className={classes.submitBtn} onClick={handleFormSubmission}>
          Submit
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default DisputeOrderForm;
