import {React} from "react";
import classes from "./Home.module.css";
import Header from "../Header/Header";
import Banner from "../Body/Banner/Banner"


import { WhoAreWe } from "../Body/WhoAreWe/WhoAreWe";
import RBExclusive from "../Body/RBExlusive/RBExlusive";

import BookCustomization from "../Body/BookCustomization/BookCustomization";

import FeaturedCollection from "../Body/FeaturedCollection/FeaturedCollection";

import BestAuthors from "../Body/BestAuthors/BestAuthors";

import StatisticsBanner from "../Body/StatisticsBanner/StatisticsBanner";
import Gallery from "../Body/Gallery/Gallery";
import CustomerReview from "../Body/CustomerReview/CutomerReview";
import ExploreAuthors from "../Body/ExploreAuthors/ExploreAuthors";

import BlogsSection from "../Body/BlogSection/BlogSection";

import FAQRedirect from "../Body/FAQRedirect/FAQRedirect";

import Footer from "../Footer/Footer";

import { FeatureVideo } from "../Body/FeatureVideo/FeatureVideo";



function Home() {

  return (
    <main className={classes.main}>
      <Header />
      <div>
        <div className={classes.Home}>
        <Banner/>
        </div>
      </div>
    <WhoAreWe />

    <FeatureVideo />

    <div className={classes.RBExclusiveContainer}>
      <RBExclusive/>
    </div>

    <div className={classes.bookCustomizationContainer}>
      <BookCustomization/> 
    </div>

    <div className={classes.featuredCollectionContainer}>
      <FeaturedCollection/>
    </div>

      <BestAuthors/>

      <StatisticsBanner/>
      <Gallery/>
      <CustomerReview/>

      <ExploreAuthors/>
      <BlogsSection/>

      <FAQRedirect/>


      <div className={classes.footerSection}>
        <Footer/>
      </div>
      </main>
  );
}

export default Home;



