import React, { useContext, useEffect, useState } from 'react';
import ColorPicker from './ColorPicker/ColorPicker';
import OuterBorder from './OuterBorder/OuterBorder';
import SectionController from '../Components/SectionController/SectionController';
import useScreenSize from '../../../Hooks/UseScreenSize';
import classes from './FrontCoverDeluxe.module.css'; // Make sure this path is correct
import MarblePaper from './MarblePaper/MarblePaper';
import { useFabricCanvas, useFabricCanvasHandler, useFabricEditor } from 'react-fabric-page';
import InnerBorder from './InnerBorder/InnerBorder';
import { CustomizationContext } from '../Customization';


const options = ["Leather Color","Marble Paper","Outer Border", "Inner Border"];

const FrontCoverDeluxe = ({ onNext, onPrev }) => {
  const {bookVariant,bookLeather,selectedOption,setDesignJSON,designSaveInitiated} = useContext(CustomizationContext);
  const [currentDropdownId, setCurrentDropdownId] = useState(0);
  const [currentDropdown, setCurrentDropdown] = useState(options[currentDropdownId]);
  const [dropdownOpen, setDropDownOpen] = useState(true);

  // const [currentOption,setCurrentOption] = useState("");

  const { isMediumScreen } = useScreenSize(); 
  const [isCanvasReady,setIsCanvasReady] = useState(false);

  const canvas = useFabricCanvas()
  const editor = useFabricEditor()

  useEffect(() => {
    window.canvas = canvas;
  }, [canvas]);

  useFabricCanvasHandler(canvas => ({
    "canvas:x-ready": function () {
        setIsCanvasReady(true);
    }
}),[bookVariant,bookVariant]);

useEffect(() => {
  setIsCanvasReady(false);
},[bookLeather,bookVariant])

  const toggleDropdown = () => {
    setDropDownOpen(!dropdownOpen);
  };

  const handleOptionClick = (id) => {
    if (id === currentDropdownId) {
      setDropDownOpen(!dropdownOpen);
    } else {
      setCurrentDropdownId(id);
      setCurrentDropdown(options[id]);
      setDropDownOpen(true);
    }
  };

  const nextSection = (id) => {
    if (currentDropdownId < 3) {
      setCurrentDropdownId(currentDropdownId + 1);
      setCurrentDropdown(options[id + 1]);
      // setCurrentOption(options[id + 1]);
      setDropDownOpen(true);
    }
     else {
      onNext();
    }
  };

  const prevSection = (id) => {
    if (currentDropdownId >= 1) {
      setCurrentDropdownId(currentDropdownId - 1);
      setCurrentDropdown(options[id - 1]);
      setDropDownOpen(true);
    } else {
      onPrev();
    }
  };

  useEffect(() => {
    if(designSaveInitiated && editor) {
      const frontJSONString = editor?.getJSON();
      const frontJSONBlob = new Blob([frontJSONString], { type: "application/json" });
      setDesignJSON(prevArray => {
        const updatedArray = [...prevArray];
        updatedArray[0] = frontJSONBlob;
        return updatedArray;
      });
    }
  },[designSaveInitiated])

  return (
    <div className={classes.mainContainer}>
      <div className={classes.details}>
        {isMediumScreen ? (
          <></>
        ) : (
          // <div className={currentOption === "" ? classes.headerSection : classes.headerSectionBack}>
          //   <h2>{currentOption === "" ? "Customize front cover (1/5)" : currentOption}</h2>
          //   {currentOption !== "" && <FontAwesomeIcon className={classes.iconContainer} icon={faAngleLeft} onClick={() => setCurrentOption("")} />}
          //   {currentOption === "" && <p>
          //     Front Cover: The front cover is the outside of your book, with the title and picture that
          //     introduce your book to readers.
          //   </p>}
          // </div>
          <div className={classes.headerSection}>
            <h2>Customize front cover (1/5)</h2>
            <p>
              Front Cover: The front cover is the outside of your book, with the
              title and picture that introduce your book to readers.
            </p>
          </div>
        )}

        {!isMediumScreen && (
          <div className={classes.customizationBody}>
            <div onClick={() => handleOptionClick(0)}>
              <ColorPicker
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(0)}
              />
            </div>
            <div onClick={() => handleOptionClick(1)}>
              <MarblePaper
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(1)}
                isCanvasReady={isCanvasReady}
              />
            </div>
            <div onClick={() => handleOptionClick(2)}>
              <OuterBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(2)}
              />
            </div>
            <div onClick={() => handleOptionClick(3)}>
              <InnerBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(3)}
              />
            </div>
            <div className={classes.sectionController}>
              <SectionController
                prev={
                  currentDropdownId === 0
                    ? "Material and Variant"
                    : options[currentDropdownId - 1]
                }
                next={
                  currentDropdownId === 3
                    ? "Spine"
                    : options[currentDropdownId + 1]
                }
                onNext={() => nextSection(currentDropdownId)}
                onPrev={() => prevSection(currentDropdownId)}
              />
            </div>
          </div>
        )}

        {isMediumScreen && (
          <div className={classes.customizationBody}>
            <div
              className={
                selectedOption === "Leather Color" ? "" : classes.inVisible
              }
              onClick={() => handleOptionClick(0)}
            >
              <ColorPicker
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(0)}
              />
            </div>
            <div
              className={
                selectedOption === "Marble Paper" ? "" : classes.inVisible
              }
              onClick={() => handleOptionClick(1)}
            >
              <MarblePaper
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(1)}
                isCanvasReady={isCanvasReady}
              />
            </div>
            <div
              className={
                selectedOption === "Outer Border" ? "" : classes.inVisible
              }
              onClick={() => handleOptionClick(2)}
            >
              <OuterBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(2)}
              />
            </div>
            <div
              className={
                selectedOption === "Inner Border" ? "" : classes.inVisible
              }
              onClick={() => handleOptionClick(3)}
            >
              <InnerBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(3)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FrontCoverDeluxe;
