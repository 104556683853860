import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";

import { logout } from "../../../Store/actions/UsersActions";
import ButtonUtility from "../../../Components/Buttons/ButtonUtlity/ButtonUtlity";
import styles from "./Sidebar.module.css";
const walletIcon = "/Assets/Icons/walletIcon.svg";
const closeIcon = "/Assets/Icons/icon-x-grey.svg";

const sideBarMenu = [
  {
    name: "My Profile",
    url: "my-profile",
  },
  {
    name: "My Orders",
    url: "my-orders",
  },
  {
    name: "Manage Address",
    url: "manage-address",
  },
  {
    name: "Coupons",
    url: "coupons",
  },
  {
    name: "Wishlist",
    url: "wishlist",
  },
  {
    name: "My Wallet",
    url: "my-wallet",
  },
];

const Sidebar = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { location } = history;
  const currentUser = useSelector((state) => state.user.currentUser);
  const handleLogout = () => {
    logout(history, dispatch);
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.crossIcon}>
        <img onClick={onClose} src={closeIcon} alt="" />
      </div>
      <div className={styles.profileSection}>
        <div className={styles.profileImageContainer}>
          {/* Profile Image Placeholder */}
          <div
            className={
              currentUser.photoURL ? styles.image : styles.profileImage
            }
          >
            <img src={currentUser.photoURL} alt="" />
          </div>
        </div>
        <div className={styles.userInfo}>
          <h3 className={styles.userName}>{currentUser.fullName}</h3>
          <p className={styles.userEmail}>{currentUser.userId}</p>
        </div>
        <div className={styles.editSection}>
          <div className={styles.balance}>
            <div className={styles.walletIcon}>
              <img src={walletIcon} alt="" />
            </div>
            <div className={styles.walletDetails}>
              <span>$ {currentUser.walletBalance}</span>
              <p>Available Balance</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.menuSection}>
        <div className={styles.menuBtn}>
          {sideBarMenu.map((item, idx) => (
            <ButtonUtility
              key={idx}
              className={styles.menuButton}
              onClick={() => history.push(`${url}/${item.url}`)}
              isActive={location.pathname === `${url}/${item.url}`}
            >
              {item.name}
            </ButtonUtility>
          ))}
        </div>
        <ButtonUtility className={styles.logoutButton} onClick={handleLogout}>
          Logout
        </ButtonUtility>
      </div>
    </div>
  );
};

export default Sidebar;
