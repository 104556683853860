import React from 'react';
import classes from './Card2.module.css';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../Routes/Urls';
import { addWishlistItem } from '../../../Store/actions/WishlistAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const heartIcon = "/Assets/Icons/heartIcon.svg"

const Card = ({ altText,price,image,bookData, icon  }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)

  const handleAddCart = () => {
    history.push(`${urls.BESTSELLERPDP}/${bookData.id}`);
  }

  const handleIconClick = () => {
    if(currentUser?.id){
      addWishlistItem(bookData,1,dispatch,true)
    }
    else{
      history.push(`${urls.LOGIN}`)
    }
    
  }

  return (
    <div className={classes.card}>
      <div className={classes.main}>
      <div>
      <div className={classes.imageContainer}>
        <img src={image} alt={altText} className={classes.image} />
        <img className={classes.heartButton} src={icon || heartIcon} alt="" onClick={handleIconClick}/>
        {/* <button className={classes.heartButton}>&#9825;</button> */}
      </div>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.titleContainer}>
            <h3 className={classes.title}>{bookData?.title}</h3>
            <p className={classes.author}>{bookData.author}</p>
          </div>
        </div>
      </div>
      </div>
      </div>
      <div className={classes.actions}>
      <div className={classes.bottomRow}>
        <p className={classes.price}>${price?.toFixed(2)}</p>
      </div>
      <div className={classes.addToCartButton}>
        <SecondaryButton className={classes.cartBtn} onClick={handleAddCart}>Add to Cart</SecondaryButton>
      </div>
      </div>
      <div className={classes.cloudLayer}></div>
    </div>
  );
};

export default Card;
