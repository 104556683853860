import classes from "./AddText.module.css"
import { useState,useEffect, useContext } from "react";

import { useFabricCanvas } from "react-fabric-page";
// import { useActiveFabricObject } from "react-fabric-page";
// import { ActiveTextboxObject,addTextboxObject } from "../../../../../objectUtils";
import { CustomizationContext } from "../../../Customization";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
const dropdownIcon = "/Assets/Icons/caret-down-solid.svg"
  

const AddText = () => {

  const {dedicationTextColor,setDedicationTextColor,
    dedicationFontFamily,setDedicationFontFamily,
    dedicationFontStyle,setDedicationFontStyle,fontData,allTextColors,setDedicationText} = useContext(CustomizationContext);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [colors,setColors] = useState([]);

    let canvas = useFabricCanvas()
    // const [activeObject, update] = useActiveFabricObject(ActiveTextboxObject);


    useEffect(() => {
      setColors(allTextColors)
    },[allTextColors])

    useEffect(() => {
      setDedicationTextColor(colors?.[0])
    },[colors])

    useEffect(() => {
      const handleTextChange = () => {
        const contentsLayer = canvas.getObjects().find(o => o.id === "contents");
        
        if (contentsLayer) {
          const textBox = contentsLayer.getObjects().find(o => o.type === "textArea");
          
          if (textBox) {
            setDedicationText(textBox.text);
          }
        }

        canvas.renderAll()
      };
    
      canvas.on('text:changed', handleTextChange);
    
      return () => {
        canvas.off('text:changed', handleTextChange);
      };
    }, [canvas]);
    

    // const handleAddTextBox = () => {
    //     addTextboxObject(canvas);
    //   };

      const handleColorChange = (color) => {
        setDedicationTextColor(color);
      };
    
    
      const handleFontChange = (font) => {
        setDedicationFontFamily(font);
        setIsDropdownOpen(false);
      };

      const handleTextStyleChange = (style) => {
        setDedicationFontStyle((prevStyle) => {
          const newStyle = { ...prevStyle };
      
          switch (style) {
            case 'italic':
              newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
              break;
            case 'underline':
              newStyle.underline = !newStyle.underline;
              break;
            default:
              break;
          }
      
          return newStyle;
        });
      };

      // useEffect(() => {
      //   const changeCoverColor = (colorCode) => {
      //     const obj = activeObject;
      //     const selectedTextStyle = obj?._activeObject;
      //     if (selectedTextStyle) {
      //       selectedTextStyle.set("fontFamily", selectedFont.fontFamily);
      //       selectedTextStyle.set("fill", colorCode);
      //       obj.dirty = true;
      //       canvas.renderAll();
      //     }
      //   };
    
      //   changeCoverColor(selectedColor.hex);
      // }, [selectedColor, selectedFont, activeObject, canvas]);


    return (
      <div className={classes.mainContainer}>
        {/* <div className={classes.addText} onClick={handleAddTextBox}>
                <FontAwesomeIcon icon={faPlus} />
                <h2>Add TextBox</h2>
            </div> */}

        <div
          className={classes.colorPickerDropdown}
          onClick={(e) => e.stopPropagation()}
        >
          <h3>Select font for front cover</h3>

          <div className={classes.selectColor}>
            <h2>Font family: {dedicationFontFamily?.fontFamily}</h2>
            <div className={classes.customDropdown}>
              <div
                className={classes.selectedOption}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className={classes.dropdownBar}>
                  <div className={classes.dropdownText}>
                    <span>{dedicationFontFamily?.fontFamily}</span>
                    <span>
                      {dedicationFontFamily?.fontPrice > 0 && `+$${dedicationFontFamily?.fontPrice}`}
                    </span>
                  </div>
                  <div className={classes.dropdownIcon}>
                    <img src={dropdownIcon} alt="" />
                  </div>
                </div>
              </div>
              {isDropdownOpen && (
                <div className={classes.dropdownOptions}>
                  {fontData?.map((font) => (
                    <div
                      key={font?.fontFamily}
                      className={classes.option}
                      onClick={() => handleFontChange(font)}
                    >
                      <span>{font?.fontFamily}</span>
                      <span>{font?.fontPrice > 0 && `+$${font?.fontPrice}`}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={classes.textStylingOptions}>
            <h3>Select Text Styling</h3>
            <div className={classes.stylingButtons}>
              <button
                className={`${classes.stylingButton} ${
                  dedicationFontStyle?.fontStyle === "italic"
                    ? classes.activeStylingButton
                    : ""
                }`}
                onClick={() => handleTextStyleChange("italic")}
              >
                <i>I</i>
              </button>
              <button
                className={`${classes.stylingButton} ${
                  dedicationFontStyle?.underline === true
                    ? classes.activeStylingButton
                    : ""
                }`}
                onClick={() => handleTextStyleChange("underline")}
              >
                <u>U</u>
              </button>
            </div>
          </div>

          {/* <div className={classes.selectColor}>
                <h2>Color: {dedicationTextColor?.colorName}</h2>
                <div className={classes.colors}>
                  {colors.map((color, index) =>
                    dedicationTextColor?.colorValue === color?.colorValue ? (
                      <div key={index} className={classes.selected}>
                        <div
                          className={classes.active}
                          style={{ background: color?.colorValue }}
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={classes.colorPicker}
                        style={{ background: color?.colorValue }}
                        onClick={() => handleColorChange(color)}
                      />
                    )
                  )}
                </div>
              </div> */}
          <ColorSelector
            availableColors={colors}
            selectedColor={dedicationTextColor}
            handleColorChange={handleColorChange}
          />
        </div>
      </div>
    );
}

export default AddText